import React, { useContext, useEffect, useState } from 'react';
import { Link, NavLink, useNavigate, useLocation, useParams } from 'react-router-dom';
import { isAndroid } from 'react-device-detect';
const Magiclink = (props) => {
    const navigate = useNavigate();
    const { id, token } = useParams();
    // useEffect(() => {
    //     // alert('fds')
    //     // window.open(`https://trustme.host/app/webservice/shareurllink.php/?link=nrth://SignUp/15`,);
    //     // window.Location.href = ''
    //     openApp()
    // }, []);
    useEffect(() => {
        openApp()
    }, []);
    const openApp = () => {
        var nVer = navigator.appVersion;

        // window.location.href = appScheme;

        const appScheme = `nrth://ResetPassword/?token=${token}&isResetPassword=${id}`; // Replace with the app's custom scheme
        // const appScheme = `nrth://nrthadmin.inwizards.in/ResetPassword/?token=${token}&isResetPassword=${id}`;

        const appCheckLink = document.createElement('a');
        appCheckLink.href = appScheme;

        appCheckLink.click(); // Try to open the link

        setTimeout(() => {
            window.open(`https://play.google.com/store/apps/details?id=com.inw.trulinco`,);
            // This function will be executed after a short delay (e.g., 100ms)
            // If the app is present and opens, this block won't run immediately
            // You can define actions based on the success or failure to open the app
            if (document.hidden) {
                window.location.href = appScheme;
                // App was likely opened, as the current window became hidden
            } else {
                const userAgent = window.navigator.userAgent.toLowerCase();
                if (isAndroid) {
                    console.log('userAgent1', userAgent);

                    window.open(`https://play.google.com/store/apps/details?id=com.inw.trulinco`,);
                } else if (userAgent.indexOf('windows') !== -1) {
                    console.log('userAgent2', userAgent);
                    window.open(`https://play.google.com/store/apps/details?id=com.inw.trulinco`,);

                } else if (userAgent.indexOf('mac') !== -1 || userAgent.indexOf('os x') !== -1) {
                    console.log('userAgent3', userAgent);
                    window.open(`https://apps.apple.com/in/app/trulinco-translator/id1583020135`,);

                } else if (userAgent.indexOf('linux') !== -1) {
                    console.log('userAgent4', userAgent);
                    window.open(`https://play.google.com/store/apps/details?id=com.inw.trulinco`,);
                } else {
                    console.log('userAgent5', userAgent);
                    window.open(`https://apps.apple.com/in/app/trulinco-translator/id1583020135`,);
                }


                // App was not opened, or the scheme is not recognized
            }
        }, 100);


    };
    return (
        <div className="slidebar">
            {/* <a href="nrth://">Open My App</a> */}
            {/* <button onClick={openApp}>Open App</button> */}
        </div>
    );
};

export default Magiclink;







