import * as Yup from 'yup';

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .trim()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};
// export const validationSchema = (email) => Yup.object().shape({
//   fullname: Yup.string().required('Fullname is required'),
//   username: Yup.string()
//     .required('Username is required')
//     .min(6, 'Username must be at least 6 characters')
//     .max(20, 'Username must not exceed 20 characters'),
//   email: Yup.string().required('Email is required').email('Email is invalid'),
//   password: Yup.string()
//     .required('Password is required')
//     .min(6, 'Password must be at least 6 characters')
//     .max(40, 'Password must not exceed 40 characters'),
//   confirmPassword: Yup.string()
//     .required('Confirm Password is required')
//     .oneOf([Yup.ref('password'), null], 'Confirm Password does not match'),
//   acceptTerms: Yup.bool().oneOf([true], 'Accept Terms is required'),
// });
const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const validationSchema = (email) => Yup.object().shape({
  email:  Yup.string().matches(emailPattern, 'Invalid email format').required('Email is required'),
  password: Yup.string()
    .required('Password is required')
    .min(6, 'Password must be at least 6 characters')
    .max(40, 'Password must not exceed 40 characters'),
});

export const validatePassword = (password) => {
  return String(password)
    .toLowerCase()
    .trim()
    .match(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/);
};
export const validatePhone = (phoneNumber) => {
  return String(phoneNumber).match(/^[0-9]{10}$/g);
};

export const formatClause = (clause) => {
  let wordArray = clause.replace('clause', '').split(' ');
  let result = '';
  wordArray.forEach((word) => {
    if (word.length > 0) {
      result += ` ${word[0].toUpperCase()}${word.slice(1)}`;
    }
  });
  return result;
};

export const formatSelectedClause = (clause) => {
  if (clause) {
    let wordArray = clause.toLowerCase().replaceAll('clause', '').split(' ');
    let result = '';
    wordArray.forEach((word) => {
      if (word && word.length > 0) {
        result += ` ${word[0].toUpperCase()}${word.slice(1)}`;
      }
    });
    return result;
  }
};

export const getCookie = (name) => {
  var cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    var cookies = document.cookie.split(';');
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === name + '=') {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
};
