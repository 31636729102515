import React, { useEffect, useContext, useState } from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import logo from '../../../assets/img/logo.png'
import { NavLink } from "react-router-dom";
import AppContext from "../../../context/AppContext";
const Header = ({ title }) => {

  const { toggleClass, setToggleClass, headerHeadding } = useContext(AppContext)
  const [adminName, setAdminName] = useState('')
  const [adminEmail, setAdminEmail] = useState('')

  useEffect(() => {

    var data = JSON.parse(localStorage.getItem('userData'))
    setAdminName(data.name)
    setAdminEmail(data.email)
    // setAdminData(data)
  }, [])
  return (
    <>
      <div className="header">
        <div className="left_lu">
          <a href="#" className="logo">
            <img src={logo} alt="" />
          </a>
          <h4 className="nameee">
            <div className={`men_side ${toggleClass == true ? 'open_mm' : ''}`} onClick={(e) => {
              e.stopPropagation()
              setToggleClass(!toggleClass)
            }}>
              <span></span><span></span><span></span>
            </div>

            {headerHeadding}

          </h4>
        </div>
        <div className="right_mennu">
          <ul className="ul_set">
            <li className="dropdown user_profile">
              <NavLink to="/profile" >
                {/* <a href="#" onClick={navigator('./profile')} role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false"
                className="dropdown-toggle"> */}
                <span className="user_img">
                  {adminName.charAt(0).toUpperCase()}
                </span>
                {adminName}
                <span className="email_h">
                  {adminEmail}
                </span>
                {/* </a> */}
              </NavLink>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <li><a className="dropdown-item" href=""><i className="fa fa-sign-out"></i> Logout</a></li>
              </ul>
            </li>
          </ul>
        </div>
      </div>

    </>
  );
};

export default Header;

