import React, { useEffect, useContext, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { appName } from "../../constants/constants";
import AuthContext from "../../context/AuthContext";
import AppContext from "../../context/AppContext";
import NavbarMenu from "../navbar/Navbar"
import NotFoundComponent from "../not-found/NotFoundComponent";
import Header from "../commonPage/header/Header";
import Navbar from "../navbar/Navbar";
import Edit from '../../assets/img/edit.png'
import Delete from '../../assets/img/delete.png'
import LeftArrow from '../../assets/img/left_arrow.png'
import RightArrow from '../../assets/img/right_arrow.png'
import EyeIcon from '../../assets/img/eye.png'
import axios from 'axios';
import useAxios from "../../hooks/useAxios";
import AddPlanModel from "../modals/AddPlan";
import { parseISO, format } from "date-fns";
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Swal from 'sweetalert2'
import { ToastContainer, toast } from "react-toastify";
import AddNotificationModel from "../modals/AddNotificationModel";
import { useNavigate } from "react-router-dom";
const timeout = 1000 * 60 * 30;

const Notification = ({ title }) => {
    const baseURL = process.env.REACT_APP_BACKEND_BASE_URL;
    const headers = {
        'Content-Type': 'application/json',
        'authorization': `Bearer ${localStorage.getItem("authorization")}`
    }
    const navigate = useNavigate();
    const api = useAxios();
    const { activeLink, setActiveLink, activePrevLink, toggleClass, setHeaderHeadding } = useContext(AppContext);
    const [searchText, setSearchText] = useState("");
    const [isOpneAddNotification, setIsOpneAddNotification] = useState(false)
    const [pageNumber, setPageNumber] = useState(1)
    const [limit, setLimit] = useState(10)
    const [notification, setNotification] = useState([])
    const [isEdit, setIsEdit] = useState(false)
    const [notificationData, setNotificationData] = useState({})
    const [isLoading, setIsLoading] = useState(true);
    const [customerForSearch, setCustomerForSearch] = useState("");
    const [customer, setCustomer] = useState([])
    const [planStatus, setPlanStatus] = useState(true)
    const [countPage, setCountPage] = useState('')
    const [planID, setPlanID] = useState('')
    const [planName, setPlanName] = useState('')
    const [className, setClassName] = useState('')
    const [updown, setUpdown] = useState('')


    const getNotification = async () => {

        if (pageNumber > 1) {
            var pageNumbers = pageNumber - 1
            var skip = pageNumbers * limit;
        } else {
            var skip = 0
        }
        var setdata = {
            "skip": skip,
            "limit": +limit
        }
        try {
            var res = await axios.post(`${baseURL}/dymanicNotificationTexts/getActivefilteredwithPagination`, setdata, { headers: headers });
            if (res.status == 200) {
                setIsLoading(false)
                setNotification(res.data.getActivefilteredwithPaginationdymanicNotificationTexts.dymanicNotificationTextsList)
                let pageCount = res.data.getActivefilteredwithPaginationdymanicNotificationTexts.totalCount / limit;
                if (pageCount == Math.floor(pageCount)) {
                    pageCount = Math.round(pageCount)

                } else {

                    pageCount = Math.round(pageCount) + 1

                }
                if (pageCount == 0) {
                    setCountPage(1)

                } else {
                    setCountPage(pageCount)

                }
            } else {
                toast.error(res.data.ErrorMessage)
            }
        } catch (error) {

            if (error.response.status == 401) {
                localStorage.removeItem('userData')
                localStorage.removeItem('authorization')
                navigate("/");
            } else if (error.response.status == 403) {
                toast.error(error.response.data.ErrorMessage)

            } else {
                toast.error('Server error occurring, Please try after some time.')

            }
        }

    }
    const notificationAlert = (id, name) => {
        Swal.fire({
            title: "Are you sure?",
            text: `You want to delete ${name} Plan.`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then(async (result) => {

            if (result.isConfirmed) {
                try {
                    let res = await axios.delete(`${baseURL}/dymanicNotificationTexts/delete/${id}`, { headers: headers });
                    if (res.status == 200) {

                        Swal.fire({
                            title: "Deleted!",
                            icon: "success"
                        });
                        getNotification()
                    } else {
                        Swal.fire({
                            title: "Deleted!",
                            text: "Something want worng.",
                            icon: "error"
                        });
                    }
                    getNotification()
                    setIsEdit(false)
                } catch (error) {

                    if (error.response.status == 401) {
                        localStorage.removeItem('userData')
                        localStorage.removeItem('authorization')
                        navigate("/");
                    } else if (error.response.status == 403) {
                        toast.error(error.response.data.ErrorMessage)

                    } else {
                        toast.error('Server error occurring, Please try after some time.')

                    }
                }

            }
        });
    }
    const changePlanStatus = async (e, id) => {
        var status = e ? 1 : 0;
        try {
            let res = await axios.put(`${baseURL}/dymanicNotificationTexts/activedeactive/${id}`, { isActive: status }, { headers: headers });
            if (res.status == 200) {

                if (status == 1) {
                    // toast.success('Notification activated successfully.')
                    toast.success(res.data.ErrorMessage)

                } else {
                    toast.success(res.data.ErrorMessage)
                    // toast.success('Notification deactivated successfully.')

                }
                getNotification()
            } else {
                toast.error(res.data.ErrorMessage)
            }
        } catch (error) {

            if (error.response.status == 401) {
                localStorage.removeItem('userData')
                localStorage.removeItem('authorization')
                navigate("/");
            } else if (error.response.status == 403) {
                toast.error(error.response.data.ErrorMessage)

            } else {
                toast.error('Server error occurring, Please try after some time.')

            }
        }

    }
    const getNotificationDetails = async (id) => {
        try {
            let res = await axios.get(`${baseURL}/get/dymanicNotificationTextsbyid/${id}`, { headers: headers });
            if (res.status == 200) {
                // console.log('res.data.GetdymanicNotificationTextsById', res.data.GetdymanicNotificationTextsById);
                setNotificationData(res.data.GetdymanicNotificationTextsById)
                setIsEdit(true)
                setIsOpneAddNotification(true)
            } else {
                toast.error(res.data.ErrorMessage)
            }
        } catch (error) {

            if (error.response.status == 401) {
                localStorage.removeItem('userData')
                localStorage.removeItem('authorization')
                navigate("/");
            } else if (error.response.status == 403) {
                toast.error(error.response.data.ErrorMessage)

            } else {
                toast.error('Server error occurring, Please try after some time.')

            }
        }

    }
    // const getCustomer = async (id) => {
    //     if (pageNumber > 1) {
    //         var pageNumbers = pageNumber - 1
    //         var skip = pageNumbers * limit;
    //     } else {
    //         var skip = 0
    //     }
    //     var setdata = {
    //         "skip": skip,
    //         "limit": +limit,
    //         packageId: id
    //     }
    //     try {
    //         let res = await axios.post(`${baseURL}/assignPackages/getCurrentAssignUserByPackageId/`, setdata,{headers:headers});
    //         if (res.status == 200) {
    //             console.log('res.daata', res.data);
    //             setPlanStatus(false);

    //             let filteredArray = res.data.getCurrentassignPackageByPackageId.result.filter(obj => obj);
    //             console.log('filteredArray', filteredArray);
    //             setCustomer(filteredArray)
    //             setCustomerForSearch(filteredArray)
    //             var pageCount = Math.round(res.data.getCurrentassignPackageByPackageId.totalCount / limit);
    //             console.log('pageCount', pageCount);
    //             setCountPage(pageCount)
    //         } else {
    //             toast.error(res.data.ErrorMessage)
    //         }
    //     } catch (error) {
    //         
    // if (error.response.status == 401) {
    //     localStorage.removeItem('userData')
    //     localStorage.removeItem('authorization')
    //     navigate("/");
    // }


    // toast.error('Server error occurring, Please try after some time.')
    //     }

    // }
    const sorting = async (columnName, columnType) => {

        // var setData = planStatus ? plan : customer
        // if (setData != '') {
        //     if (columnType == 'string') {
        //         setData.sort((a, b) => {
        //             if (updown == 'up') {
        //                 var nameA = a[columnName]?.toLowerCase(); // ignore upper and lowercase
        //                 var nameB = b[columnName]?.toLowerCase(); // ignore upper and lowercase
        //                 if (nameA < nameB) {
        //                     return 1;
        //                 }
        //                 if (nameA > nameB) {
        //                     return -1;
        //                 }
        //                 return 0;
        //             } else if (updown == 'down') {
        //                 var nameA = a[columnName]?.toUpperCase(); // ignore upper and lowercase
        //                 var nameB = b[columnName]?.toUpperCase(); // ignore upper and lowercase
        //                 if (nameA < nameB) {
        //                     return -1;
        //                 }
        //                 if (nameA > nameB) {
        //                     return 1;
        //                 }
        //                 return 0;
        //             }
        //         });
        //     } else if (columnType == 'int') {
        //         if (updown == 'up') {
        //             setData.sort((a, b) => b[columnName] - a[columnName]);
        //         } else if (updown == 'down') {
        //             setData.sort((a, b) => a[columnName] - b[columnName]);
        //         }
        //     }
        //     var data = planStatus ? setPlan(setData)
        //         : setCustomer(setData)

        // }

    }
    useEffect(() => {
        document.title = `${appName} | ${title}`;
        setHeaderHeadding('Notifications')
        getNotification()
    }, []);
    useEffect(() => {
        getNotification()
    }, [limit, pageNumber])

    return (
        <>
            {isLoading ? <div className="main_loader">
                <span className="loader-11"></span>
            </div> : null}
            <div className={`main_slide ${toggleClass ? 'sidebar_open' : ''}`}>
                <Header />
                <Navbar activeLink={activeLink} setActiveLink={setActiveLink} />
                <div className="wrap_us conten_set">
                    <div className="">
                        <div className="">

                            <div className="card_me table_des1">
                                {/* <div className="top_aseh justify-content-end">
                                    <div>
                                        <button className='form-control'
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                setIsOpneAddPlan(true)
                                            }}
                                        > Add Plan </button>
                                    </div>
                                </div> */}
                                <div className="top_aseh">
                                    <span></span>
                                    {/* <h4>List of Customer</h4> */}
                                    {/* <div className="searh_m2">
                                        Search keyword : <input type="text" placeholder="Keyword..." className="form-control" />
                                    </div> */}
                                </div>
                                <div className="table-responsive">

                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th >S. No.</th>
                                                <th className="" onClick={(e) => {
                                                    if (updown == 'down') {
                                                        setUpdown('up')

                                                    } else {
                                                        setUpdown('down')
                                                    }
                                                    sorting('price', 'int')
                                                    setClassName('price')

                                                }}>Message Type</th>
                                                <th className="" onClick={(e) => {
                                                    if (updown == 'down') {
                                                        setUpdown('up')

                                                    } else {
                                                        setUpdown('down')
                                                    }
                                                    sorting('name', 'string')
                                                    setClassName('name')

                                                }}>Title</th>

                                                <th className="" onClick={(e) => {
                                                    if (updown == 'down') {
                                                        setUpdown('up')

                                                    } else {
                                                        setUpdown('down')
                                                    }
                                                    sorting('frequency', 'string')
                                                    setClassName('frequency')

                                                }}>Message</th>
                                                <th className="" onClick={(e) => {
                                                    if (updown == 'down') {
                                                        setUpdown('up')

                                                    } else {
                                                        setUpdown('down')
                                                    }
                                                    sorting('noOfFrequency', 'int')
                                                    setClassName('noOfFrequency')

                                                }}>Update Title</th>
                                                <th className="" onClick={(e) => {
                                                    if (updown == 'down') {
                                                        setUpdown('up')
                                                    } else {
                                                        setUpdown('down')
                                                    }
                                                    sorting('isFree', 'int')
                                                    setClassName('isFree')

                                                }}>Update Message</th>
                                                <td></td>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {notification.length > 0 &&
                                                notification.map((item, index) => {
                                                    return (<>
                                                        <tr>
                                                            <td >{index + 1}</td>
                                                            <td>{item.messageType}</td>
                                                            <td>{item.originalTitle}</td>
                                                            <td>{item.originalMessage}</td>
                                                            <td>{item.title}</td>
                                                            <td>{item.message}</td>
                                                            <td>
                                                                <span className="btn_us1">
                                                                    <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }}
                                                                        overlay={(
                                                                            <Tooltip id="button-tooltip">
                                                                                {item.isActive == 1 ? 'Active' : 'In-active'}
                                                                            </Tooltip>
                                                                        )}
                                                                    >
                                                                        <span className="check_b">
                                                                            <input type="checkbox" checked={item.isActive == 1} onClick={(e) => {
                                                                                changePlanStatus(e.target.checked, item._id)
                                                                            }} />
                                                                            <span></span>
                                                                        </span>
                                                                    </OverlayTrigger>
                                                                    <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }}
                                                                        overlay={(
                                                                            <Tooltip id="button-tooltip">
                                                                                Edit
                                                                            </Tooltip>
                                                                        )}>
                                                                        <button className="btn btn_clr" onClick={(e) => {
                                                                            e.stopPropagation()
                                                                            getNotificationDetails(item._id)

                                                                        }}><img src={Edit} alt="" /></button>
                                                                    </OverlayTrigger>
                                                                    {/* <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }}
                                                                        overlay={(
                                                                            <Tooltip id="button-tooltip">
                                                                                Delete
                                                                            </Tooltip>
                                                                        )}>
                                                                        <button className="btn btn_clr" onClick={(e) => {
                                                                            e.stopPropagation()
                                                                            notificationAlert(item._id)

                                                                        }}><img src={Delete} alt="" /></button>
                                                                    </OverlayTrigger> */}

                                                                </span>
                                                            </td>
                                                        </tr>
                                                    </>)
                                                }

                                                )}
                                        </tbody>
                                    </table>

                                </div>
                                {notification.length > 0 && <>
                                    <div className="footer_tab">
                                        <div className="left_t1">
                                            <div className="icon_droup">
                                                <select className="form-control" onChange={(e) => {
                                                    e.stopPropagation()
                                                    setLimit(e.target.value);
                                                    setPageNumber(1)
                                                    // getCustomer()
                                                }}>
                                                    <option value="5" selected={limit == 5 ? true : false}>5</option>
                                                    <option value="10" selected={limit == 10 ? true : false}>10</option>
                                                    <option value="25" selected={limit == 25 ? true : false}>25</option>
                                                    <option value="50" selected={limit == 50 ? true : false}>50</option>
                                                    <option value="100" selected={limit == 100 ? true : false}>100</option>
                                                    <option value="200" selected={limit == 200 ? true : false}>200</option>
                                                </select>
                                            </div>
                                            <p>Lines per page</p>
                                        </div>
                                        <div className="right_t1">
                                            <div className="icon_droup">
                                                <img src={LeftArrow} onClick={(e) => {
                                                    e.stopPropagation()
                                                    if (pageNumber > 1) {
                                                        setPageNumber(pageNumber - 1)
                                                    }
                                                }} />
                                                <input type="text" value={pageNumber} className="form-control" />
                                                <img src={RightArrow} onClick={(e) => {
                                                    e.stopPropagation()
                                                    if (countPage > pageNumber) {
                                                        setPageNumber(pageNumber + 1)
                                                    }
                                                }} />
                                            </div>
                                            <p>of {countPage}</p>
                                        </div>
                                    </div>

                                </>}
                                {/* <div className="footer_tab">
                                        <div className="left_t1">
                                            <div className="icon_droup">
                                                <img src={LeftArrow} />
                                                <input type="text" value="10" className="form-control" />
                                                <img src={RightArrow} />
                                            </div>
                                            <p>Lines per page</p>
                                        </div>
                                        <div className="right_t1">
                                            <div className="icon_droup">
                                                <img src={LeftArrow} />
                                                <input type="text" value="10" className="form-control" />
                                                <img src={RightArrow} />
                                            </div>
                                            <p>of 293</p>
                                        </div>
                                    </div> */}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <AddNotificationModel isOpen={isOpneAddNotification} setIsOpen={setIsOpneAddNotification} getNotification={getNotification} isEdit={isEdit} setIsEdit={setIsEdit} notificationData={notificationData} />
            <ToastContainer />
        </>
    );
};

export default Notification;
