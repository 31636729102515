import React, { useEffect, useContext, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'
import { appName } from "../../constants/constants";
import AuthContext from "../../context/AuthContext";
import AppContext from "../../context/AppContext";
import NavbarMenu from "../navbar/Navbar"
import NotFoundComponent from "../not-found/NotFoundComponent";
import Header from "../commonPage/header/Header";
import Navbar from "../navbar/Navbar";
import Edit from '../../assets/img/edit.png'
import Delete from '../../assets/img/delete.png'
import LeftArrow from '../../assets/img/left_arrow.png'
import RightArrow from '../../assets/img/right_arrow.png'
import EyeIcon from '../../assets/img/eye.png'
import axios from 'axios';
import useAxios from "../../hooks/useAxios";
import ReactPlayer from "react-player";
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { parseISO, format, nextFriday } from "date-fns";
import ViewGalletyModel from "../modals/ViewGallery";
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import VideoThumbnail from 'react-video-thumbnail';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import { tr } from "date-fns/locale";

const timeout = 1000 * 60 * 30;

const CustomerManagement = ({ title }) => {
  const headers = {
    'Content-Type': 'application/json',
    'authorization': `Bearer ${localStorage.getItem("authorization")}`
  }
  const navigate = useNavigate();
  const baseURL = process.env.REACT_APP_BACKEND_BASE_URL;
  const api = useAxios();
  const { activeLink, setActiveLink, activePrevLink, toggleClass, setHeaderHeadding, customersID, setCustomersID } = useContext(AppContext);
  const { user, logoutUser } = useContext(AuthContext);
  const [detailsStatus, setDetailsStatus] = useState(false);
  const [activaTab, setActivaTab] = useState('profile')
  const [limit, setLimit] = useState()
  const [countPage, setCountPage] = useState('')
  const [pageNumber, setPageNumber] = useState(1)
  const [searchText, setSearchText] = useState("");
  const [customerForSearch, setCustomerForSearch] = useState("");
  const [customer, setCustomer] = useState([])
  const [customerID, setCustomerID] = useState([])
  const [profileData, setProfileData] = useState('')
  const [challengeData, setChallengeData] = useState([])
  const [galleryData, setGalleryData] = useState([])
  const [badgesData, setBadgesData] = useState([])
  const [isOpenVideo, setIsOpenVideo] = useState(false)
  const [videoUrl, setVideoUrl] = useState({})
  const [packageData, setPackageData] = useState([])
  const [videoId, setVideoId] = useState('')
  const [isLoading, setIsLoading] = useState(true);
  const [previousChallengeId, setPreviousChallengeId] = useState(0)
  const [nextChallengeId, setNextChallengeID] = useState(0)
  const [activeVideo, setActiveVideo] = useState(0)
  const [className, setClassName] = useState('')
  const [differenceInDays, setDifferenceInDays] = useState([])
  const [pagination, setPagination] = useState(false)
  const [isPartiallyForBadges, setIsPartiallyForBadges] = useState()




  const [updown, setUpdown] = useState('')
  const [galleryHandel, setGalleryHandel] = useState(false)

  const getCustomer = async () => {
    if (pageNumber > 1) {
      var pageNumbers = pageNumber - 1
      var skip = pageNumbers * limit;
    } else {
      var skip = 0
    }
    var lastLimit = limit
    if (limit == null) {
      lastLimit = 25
    }
    var setdata = {
      "skip": skip,
      "limit": +lastLimit
    }
    try {
      let res = await axios.post(`${baseURL}/customer/getAllfilteredwithPagination/`, setdata, { headers: headers });
      if (res.status == 200) {
        setIsLoading(false)
        setCustomer(res.data.getAllfilteredwithPagination.customersList)
        setCustomerForSearch(res.data.getAllfilteredwithPagination.customersList)
        var pageCount = Math.ceil(res.data.getAllfilteredwithPagination.totalCount / lastLimit);
        console.log('pageCount');
        setCountPage(pageCount)
      } else {

        toast.error(res.data.ErrorMessage)
      }
    } catch (error) {

      if (error.response.status == 401) {
        localStorage.removeItem('userData')
        localStorage.removeItem('authorization')
        navigate("/");
      } else if (error.response.status == 403) {
        toast.error(error.response.data.ErrorMessage)

      } else {
        toast.error('Server error occurring, Please try after some time.')

      }
    }

  }
  const handleChange = value => {

    setSearchText(value);
    filterData(value);
  };
  const filterData = async (value) => {
    const lowercasedValue = value.toLowerCase().trim();
    if (lowercasedValue === "") {


      setCustomerForSearch(customerForSearch); setCustomer(customerForSearch)
    } else {
      console.log('customer', customer);

      var filteredNameData = await customer.filter((item) =>
        item.name.toLowerCase().includes(lowercasedValue.toLowerCase())
      );

      var filteredData = filteredNameData
      // if (filteredData.length == 0) {
      var filteredEmailData = await customer.filter((item) =>
        item.email.toLowerCase().includes(lowercasedValue.toLowerCase())
      );
      if (filteredEmailData.length > 0) {
        filteredEmailData.forEach(element => {
          const matchingItem = filteredData.find(item2 => item2._id === element._id);
          if (matchingItem) {

          } else {
            filteredData.push(element)
          }
        });
      }

      var filteredActiveData = await customer.filter((item) =>
        item.activePlan.toLowerCase().includes(lowercasedValue.toLowerCase())
      );
      filteredActiveData.forEach(element => {
        const matchingItem = filteredData.find(item2 => item2._id === element._id);
        if (matchingItem) {

        } else {
          filteredData.push(element)
        }
      })
      setCustomer(filteredData);
    }
  }
  const getCustomerDetails = async (id) => {
    try {
      let res = await axios.get(`${baseURL}/get/customersbyid/${id}`, { headers: headers });
      if (res.status == 200) {
        setProfileData(res.data.GetcustomersById)
        setCustomerID(id)
      } else {
        toast.error(res.data.ErrorMessage)
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem('userData')
        localStorage.removeItem('authorization')
        navigate("/");
      } else if (error.response.status == 403) {
        toast.error(error.response.data.ErrorMessage)

      } else {
        toast.error('Server error occurring, Please try after some time.')

      }
    }

  }
  const getChallenge = async (id) => {
    console.log('dsdgfdgdgdgdfgdf');
    if (pageNumber > 1) {
      var pageNumbers = pageNumber - 1
      var skip = pageNumbers * limit;
    } else {
      var skip = 0
    }
    var setData = {
      customerId: id,
      "skip": skip,
      "limit": +limit
    }
    try {
      let res = await axios.post(`${baseURL}/challenges/getAllChallengeByCustomerId/`, setData, { headers: headers });
      if (res.status == 200) {
        var chalangeData = []
        res.data.getAllChallengeByCustomerId.data.forEach((element, index) => {
          let repType = element.repType
          if (element.repType == 'Week') {
            repType = 'Days'
          }

          chalangeData.push({
            levelName: element?.levels?.name,
            exerciseTypes: element?.exercises?.exerciseTypes?.name,
            exercisesName: element?.exercises?.name,
            exerciseDurations: element?.exerciseDurations?.name != undefined ? `${element?.dayCount}  ${element?.exerciseDurations?.name}` : `${element?.dayCount} ${repType}`,
            exerciseCount: element?.exerciseCount,
            createdAt: element?.createdAt,
            item: element,
            _id: element?._id,
          })
        });

        setChallengeData(chalangeData)
        var pageCount = Math.round(res.data.getAllChallengeByCustomerId.totalCount / limit);
        setCountPage(pageCount == 0 ? 1 : pageCount)
      } else {
        toast.error(res.data.ErrorMessage)
      }
    } catch (error) {
      console.log('error', error);

      if (error.response.status == 401) {
        localStorage.removeItem('userData')
        localStorage.removeItem('authorization')
        navigate("/");
      } else if (error.response.status == 403) {
        toast.error(error.response.data.ErrorMessage)

      } else {
        toast.error('Server error occurring, Please try after some time.')

      }
    }

  }
  const changeCustomerStatus = async (e, id) => {
    var status = e ? 1 : 0;
    try {
      let res = await axios.put(`${baseURL}/activedeactive/customers/${id}`, { isActive: status }, { headers: headers });
      if (res.status == 200) {

        if (status == 1) {
          // toast.success('Customer activated successfully.')
          toast.success(res.data.ErrorMessage)

        } else {
          // toast.success('Customer deactivated successfully.')
          toast.success(res.data.ErrorMessage)
        }
        getCustomer()
      } else {
        toast.error(res.data.ErrorMessage)
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem('userData')
        localStorage.removeItem('authorization')
        navigate("/");
      } else if (error.response.status == 403) {
        toast.error(error.response.data.ErrorMessage)

      } else {
        toast.error('Server error occurring, Please try after some time.')

      }
    }

  }
  const customerAlert = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then(async (result) => {

      if (result.isConfirmed) {
        try {
          let res = await axios.delete(`${baseURL}/delete/customers/${id}`, { headers: headers });
          if (res.status == 200) {

            Swal.fire({
              title: "Deleted!",
              icon: "success"
            });
            getCustomer()
          } else {
            Swal.fire({
              title: "Deleted!",
              text: "Something want worng.",
              icon: "error"
            });
          }
          getCustomer()
        } catch (error) {
          if (error.response.status == 401) {
            localStorage.removeItem('userData')
            localStorage.removeItem('authorization')
            navigate("/");
          } else if (error.response.status == 403) {
            toast.error(error.response.data.ErrorMessage)

          } else {
            toast.error('Server error occurring, Please try after some time.')

          }
        }

      }
    });
  }
  const getGallery = async (id) => {

    if (pageNumber > 1) {
      var pageNumbers = pageNumber - 1
      var skip = pageNumbers * limit;
    } else {
      var skip = 0
    }
    var setData = {
      customerId: id,
      "skip": skip,
      "limit": +limit
    }
    try {
      let res = await axios.post(`${baseURL}/challenges/getAllChallengeByCustomerId/`, setData, { headers: headers });
      if (res.status == 200) {
        var chalangeDataByGallery = []
        res.data.getAllChallengeByCustomerId.data.forEach((element, index) => {
          if (element.customerDayWiseExercises[0] != undefined) {
            chalangeDataByGallery.push({
              levelName: element?.levels?.name,
              exerciseTypes: element?.exercises?.exerciseTypes?.name,
              exercisesName: element?.exercises?.name,
              exerciseDurations: element?.exerciseDurations?.name != undefined ? `${element?.dayCount}  ${element?.exerciseDurations?.name}` : `${element?.dayCount} ${element.repType}`,
              exerciseCount: element?.exerciseCount,
              createdAt: element?.createdAt,
              item: element,
              _id: element?._id,
            })
          }
        });
        console.log('chalangeDataByGallery', chalangeDataByGallery);
        setChallengeData(chalangeDataByGallery)
        // setChallengeData()

      } else {
        toast.error(res.data.ErrorMessage)
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem('userData')
        localStorage.removeItem('authorization')
        navigate("/");
      } else if (error.response.status == 403) {
        toast.error(error.response.data.ErrorMessage)

      } else {
        toast.error('Server error occurring, Please try after some time.')

      }
    }
  }
  const getBadges = async (id) => {
    if (pageNumber > 1) {
      var pageNumbers = pageNumber - 1
      var skip = pageNumbers * limit;
    } else {
      var skip = 0
    }
    var setData = {
      customerId: id,
      isPartially: isPartiallyForBadges,
      "skip": skip,
      "limit": +limit
    }
    try {
      let res = await axios.post(`${baseURL}/userBadges/getUserBadgesDetailswithPaginationByCustomerId/`, setData, { headers: headers });
      if (res.status == 200) {

        var badgesData = []
        res.data.getUserBadgesDetailswithPaginationByCustomerId.userBadgesList.forEach((element, index) => {
          badgesData.push({
            name: element?.customerId?.name,
            activePlan: element?.customerId?.activePlan,
            levelName: element?.challengeId?.levelId?.name,
            createdAt: element?.createdAt,
            item: element,
            _id: element?._id
          })
        });

        setBadgesData(badgesData)
        var pageCount = Math.round(res.data.getUserBadgesDetailswithPaginationByCustomerId.totalCount / limit);
        setCountPage(pageCount == 0 ? 1 : pageCount)
      } else {
        toast.error(res.data.ErrorMessage)
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem('userData')
        localStorage.removeItem('authorization')
        navigate("/");
      } else if (error.response.status == 403) {
        toast.error(error.response.data.ErrorMessage)

      } else {
        toast.error('Server error occurring, Please try after some time.')

      }
    }

  }
  const getPackage = async (id) => {
    if (pageNumber > 1) {
      var pageNumbers = pageNumber - 1
      var skip = pageNumbers * limit;
    } else {
      var skip = 0
    }
    var setData = {
      customerId: id,
      "skip": skip,
      "limit": +limit
    }
    try {
      let res = await axios.post(`${baseURL}/customer/getAssignPackageByCustomerId/`, setData, { headers: headers });
      if (res.status == 200) {
        setPackageData(res.data.getAssignPackageByCustomerId.assignPackages)
        var pageCount = Math.round(res.data.getAssignPackageByCustomerId.totalCount / limit);
        setCountPage(pageCount == 0 ? 1 : pageCount)
      } else {
        toast.error(res.data.ErrorMessage)
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem('userData')
        localStorage.removeItem('authorization')
        navigate("/");
      } else if (error.response.status == 403) {
        toast.error(error.response.data.ErrorMessage)

      } else {
        toast.error('Server error occurring, Please try after some time.')

      }
    }

  }
  const getChallengeDetail = async (id) => {
    if (id) {
      setVideoId('')

      var setData = {
        challengeId: id,

      }
      try {
        let res = await axios.post(`${baseURL}/challenges/getChallangeByChallengeId/`, setData, { headers: headers });
        if (res.status == 200) {
          setVideoUrl(res.data?.getChallangeByChallengeId[0])


          let index = challengeData.findIndex(obj => obj._id == id);
          await getID(index)

          // var priviceIndex = index - 1;
          // var nextIndex = index + 1;
          // challengeData.map((item1, index1) => {
          //   if (priviceIndex == index1) {
          //     
          //     setPreviousChallengeId(item1._id)
          //   } else {
          //     setPreviousChallengeId('')
          //   } if (nextIndex == index1) {
          //     setNextChallengeID(item1._id)
          //   } else {
          //     setNextChallengeID('')
          //   }
          //   if (index1 == 0) {
          //     
          //     if (item1.customerDayWiseExercises[0]?.status == 'Complete') {
          //       setVideoId(`https://stage.trulinco.com/nrth-fitness-api/${item1.customerDayWiseExercises[0]?.videoUrl}`)
          //     } else {
          //       setVideoId('')
          //     }
          //   }
          // })
        } else {
          toast.error(res.data.ErrorMessage)
        }
      } catch (error) {
        if (error.response.status == 401) {
          localStorage.removeItem('userData')
          localStorage.removeItem('authorization')
          navigate("/");
        } else if (error.response.status == 403) {
          toast.error(error.response.data.ErrorMessage)

        } else {
          toast.error('Server error occurring, Please try after some time.')

        }
      }
    }
  }
  const sorting = async (columnName, columnType) => {

    var setData = detailsStatus == false ? customer
      : detailsStatus == true && activaTab == 'packageHistory' ? packageData
        : detailsStatus == true && activaTab == 'challenges' ? challengeData
          : detailsStatus == true && activaTab == 'gallery' ? challengeData
            : detailsStatus == true && activaTab == 'badges' ? badgesData : ''
    if (setData != '') {
      if (columnType == 'string') {
        setData.sort((a, b) => {
          if (updown == 'up') {
            var nameA = a[columnName]?.toLowerCase(); // ignore upper and lowercase
            var nameB = b[columnName]?.toLowerCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return 1;
            }
            if (nameA > nameB) {
              return -1;
            }
            return 0;
          } else if (updown == 'down') {
            var nameA = a[columnName]?.toUpperCase(); // ignore upper and lowercase
            var nameB = b[columnName]?.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          }
        });
      } else if (columnType == 'int') {
        if (updown == 'up') {
          setData.sort((a, b) => b[columnName] - a[columnName]);

        } else if (updown == 'down') {
          setData.sort((a, b) => a[columnName] - b[columnName]);
        }
      }
      var data = detailsStatus == false ? setCustomer
        : detailsStatus == true && activaTab == 'packageHistory' ? setPackageData(setData)
          : detailsStatus == true && activaTab == 'challenges' ? setChallengeData(setData)
            : detailsStatus == true && activaTab == 'gallery' ? setChallengeData(setData)
              : detailsStatus == true && activaTab == 'badges' ? setBadgesData(setData) : ''

    }

  }
  useEffect(() => {
    document.title = `${appName} | ${title}`;
    if (detailsStatus) {
      setHeaderHeadding('CUSTOMERS : DETAILS')
    } else if (customersID) {

      setHeaderHeadding('CUSTOMERS : DETAILS')
      getCustomerDetails(customersID)
      setDetailsStatus(true)
    } else {
      if (pagination) {

      } else {
        setLimit(25)

      }
      setHeaderHeadding('CUSTOMERS : LIST')
    }
    getCustomer()

    return () => {
      if (customersID) {
        setCustomersID('')
      }
    };

  }, []);



  const getID = (index) => {
    // console.log('index', index);
    // console.log(challengeData);
    if (challengeData[index - 1] != undefined && challengeData[index - 1].item.customerDayWiseExercises.length > 0) {

      var prev = index == 0 ? 0 : challengeData[index - 1]._id;
      console.log('prev', prev);
      setPreviousChallengeId(prev)

    } else {
      setPreviousChallengeId(0)
    }
    if (challengeData[index + 1] != undefined && challengeData[index + 1].item.customerDayWiseExercises.length > 0) {
      var next = index == challengeData.length - 1 ? 0 : challengeData[index + 1]._id
      console.log('next', next);
      setNextChallengeID(next)
    } else {
      // console.log('nextfd', nextFriday);

      setNextChallengeID(0)
    }
    if (challengeData[index] != undefined && challengeData[index]) {
      if (challengeData[index]?.item?.customerDayWiseExercises[0]?.videoUrl != undefined && challengeData[index]?.item?.customerDayWiseExercises[0]?.videoUrl != '') {
        setVideoId(challengeData[index]?.item?.customerDayWiseExercises[0]?.videoUrl)
      } else {
        setVideoId('')
      }
      // console.log('challengeData[index]', challengeData[index]);

    }
  }

  const handleCalculateDifference = () => {
    if (videoUrl.startDate != undefined && videoUrl.startDate != null) {
      const startt = format(parseISO(videoUrl.startDate), "yyyy-MM-dd");
      const endd = format(parseISO(videoUrl.endDate), "yyyy-MM-dd");
      console.log('startt', startt, 'endd', endd);

      const start = new Date(startt);
      console.log('sdate-----', start);
      const end = new Date(endd);
      // console.log('start', start, 'end', end);

      const differenceInTime = end.getTime() - start.getTime();
      console.log('differenceInTime', differenceInTime,);

      const differenceIn = differenceInTime / (1000 * 3600 * 24); // milliseconds to days


      const numbers = [];

      // Loop to generate numbers from 1 to 30
      for (let i = 0; i <= Math.round(differenceIn + 1); i++) {
        if (i > 0) {
          numbers.push(i);
        }

      }
      console.log('numbers', numbers);

      setDifferenceInDays(numbers); // Round to nearest whole number
      console.log('differenceInDays', differenceInDays);
    }
  };
  useEffect(() => {
    if (detailsStatus) {
      setIsLoading(false)
      setHeaderHeadding('CUSTOMERS : DETAILS')
    } else {
      if (pagination) {

      } else {
        setLimit(25)

      }
      setHeaderHeadding('CUSTOMERS : LIST')
    }
  }, [getCustomerDetails]);
  useEffect(() => {
    if (detailsStatus == false) {
      setIsLoading(true)
      getCustomer()
    } else if (activaTab == 'challenges') {
      // getChallenge(customerID)
    } else if (activaTab == 'badges') {
      getBadges(customerID)
    }

  }, [limit, pageNumber])

  useEffect(() => {
    if (activaTab == "gallery") {
      handleCalculateDifference()

    }
  }, [videoUrl, videoId])
  useEffect(() => {
    if (galleryHandel == true) {
      getChallengeDetail(challengeData[0]?._id)
      getID(0)
      // setVideoUrl()
    }
  }, [challengeData]);
  useEffect(() => {
    console.log('activaTab', activaTab);

    if (activaTab == "isPartiallybadges" || activaTab == "badges") {
      getBadges(customerID)

    }
  }, [isPartiallyForBadges])
  return (
    <>
      {isLoading ? <div className="main_loader">
        <span className="loader-11"></span>
      </div> : null}

      <div className={`main_slide ${toggleClass ? 'sidebar_open' : ''}`}>
        <Header />
        <Navbar activeLink={activeLink} setActiveLink={setActiveLink} />

        <div className="wrap_us conten_set">


          {detailsStatus == false && <>

            <div className="">
              {/* <h4>Customer  List</h4> */}
              <div className="card_me table_des1">
                <div className="top_aseh">
                  <span></span>
                  {/* <h4>List of Customer</h4> */}
                  <div className="searh_m2">
                    Search keyword : <input type="text" placeholder="Keyword..." className="form-control" onChange={e => handleChange(e.target.value)} />
                  </div>
                </div>
                <div className="table-responsive">

                  <table className="table">
                    <thead>
                      <tr>
                        <th>S. No.</th>
                        <th className={className == 'name' ? `orderd table-${updown}` : 'orderd table-down'} onClick={(e) => {
                          if (updown == 'down') {
                            setUpdown('up')

                          } else {
                            setUpdown('down')
                          }
                          sorting('name', 'string')
                          setClassName('name')

                        }}>Name</th>
                        <th className={className == 'email' ? `orderd table-${updown}` : 'orderd table-down'} onClick={(e) => {
                          if (updown == 'down') {
                            setUpdown('up')

                          } else {
                            setUpdown('down')
                          }
                          sorting('email', 'string')
                          setClassName('email')

                        }}>Email</th>
                        <th className={className == 'activePlan' ? `orderd table-${updown}` : 'orderd table-down'} onClick={(e) => {
                          if (updown == 'down') {
                            setUpdown('up')

                          } else {
                            setUpdown('down')
                          }
                          sorting('activePlan', 'string')
                          setClassName('activePlan')

                        }}>Active plan</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {customer.length > 0 &&
                        customer.map((item, index) => {
                          return (<>
                            <tr>
                              <td >{index + 1}</td>
                              <td >{item.name}</td>
                              <td>{item.email}</td>
                              <td>{item.activePlan}</td>
                              <td>
                                <span className="btn_us1">
                                  <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }}
                                    overlay={(
                                      <Tooltip id="button-tooltip">
                                        {item.isActive == 1 ? 'Active' : 'In-active'}
                                      </Tooltip>
                                    )}
                                  >
                                    <span className="check_b">
                                      <input type="checkbox" checked={item.isActive == 1} onClick={(e) => {
                                        changeCustomerStatus(e.target.checked, item._id)
                                      }} />
                                      <span></span>
                                    </span>
                                  </OverlayTrigger>
                                  <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }}
                                    overlay={(
                                      <Tooltip id="button-tooltip">
                                        View
                                      </Tooltip>
                                    )}
                                  >
                                    <button className="btn btn_clr" onClick={(e) => {
                                      e.stopPropagation()
                                      setDetailsStatus(true)
                                      getCustomerDetails(item._id)
                                      setIsLoading(true)
                                    }}><img width={25} src={EyeIcon} alt="" /></button>
                                  </OverlayTrigger>
                                  <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }}
                                    overlay={(
                                      <Tooltip id="button-tooltip">
                                        Delete
                                      </Tooltip>
                                    )}
                                  >
                                    <button className="btn btn_clr" onClick={() => customerAlert(item._id)}><img src={Delete} alt="" /></button>
                                  </OverlayTrigger>
                                </span>
                              </td>
                            </tr>
                          </>)
                        })

                      }
                      <td>
                      </td>
                    </tbody>
                  </table>

                </div>
                <div className="footer_tab">
                  <div className="left_t1">
                    <div className="icon_droup">
                      <select className="form-control" onChange={(e) => {
                        e.stopPropagation()
                        setLimit(e.target.value);
                        setPageNumber(1)
                        setPagination(true)
                      }}>
                        <option value="5" selected={limit == 5 ? true : false}>5</option>
                        <option value="10" selected={limit == 10 ? true : false}>10</option>
                        <option value="25" selected={limit == 25 ? true : false}>25</option>
                        <option value="50" selected={limit == 50 ? true : false}>50</option>
                        <option value="100" selected={limit == 100 ? true : false}>100</option>
                        <option value="200" selected={limit == 200 ? true : false}>200</option>
                      </select>
                    </div>
                    <p>Lines per page</p>
                  </div>
                  <div className="right_t1">
                    <div className="icon_droup">
                      <img src={LeftArrow} onClick={(e) => {
                        e.stopPropagation()
                        if (pageNumber > 1) {
                          setPageNumber(pageNumber - 1)

                        }
                      }} />
                      <input type="text" value={pageNumber} className="form-control" />
                      <img src={RightArrow} onClick={(e) => {
                        e.stopPropagation()
                        if (countPage > pageNumber) {
                          setPageNumber(pageNumber + 1)

                        }
                      }} />
                    </div>
                    <p>of {countPage}</p>
                  </div>
                </div>
              </div>
            </div>
          </>}
          {detailsStatus == true && <>
            <div className="back_btn_us mb-3 text-end">


              <button className="btn btn_theme btn_r"

                onClick={(e) => {
                  e.stopPropagation()
                  setDetailsStatus(false)
                  getCustomer()
                  setLimit(25)
                  setActivaTab('profile');
                }}
              >
                Back
              </button>
            </div>

            <div className="">
              {/* <h4>Customer Details</h4> */}
              <div className="">
                <div className="">
                  <div className='w-full items-center generate_docuement'>
                    <div className="template_list_m1 mt_uss">
                      <div className='tab_lisd1 mb-4 profil_tab' style={{ display: 'flex' }}>
                        <button data-tip="Profile"
                          style={{
                            borderBottom: activaTab == 'profile' ? '2px solid white' : 'none',
                          }}
                          onClick={(e) => {
                            e.stopPropagation()
                            setActivaTab('profile');
                            setIsOpenVideo(false)
                            setIsLoading(true)
                            setGalleryHandel(false)

                          }}
                        >
                          Profile
                        </button>
                        <button data-tip="Package History"
                          style={{
                            borderBottom: activaTab == 'packageHistory' ? '2px solid white' : 'none',
                          }}
                          onClick={(e) => {
                            e.stopPropagation()
                            setActivaTab('packageHistory');
                            setLimit(10)
                            setIsOpenVideo(false)
                            setPageNumber(1)
                            getPackage(customerID)
                            setIsLoading(true)
                            setGalleryHandel(false)

                          }}
                        >
                          Package History
                        </button>
                        <button data-tip="Challenge"
                          style={{
                            borderBottom: activaTab == 'challenges' ? '2px solid white' : 'none',
                          }}
                          onClick={(e) => {
                            e.stopPropagation()
                            setActivaTab('challenges');
                            setIsOpenVideo(false)
                            setChallengeData([])
                            getChallenge(customerID)
                            setLimit(10)
                            setPageNumber(1)
                            setIsLoading(true)
                            setGalleryHandel(false)

                          }}
                        >
                          Challenge
                        </button>
                        <button data-tip="Gallery"
                          style={{
                            borderBottom: activaTab == 'gallery' ? '2px solid white' : 'none',
                          }}
                          onClick={(e) => {
                            e.stopPropagation()
                            setActivaTab('gallery');
                            // getChallenge(customerID)
                            getGallery(customerID)
                            setIsOpenVideo(false)
                            setLimit(10)
                            setPageNumber(1)
                            setIsLoading(true)
                            setGalleryHandel(true)
                          }}
                        >
                          Gallery
                        </button>
                        <button data-tip="Badges"
                          style={{

                            borderBottom: activaTab == 'badges' ? '2px solid white' : 'none',
                          }}
                          onClick={(e) => {
                            e.stopPropagation()
                            setActivaTab('badges');
                            // getBadges(customerID)
                            setIsOpenVideo(false)
                            setLimit(10)
                            setPageNumber(1)
                            setIsLoading(true)
                            setGalleryHandel(false)
                            setIsPartiallyForBadges(false)
                          }}
                        >
                          Badges
                        </button>
                        <button data-tip="isPartiallybadges"
                          style={{

                            borderBottom: activaTab == 'isPartiallybadges' ? '2px solid white' : 'none',
                          }}
                          onClick={(e) => {
                            e.stopPropagation()
                            setActivaTab('isPartiallybadges');
                            // getBadges(customerID)
                            setIsOpenVideo(false)
                            setLimit(10)
                            setPageNumber(1)
                            setIsLoading(true)
                            setGalleryHandel(false)
                            setIsPartiallyForBadges(true)
                          }}
                        >
                          Partially  Badges
                        </button>
                        <div className="right_profile" >
                          <h5 style={{ margin: "0px" }}>Name : {profileData.name}</h5>
                          <span>Email : {profileData.email}</span>

                        </div>

                      </div>
                      {activaTab == 'profile' && profileData &&
                        <>

                          <div className="row">
                            <div className="col-md-8">
                              <div className="card_me">
                                <div className="card_body">
                                  <div className="user_pro">
                                    <div className="">
                                      {profileData.profileImageUrl != '' &&
                                        <img className="user_profile_img" src={profileData.profileImageUrl} />
                                        // <img className="user_profile_img" src={`https://stage.trulinco.com/nrth-fitness-api/${profileData.profileImageUrl}`} />

                                      }
                                      {profileData.profileImageUrl == '' &&
                                        <span>{profileData.name.charAt(0).toUpperCase()}</span>
                                      }
                                    </div>
                                    <h4>{profileData.name}
                                      <span>{profileData.email}</span>
                                    </h4>
                                  </div>
                                  <div className="mb-3">
                                    <label> Name</label>
                                    <input placeholder="" type="name" className="form-control" value={profileData.name} />
                                  </div>
                                  <div className="mb-3">
                                    <label> Email</label>
                                    <input placeholder="" type="name" className="form-control" value={profileData.email} disabled />
                                  </div>

                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      }
                      {activaTab == 'packageHistory' &&
                        <>
                          <div className="">
                            <div className="">
                              <div className="card_me table_des1">
                                <div className="top_aseh">

                                  <div className="searh_m2">

                                  </div>
                                </div>
                                <div className="table-responsive">

                                  <table className="table">
                                    <thead>
                                      <tr>
                                        <th >S. No.</th>
                                        <th className={className == 'packageName' ? `orderd table-${updown}` : 'orderd table-down'} onClick={(e) => {
                                          if (updown == 'down') {
                                            setUpdown('up')

                                          } else {
                                            setUpdown('down')
                                          }
                                          sorting('packageName', 'int')
                                          setClassName('packageName')

                                        }}>Name</th>
                                        <th className={className == 'frequency' ? `orderd table-${updown}` : 'orderd table-down'} onClick={(e) => {
                                          if (updown == 'down') {
                                            setUpdown('up')

                                          } else {
                                            setUpdown('down')
                                          }
                                          sorting('frequency', 'int')
                                          setClassName('frequency')

                                        }}>Frequency</th>
                                        <th className="orderd">Apply Coupon</th>
                                        <th>Package Charge</th>
                                        <th>Coupon Code</th>
                                        <th>Discounted Amount</th>
                                        <th>Paid Amounts</th>
                                        <th className={className == 'transactionStatus' ? `orderd table-${updown}` : 'orderd table-down'} onClick={(e) => {
                                          if (updown == 'down') {
                                            setUpdown('up')

                                          } else {
                                            setUpdown('down')
                                          }
                                          sorting('transactionStatus', 'int')
                                          setClassName('transactionStatus')

                                        }}>Status</th>
                                        <th >Created Date</th>
                                        <th >Next Billing Time </th>

                                      </tr>
                                    </thead>
                                    <tbody>
                                      {packageData.length > 0 &&
                                        packageData.map((item, index) => {
                                          return (<>
                                            <tr>
                                              <td >{index + 1}</td>
                                              <td>{item?.packageId?.name}</td>
                                              <td>{item?.frequency}</td>
                                              <td>{item?.applyCoupon ? "Yes" : 'No'}</td>
                                              <td>{item?.amountSubtotal}</td>
                                              <td>{item?.promotionCode}</td>
                                              <td>{item?.discount}</td>
                                              <td>{item?.amountTotal}</td>
                                              <td>{item?.transactionStatus}</td>
                                              <td>{format(parseISO(item.createdAt), "ccc, dd MMM yyyy, HH:mm a")}</td>
                                              <td>{format(parseISO(item.next_billing_time), "ccc, dd MMM yyyy, HH:mm a")}</td>


                                            </tr>
                                          </>)
                                        })}
                                    </tbody>
                                  </table>

                                </div>
                                {packageData.length == 0 &&
                                  <span className="not_found">No data available.</span>
                                }
                                {packageData.length > 0 && <>
                                  <div className="footer_tab">
                                    <div className="left_t1">
                                      <div className="icon_droup">
                                        <select className="form-control" onChange={(e) => {
                                          e.stopPropagation()
                                          setLimit(e.target.value);
                                          setPageNumber(1)
                                          // getCustomer()
                                        }}>
                                          <option value="5" selected={limit == 5 ? true : false}>5</option>
                                          <option value="10" selected={limit == 10 ? true : false}>10</option>
                                          <option value="25" selected={limit == 25 ? true : false}>25</option>
                                          <option value="50" selected={limit == 50 ? true : false}>50</option>
                                          <option value="100" selected={limit == 100 ? true : false}>100</option>
                                          <option value="200" selected={limit == 200 ? true : false}>200</option>

                                        </select>
                                      </div>
                                      <p>Lines per page</p>
                                    </div>
                                    <div className="right_t1">
                                      <div className="icon_droup">
                                        <img src={LeftArrow} onClick={(e) => {
                                          e.stopPropagation()
                                          if (pageNumber > 1) {
                                            setPageNumber(pageNumber - 1)
                                          }
                                        }} />
                                        <input type="text" value={pageNumber} className="form-control" />
                                        <img src={RightArrow} onClick={(e) => {
                                          e.stopPropagation()
                                          if (countPage > pageNumber) {
                                            setPageNumber(pageNumber + 1)
                                          }
                                        }} />
                                      </div>
                                      <p>of {countPage}</p>
                                    </div>
                                  </div>
                                </>}
                              </div>
                            </div>
                          </div>
                        </>
                      }
                      {activaTab == 'challenges' && isOpenVideo == false &&
                        <>
                          <div className="">
                            <div className="">
                              <div className="card_me table_des1">
                                <div className="top_aseh">
                                  <div className="searh_m2">
                                  </div>
                                </div>
                                <div className="table-responsive">

                                  <table className="table">
                                    <thead>
                                      <tr>
                                        <th >S. No.</th>
                                        <th className={className == 'levelName' ? `orderd table-${updown}` : 'orderd table-down'} onClick={(e) => {
                                          if (updown == 'down') {
                                            setUpdown('up')

                                          } else {
                                            setUpdown('down')
                                          }
                                          sorting('levelName', 'string')
                                          setClassName('levelName')

                                        }}>Exercise Level</th>
                                        <th className={className == 'exerciseTypes' ? `orderd table-${updown}` : 'orderd table-down'} onClick={(e) => {
                                          if (updown == 'down') {
                                            setUpdown('up')

                                          } else {
                                            setUpdown('down')
                                          }
                                          sorting('exerciseTypes', 'string')
                                          setClassName('exerciseTypes')

                                        }}>Exercise Type</th>
                                        <th className={className == 'exercisesName' ? `orderd table-${updown}` : 'orderd table-down'} onClick={(e) => {
                                          if (updown == 'down') {
                                            setUpdown('up')

                                          } else {
                                            setUpdown('down')
                                          }
                                          sorting('exercisesName', 'string')
                                          setClassName('exercisesName')

                                        }}>Exercise Name</th>
                                        <th className={className == 'exerciseDurations' ? `orderd table-${updown}` : 'orderd table-down'} onClick={(e) => {
                                          if (updown == 'down') {
                                            setUpdown('up')
                                          } else {
                                            setUpdown('down')
                                          }
                                          sorting('exerciseDurations', 'string')
                                          setClassName('exerciseDurations')

                                        }}>Exercise Duration Name</th>
                                        <th className={className == 'exerciseCount' ? `orderd table-${updown}` : 'orderd table-down'} onClick={(e) => {
                                          if (updown == 'down') {
                                            setUpdown('up')
                                          } else {
                                            setUpdown('down')
                                          }
                                          sorting('exerciseCount', 'int')
                                          setClassName('exerciseCount')

                                        }}>Exercise Count</th>
                                        <th>Created Date</th>
                                        <th>Gallery</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {challengeData.length > 0 &&

                                        challengeData.map((item, index) => {
                                          return (<>
                                            <tr>
                                              <td >{index + 1}</td>
                                              <td>{item.levelName}</td>
                                              <td>{item.exerciseTypes}</td>
                                              <td>{item.exercisesName}</td>
                                              <td>{item.exerciseDurations}</td>
                                              <td>{item.exerciseCount}</td>
                                              <td>{format(parseISO(item.createdAt), "ccc, dd MMM yyyy, HH:mm a")}</td>

                                              <td>
                                                <span className="btn_us1">
                                                  {item.item != undefined && <>
                                                    {/* {item.item?.customerDayWiseExercises?.length > 0 && */}
                                                    <button disabled={item.item?.customerDayWiseExercises?.length > 0 ? false : true} className="btn btn_clr" onClick={(e) => {
                                                      e.stopPropagation()
                                                      setIsOpenVideo(true)
                                                      getID(index)
                                                      setActivaTab('gallery')
                                                      setVideoUrl(item.item)

                                                      if (item.item.customerDayWiseExercises[0]?.videoUrl != undefined && item.item.customerDayWiseExercises[0]?.videoUrl != '') {
                                                        setVideoId(item.item.customerDayWiseExercises[0]?.videoUrl)
                                                      } else {

                                                        setVideoId('')
                                                      }
                                                    }}><img width={25} src={EyeIcon} alt="" /></button>

                                                    {/* // <button className="btn btn_clr" onClick={(e) =>}><img width={25} src={EyeIcon} alt="" /></button> */}
                                                    {/* } */}
                                                  </>}
                                                </span>
                                              </td>
                                            </tr>
                                          </>)
                                        })
                                      }
                                    </tbody>
                                  </table>

                                </div>
                                {challengeData.length == 0 &&
                                  <><span className="not_found">No data available.</span></>
                                }
                                {challengeData.length > 0 && <>
                                  <div className="footer_tab">
                                    <div className="left_t1">
                                      <div className="icon_droup">
                                        <select className="form-control" onChange={(e) => {
                                          e.stopPropagation()
                                          setLimit(e.target.value);
                                          setPageNumber(1)
                                          getChallenge(customerID)
                                          // getCustomer()
                                        }}>
                                          <option value="5" selected={limit == 5 ? true : false}>5</option>
                                          <option value="10" selected={limit == 10 ? true : false}>10</option>
                                          <option value="25" selected={limit == 25 ? true : false}>25</option>
                                          <option value="50" selected={limit == 50 ? true : false}>50</option>
                                          <option value="100" selected={limit == 100 ? true : false}>100</option>
                                          <option value="200" selected={limit == 200 ? true : false}>200</option>
                                        </select>
                                      </div>
                                      <p>Lines per page</p>
                                    </div>
                                    <div className="right_t1">
                                      <div className="icon_droup">
                                        <img src={LeftArrow} onClick={(e) => {
                                          e.stopPropagation()
                                          if (pageNumber > 1) {
                                            setPageNumber(pageNumber - 1)
                                            getChallenge(customerID)
                                          }
                                        }} />
                                        <input type="text" value={pageNumber} className="form-control" />
                                        <img src={RightArrow} onClick={(e) => {
                                          e.stopPropagation()
                                          if (countPage > pageNumber) {
                                            setPageNumber(pageNumber + 1)
                                            getChallenge(customerID)
                                          }
                                        }} />
                                      </div>
                                      <p>of {countPage}</p>
                                    </div>
                                  </div>
                                </>}
                              </div>
                            </div>
                          </div>
                        </>
                      }
                      {activaTab == 'gallery' &&
                        <>
                          <div className="">
                            <div className="">
                              <div className="gallery">
                                {challengeData.length > 0 &&
                                  <div className="row">
                                    <div className="col-md-12">
                                      <h3 className="arrow_act">
                                        <span>
                                          {previousChallengeId != 0 &&
                                            <button type="button" className="btn" disabled={previousChallengeId == 0} onClick={(e) => {
                                              e.stopPropagation()
                                              getChallengeDetail(previousChallengeId);

                                            }} >
                                              <FontAwesomeIcon icon={faChevronLeft} />
                                            </button>
                                          }
                                        </span>
                                        <span>
                                          {Object.keys(videoUrl).length > 0 && <> {videoUrl.exercises.name} </>}
                                        </span>
                                        <span>
                                          {nextChallengeId != 0 &&
                                            <button type="button" className="btn" disabled={nextChallengeId == 0} onClick={(e) => {
                                              e.stopPropagation()
                                              getChallengeDetail(nextChallengeId);
                                            }}  >
                                              <FontAwesomeIcon icon={faChevronRight} />
                                            </button>
                                          }
                                        </span>
                                      </h3>
                                    </div>

                                    <div className="col-md-9">
                                      <div className="card_me ">
                                        <div className="vide_full">
                                          {videoId != 'https://stage.trulinco.com/nrth-fitness-api/' && videoId != '' && <>
                                            <ReactPlayer
                                              url={videoId}
                                              controls={true}
                                              width="750"
                                              height="500"
                                              config={{
                                                file: {
                                                  attributes: {
                                                    controlsList: 'nodownload' // This removes the download option
                                                  }
                                                }
                                              }}
                                            />
                                            {/* <video width="750" height="500" controls controlsList="nodownload">
                                              <source src={videoId} type="video/mp4" />
                                            </video> */}
                                          </>}
                                          {videoId == 'https://stage.trulinco.com/nrth-fitness-api/' || videoId == '' &&
                                            <h3>Without record video.</h3>
                                          }
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="card_me thum_list">
                                        <div className="star_ded">
                                          <span>
                                            Start
                                            <br />
                                            {Object.keys(videoUrl).length > 0 && <> {format(parseISO(videoUrl.startDate), "dd-MM-yyyy")} </>}
                                          </span>
                                          <span>
                                            End
                                            <br />
                                            {Object.keys(videoUrl).length > 0 && <> {format(parseISO(videoUrl.endDate), "dd-MM-yyyy")} </>}
                                          </span>
                                        </div>
                                        <ul className="ul_set">
                                          {/* {Object.keys(videoUrl).length > 0 && <>
                                            {videoUrl.customerDayWiseExercises?.length > 0 &&
                                              videoUrl.customerDayWiseExercises?.map((item, index) => {

                                                return (<>

                                                  {item.status == 'Complete' &&
                                                    <li className={activeVideo == index ? 'active' : ''} >
                                                      <button className={item.videoUrl == '' ? 'btn blank' : 'btn'} disabled={item.videoUrl == ''} onClick={(e) => {
                                                        e.stopPropagation()
                                                        setActiveVideo(index)

                                                        setVideoId(item.videoUrl)
                                                      }}>
                                                        <span>{index + 1}</span>
                                                      </button>
                                                    </li>
                                                  }
                                                </>)
                                              })
                                            }
                                          </>} */}

                                          {/* new code update by dev 01 on 31-07-2024 old code comment in down side */}

                                          {differenceInDays?.length > 0 &&
                                            videoUrl.customerDayWiseExercises?.map((item, index) => {
                                              return (<>
                                                <li className={activeVideo == index ? `active ${item}` : `${activeVideo}-${index}-${item}`} >
                                                  {Object.keys(videoUrl).length > 0 ? (
                                                    <button className={item.videoUrl == '' && item.status == 'Complete' ? 'btn blank empty' : item.videoUrl == '' ? 'btn blank ' : 'btn videoBorder'}
                                                      disabled={item.videoUrl == ''}
                                                      onClick={(e) => {
                                                        e.stopPropagation()
                                                        if (item.videoUrl != '') {
                                                          setActiveVideo(index)
                                                          setVideoId(item.videoUrl)
                                                        }

                                                      }}
                                                    >
                                                      <span>{index + 1}</span>
                                                    </button>
                                                  ) : (

                                                    <button className="btn" >
                                                      <span>{index + 1 + 1}</span>
                                                    </button>
                                                  )}
                                                </li>
                                              </>)
                                            })
                                          }

                                        </ul>
                                        {/* <div className="thumbnail-image conten_set" onClick={(e) => {
                                          e.stopPropagation()
                                          setVideoId('http://103.117.65.42:8000/media/38/38.mp4')
                                        }}>
                                          <VideoThumbnail
                                            videoUrl="http://103.117.65.42:8000/media/38/38.mp4"
                                            thumbnailHandler={(thumbnail) => console.log(thumbnail)}
                                            width={120}
                                            height={80}

                                          />
                                          <h4>
                                            Shikhar Dhawan Masterclass</h4>
                                          <p>Upload Date: 10 min</p>
                                          <p>Duration: 10 min</p>
                                        </div>
                                        <div className="thumbnail-image" onClick={(e) => {
                                          e.stopPropagation()
                                          setVideoId('http://103.117.65.42:8000/media/26/26.mp4')
                                        }}>
                                          <VideoThumbnail
                                            videoUrl="http://103.117.65.42:8000/media/26/26.mp4"
                                            thumbnailHandler={(thumbnail) => console.log(thumbnail)}
                                            width={120}
                                            height={80}

                                          />
                                          <h4>
                                            Shikhar Dhawan Masterclass</h4>
                                          <p>Upload Date: 10 min</p>
                                          <p>Duration: 10 min</p>
                                        </div> */}

                                      </div>
                                    </div>



                                  </div>
                                }
                                {challengeData.length == 0 &&
                                  <div className="">
                                    <div className="">
                                      <h3>No data available.</h3>
                                    </div>
                                  </div>
                                }
                              </div>
                            </div>
                          </div>
                        </>
                      }
                      {activaTab == 'badges' &&
                        <>

                          <div className="row">
                            <div className="col-md-12">
                              <div className="gallery">
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="card_me table_des1">
                                      <div className="top_aseh">

                                        <div className="searh_m2">

                                        </div>
                                      </div>
                                      <div className="table-responsive">

                                        <table className="table">
                                          <thead>
                                            <tr>
                                              <th >S. No.</th>
                                              <th className={className == 'name' ? `orderd table-${updown}` : 'orderd table-down'} onClick={(e) => {
                                                if (updown == 'down') {
                                                  setUpdown('up')

                                                } else {
                                                  setUpdown('down')
                                                }
                                                sorting('name', 'string')
                                                setClassName('name')

                                              }}>User Name</th>
                                              <th className={className == 'activePlan' ? `orderd table-${updown}` : 'orderd table-down'} onClick={(e) => {
                                                if (updown == 'down') {
                                                  setUpdown('up')

                                                } else {
                                                  setUpdown('down')
                                                }
                                                sorting('activePlan', 'string')
                                                setClassName('activePlan')

                                              }}>Active Plan</th>

                                              <th className={className == 'levelName' ? `orderd table-${updown}` : 'orderd table-down'} onClick={(e) => {
                                                if (updown == 'down') {
                                                  setUpdown('up')

                                                } else {
                                                  setUpdown('down')
                                                }
                                                sorting('levelName', 'string')
                                                setClassName('levelName')

                                              }}>Exercise Level</th>
                                              <th >Created date</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {badgesData.length > 0 &&
                                              badgesData.map((item, index) => {
                                                return (<>
                                                  <tr>
                                                    <td >{index + 1}</td>
                                                    <td>{item.name}</td>
                                                    <td>{item.activePlan}</td>
                                                    <td>{item.levelName}</td>
                                                    <td>{format(
                                                      parseISO(item.createdAt),
                                                      "ccc, dd MMM yyyy, HH:mm"
                                                    )}</td>
                                                    {/* <td><button className="btn btn_clr" onClick={(e) => {
                                                        e.stopPropagation()
                                                        // setBadgesAwardedDetail(item)
                                                        // setIsOpneViewAwarded(true)
                                                      }}><img width={25} src={EyeIcon} alt="" /></button></td> */}

                                                  </tr>
                                                </>)
                                              })}
                                          </tbody>

                                        </table>

                                      </div>
                                      {badgesData.length == 0 &&
                                        <><span className="not_found">No data available.</span></>
                                      }
                                      {badgesData.length > 0 && <>
                                        <div className="footer_tab">
                                          <div className="left_t1">
                                            <div className="icon_droup">
                                              <select className="form-control" onChange={(e) => {
                                                e.stopPropagation()
                                                setLimit(e.target.value);
                                                setPageNumber(1)
                                                getBadges(customerID)
                                              }}>
                                                <option value="5" selected={limit == 5 ? true : false}>5</option>
                                                <option value="10" selected={limit == 10 ? true : false}>10</option>
                                                <option value="25" selected={limit == 25 ? true : false}>25</option>
                                                <option value="50" selected={limit == 50 ? true : false}>50</option>
                                                <option value="100" selected={limit == 100 ? true : false}>100</option>
                                                <option value="200" selected={limit == 200 ? true : false}>200</option>
                                              </select>
                                            </div>
                                            <p>Lines per page</p>
                                          </div>
                                          <div className="right_t1">
                                            <div className="icon_droup">
                                              <img src={LeftArrow} onClick={(e) => {
                                                e.stopPropagation()
                                                if (pageNumber > 1) {
                                                  setPageNumber(pageNumber - 1)
                                                  getBadges(customerID)
                                                }
                                              }} />
                                              <input type="text" value={pageNumber} className="form-control" />
                                              <img src={RightArrow} onClick={(e) => {
                                                e.stopPropagation()
                                                if (countPage > pageNumber) {
                                                  setPageNumber(pageNumber + 1)
                                                  getBadges(customerID)
                                                }
                                              }} />
                                            </div>
                                            <p>of {countPage}</p>
                                          </div>
                                        </div>
                                      </>}
                                    </div>
                                  </div>
                                </div>


                              </div>
                            </div>
                          </div>
                        </>
                      }
                      {activaTab == 'isPartiallybadges' &&
                        <>

                          <div className="row">
                            <div className="col-md-12">
                              <div className="gallery">
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="card_me table_des1">
                                      <div className="top_aseh">

                                        <div className="searh_m2">

                                        </div>
                                      </div>
                                      <div className="table-responsive">

                                        <table className="table">
                                          <thead>
                                            <tr>
                                              <th >S. No.</th>
                                              <th className={className == 'name' ? `orderd table-${updown}` : 'orderd table-down'} onClick={(e) => {
                                                if (updown == 'down') {
                                                  setUpdown('up')

                                                } else {
                                                  setUpdown('down')
                                                }
                                                sorting('name', 'string')
                                                setClassName('name')

                                              }}>User Name</th>
                                              <th className={className == 'activePlan' ? `orderd table-${updown}` : 'orderd table-down'} onClick={(e) => {
                                                if (updown == 'down') {
                                                  setUpdown('up')

                                                } else {
                                                  setUpdown('down')
                                                }
                                                sorting('activePlan', 'string')
                                                setClassName('activePlan')

                                              }}>Active Plan</th>

                                              <th className={className == 'levelName' ? `orderd table-${updown}` : 'orderd table-down'} onClick={(e) => {
                                                if (updown == 'down') {
                                                  setUpdown('up')

                                                } else {
                                                  setUpdown('down')
                                                }
                                                sorting('levelName', 'string')
                                                setClassName('levelName')

                                              }}>Exercise Level</th>
                                              <th >Created date</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {badgesData.length > 0 &&
                                              badgesData.map((item, index) => {
                                                return (<>
                                                  <tr>
                                                    <td >{index + 1}</td>
                                                    <td>{item.name}</td>
                                                    <td>{item.activePlan}</td>
                                                    <td>{item.levelName}</td>
                                                    <td>{format(
                                                      parseISO(item.createdAt),
                                                      "ccc, dd MMM yyyy, HH:mm"
                                                    )}</td>
                                                    {/* <td><button className="btn btn_clr" onClick={(e) => {
                                                        e.stopPropagation()
                                                        // setBadgesAwardedDetail(item)
                                                        // setIsOpneViewAwarded(true)
                                                      }}><img width={25} src={EyeIcon} alt="" /></button></td> */}

                                                  </tr>
                                                </>)
                                              })}
                                          </tbody>

                                        </table>
                                        {badgesData.length == 0 &&
                                          <><span className="not_found">No data available.</span></>
                                        }
                                        {badgesData.length > 0 && <>
                                          <div className="footer_tab">
                                            <div className="left_t1">
                                              <div className="icon_droup">
                                                <select className="form-control" onChange={(e) => {
                                                  e.stopPropagation()
                                                  setLimit(e.target.value);
                                                  setPageNumber(1)
                                                  getBadges(customerID)
                                                }}>
                                                  <option value="5" selected={limit == 5 ? true : false}>5</option>
                                                  <option value="10" selected={limit == 10 ? true : false}>10</option>
                                                  <option value="25" selected={limit == 25 ? true : false}>25</option>
                                                  <option value="50" selected={limit == 50 ? true : false}>50</option>
                                                  <option value="100" selected={limit == 100 ? true : false}>100</option>
                                                  <option value="200" selected={limit == 200 ? true : false}>200</option>
                                                </select>
                                              </div>
                                              <p>Lines per page</p>
                                            </div>
                                            <div className="right_t1">
                                              <div className="icon_droup">
                                                <img src={LeftArrow} onClick={(e) => {
                                                  e.stopPropagation()
                                                  if (pageNumber > 1) {
                                                    setPageNumber(pageNumber - 1)
                                                    getBadges(customerID)
                                                  }
                                                }} />
                                                <input type="text" value={pageNumber} className="form-control" />
                                                <img src={RightArrow} onClick={(e) => {
                                                  e.stopPropagation()
                                                  if (countPage > pageNumber) {
                                                    setPageNumber(pageNumber + 1)
                                                    getBadges(customerID)
                                                  }
                                                }} />
                                              </div>
                                              <p>of {countPage}</p>
                                            </div>
                                          </div>
                                        </>}
                                      </div>
                                    </div>
                                  </div>
                                </div>


                              </div>
                            </div>
                          </div>
                        </>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>}
        </div>
      </div >
      <ToastContainer />
    </>
  );
};

export default CustomerManagement;




// {differenceInDays?.length > 0 &&
//   differenceInDays?.map((item, index) => {
//     return (<>
//       <li className={activeVideo == index ? `active ${item}` : `${activeVideo}-${index}-${item}`} >
//         {Object.keys(videoUrl).length > 0 &&
//           videoUrl.customerDayWiseExercises?.length > 0 ? (
//           <button className={videoUrl.customerDayWiseExercises[index]?.videoUrl == '' && videoUrl.customerDayWiseExercises[index]?.status == 'Complete' ? 'btn blank empty' : videoUrl.customerDayWiseExercises[index]?.videoUrl == '' ? 'btn blank ' : 'btn videoBorder'}
//             disabled={videoUrl.customerDayWiseExercises[index]?.videoUrl == ''}
//             onClick={(e) => {
//               e.stopPropagation()
//               if (videoUrl.customerDayWiseExercises[index].videoUrl != '') {
//                 setActiveVideo(index)
//                 setVideoId(videoUrl.customerDayWiseExercises[index].videoUrl)
//               }

//             }}
//           >
//             <span>{item}</span>
//           </button>
//         ) : (

//           <button className="btn" >
//             <span>{item}</span>
//           </button>
//         )}
//       </li>
//     </>)
//   })
// } 
