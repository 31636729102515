import React, { useContext, useState, useRef, useEffect } from 'react';
import Modal from 'react-modal';
import './modal.css';
import axios from 'axios';
import { toast } from 'react-toastify';
import useAxios from '../../hooks/useAxios';
import { setDate } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';


const customStyles = {
    content: {
        top: '30%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: '500px',
        maxHeight: 'auto',
        borderRadius: '11px',
        minWidth: '500px',
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
};

Modal.setAppElement('#root');
const AddNotificationModel = ({
    isOpen,
    setIsOpen,
    getNotification, isEdit, setIsEdit, notificationData

}) => {
    const headers = {
        'Content-Type': 'application/json',
        'authorization': `Bearer ${localStorage.getItem("authorization")}`
    }
    const navigate = useNavigate();
    const api = useAxios();
    const baseURL = process.env.REACT_APP_BACKEND_BASE_URL;
    const [titleErrorMessage, setTitleNameErrorMessage] = useState('')
    const [messageErrorMessage, setMessageErrorMessage] = useState('')


    const [title, setTitle] = useState('')
    const [message, setMessage] = useState('')



    const createNotifcation = async () => {
        var setdata = {
            title: title,
            message: message,
            messageType: notificationData.messageType,
            // originalMessage: message,
            // message: message,

        }
        try {
            let res = await axios.put(`${baseURL}/dymanicNotificationTexts/update/${notificationData._id}`, setdata, { headers: headers });
            if (res.status == 200) {
                // toast.success('Notification updated successfully.')
                toast.success(res.data.ErrorMessage)

                setIsEdit(false)
                setIsOpen(false)
                getNotification()
            } else {
                toast.error(res.data.ErrorMessage)
            }
        } catch (error) {
            if (error.response.status == 401) {
                localStorage.removeItem('userData')
                localStorage.removeItem('authorization')
                navigate("/");
            } else if (error.response.status == 403) {
                toast.error(error.response.data.ErrorMessage)

            } else {
                toast.error('Server error occurring, Please try after some time.')

            }
        }

    }

    useEffect(() => {

        if (notificationData) {
            setTitle(notificationData.title)
            setMessage(notificationData.message)
        }
    }, [getNotification])
    useEffect(() => {



    }, [message, title])
    return (
        <div className=''>
            <Modal
                isOpen={isOpen}
                onRequestClose={() => setIsOpen(false)}
                style={customStyles}
                onAfterClose={() => {
                    setTitle(''); setMessage('')
                }}
            >
                <div className=' modal-container'>
                    <div className='text-center'>
                        <h3 className='text-2xl font-bold heade_close'> Update Notification <button className='btn btn_theme' onClick={(e) => {
                            setIsOpen(false)
                            setTitle('')
                            setMessage('')
                        }}>
                            <FontAwesomeIcon icon={faXmark} />
                            {/* <FontAwesomeIcon icon={faArrowRightFromBracket} /> */}
                        </button> </h3>
                    </div>
                    <form>
                        <div className="mb-3" >
                            <label>Message Type</label>
                            <input placeholder="Enter title" onChange={(e) => {
                                e.stopPropagation()
                            }} type="text" className='form-control' readOnly value={notificationData?.messageType} />
                        </div>
                        <div style={{ border: "1px solid var(--br)", padding: "10px", marginBottom: "10px" }}>
                            <div className="mb-3">
                                <label>Original Title</label>
                                <input placeholder="Enter title" onChange={(e) => {
                                    e.stopPropagation()
                                }} type="text" className='form-control' readOnly value={notificationData?.originalTitle} />
                            </div>

                            <div className="mb-3" >
                                <label>Original Message</label>
                                <textarea readOnly className="form-control">{notificationData?.originalMessage}</textarea>
                            </div>
                        </div>
                        {/* <div className="mb-3">
                            <label>Title Variable</label>
                            <select className="form-control" onChange={(e) => {
                                e.stopPropagation()
                                setTitle(`${title}  ${e.target.value}`);
                            }} >
                                <option>Select Title Variable</option>
                                {Object.keys(notificationData).length > 0 &&
                                    notificationData.dynamicTitleParam.map((item, index) => {
                                        return (<>
                                            <option value={item} >  {item}</option>
                                        </>)
                                    })
                                }
                            </select>
                        </div> */}
                        <div style={{ border: "1px solid var(--br)", padding: "10px" }}>
                            <div className="mb-3">
                                <label>Title</label>
                                <input placeholder="Enter title" onChange={(e) => {
                                    e.stopPropagation()
                                    if (e.target.value == undefined || e.target.value == '') {
                                        setTitleNameErrorMessage('The Title name field is required.')
                                    } else if (e.target.value.length < 3) {
                                        setTitleNameErrorMessage('Please enter minimum 3 characters.')
                                    } else {
                                        setTitleNameErrorMessage('')
                                    }
                                    setTitle(e.target.value)
                                }} type="text" className='form-control' required value={title} />
                                <p style={{ color: "red" }}>{titleErrorMessage}</p>
                            </div>
                            {/* <div className="mb-3">
                            <label>Message Variable</label>
                            <select className="form-control" onChange={(e) => {
                                e.stopPropagation()
                                console.log('message', message);
                                setMessage(`${message}  ${e.target.value}`);
                            }} >
                                <option>Select Message Variable</option>
                                {Object.keys(notificationData).length &&
                                    notificationData.dynamicMsgParam.map((item, index) => {
                                        return (<>
                                            <option value={item} >  {item}</option>
                                        </>)
                                    })
                                }
                            </select>
                        </div> */}
                            <div className="mb-3" >
                                <label>Message</label>
                                <textarea className="form-control" onChange={(e) => {
                                    e.stopPropagation()
                                    if (e.target.value == undefined || e.target.value == '') {
                                        setMessageErrorMessage('The Message field is required.')
                                    } else if (e.target.value.length < 3) {
                                        setMessageErrorMessage('Please enter minimum 3 characters.')
                                    } else {
                                        setMessageErrorMessage('')
                                    }
                                    setMessage(e.target.value)
                                }} value={message}  >{message}</textarea>
                            </div>
                        </div>
                        <p style={{ color: "red" }}>{messageErrorMessage}</p>
                        <button
                            className='btn btn_theme btn-block'
                            onClick={(e) => {
                                e.preventDefault();
                                createNotifcation()
                            }}
                        >Submit</button>
                    </form>
                </div>
            </Modal>
        </div>
    );
};

export default AddNotificationModel;
