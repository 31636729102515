import React, { useEffect, useContext, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { appName } from "../../constants/constants";
import AuthContext from "../../context/AuthContext";
import AppContext from "../../context/AppContext";
import Navbar from "../navbar/Navbar";
import Header from "../commonPage/header/Header";
import LevelModel from "../modals/Level";
import FusionCharts, { items } from "fusioncharts";
import Charts from 'fusioncharts/fusioncharts.charts';
import ReactFusioncharts from "react-fusioncharts";
import Swal from 'sweetalert2'
import Edit from '../../assets/img/edit.png'
import Delete from '../../assets/img/delete.png'
import LeftArrow from '../../assets/img/left_arrow.png'
import RightArrow from '../../assets/img/right_arrow.png'
import useAxios from "../../hooks/useAxios";
import axios from 'axios';
import Frequency from "../modals/Frequency";
import DistanceModel from "../modals/Distance";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import ExercisesModel from "../modals/Exercises";
import { useNavigate } from "react-router-dom";
import Moment from 'moment';
Charts(FusionCharts);

const timeout = 1000 * 60 * 30;

const Dashboard = ({ title }) => {
  const baseURL = process.env.REACT_APP_BACKEND_BASE_URL;
  const api = useAxios();
  const navigate = useNavigate();
  const { activeLink, setActiveLink, activePrevLink, toggleClass, setHeaderHeadding, setCustomersID } = useContext(AppContext);
  const { user, logoutUser } = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState(false);
  const [buttonName, setButtonName] = useState("");
  const [levelIsOpen, setLevelIsOpen] = useState(false);
  const [frequency, setFrequency] = useState([])
  const [isFrequencyOpen, setFrequencyIsOpen] = useState(false)
  const [frequencyData, setFrequencyData] = useState({})
  const [frequencyEdit, setFrequencyEdit] = useState(false)
  const [distance, setDistance] = useState([])
  const [isDistanceOpen, setDistanceIsOpen] = useState(false)
  const [distanceData, setDistanceData] = useState({})
  const [distanceEdit, setDistanceEdit] = useState(false)
  const [strengthExercise, setStrengthExercise] = useState([])
  const [cardioExercise, setCardioExercise] = useState([])
  const [isExercisesOpen, setExercisesIsOpen] = useState(false)
  const [exercisesEdit, setExercisesEdit] = useState(false)
  const [exercisesData, setExercisesData] = useState({})
  const [exercisesId, setExercisesId] = useState({})
  const [cardioExerciseId, setCardioExerciseId] = useState()
  const [strengthExerciseId, setStrengthExerciseId] = useState()
  const [level, setLevel] = useState([])
  const [levelData, setLevelData] = useState([])
  const [levelEdit, setLevelEdit] = useState(false)
  const [activeFrequency, setActiveFrequency] = useState([])
  const [activeDistance, setActiveDistance] = useState([])
  const [customer, setCustomer] = useState([])
  const [customerGraphCategory, setCustomerGraphCategory] = useState([])
  const [customerGraphValue, setCustomerGraphValue] = useState([])
  const [subscribeGraphCategory, setSubscribeGraphCategory] = useState([])
  const [subscribeGraphValue, setSubscribeGraphValue] = useState([])
  const [freeCustomerCategory, setFreeCustomerGraphCategory] = useState([])
  const [freeCustomeraphValue, setFreeCustomerGraphValue] = useState([])
  const [runningChallengesGraphCategory, setRunningChallengesGraphCategory] = useState([])
  const [runningChallengesGraphValue, setRunningChallengesGraphValue] = useState([])
  const [badgesAwardedGraphCategory, setBadgesAwardedGraphCategory] = useState([])
  const [badgesAwardedGraphValue, setBadgesAwardedGraphValue] = useState([])
  const [limit, setLimit] = useState(5)
  const [countPage, setCountPage] = useState('')
  const [pageNumber, setPageNumber] = useState(1)
  const [searchText, setSearchText] = useState("");
  const [customerForSearch, setCustomerForSearch] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [chartYear, setChartYear] = useState(Moment().format('YYYY'))
  const [callChartFuncation, setCallChartFuncation] = useState(false)
  const [className, setClassName] = useState('')
  const [updown, setUpdown] = useState('')
  const headers = {
    'Content-Type': 'application/json',
    'authorization': `Bearer ${localStorage.getItem("authorization")}`
  }


  useEffect(() => {
    document.title = `${appName} | ${title}`;
    // alert(toggleClass)
    setHeaderHeadding('ADMIN PANEL')
  }, []);

  const dataSource = {
    chart: {
      caption: "Total Users",
      // yaxisname: "Number of deaths reported",
      // subcaption: "(As per government records)",
      // plottooltext:
      // "<b>$dataValue</b> people died because of $seriesName in $label",
      // showsum: "0",
      theme: "fusion",
      bgColor: "#000", // Background color 
      paletteColors: '#cdcbcb,#873a0e,#000',
      // plotFillColor: '#000',
      baseFontColor: '#fff',
      // canvasbgColor: '#000',
      // canvasbgAlpha: "0",
      // canvasBorderThickness: "0",
      // showAlternateHGridColor: "0",
      showCanvasBg: "0",
      showCanvasBase: "0",
      // canvasBaseDepth: "0",
      // canvasBgDepth: "0",
      // canvasBaseColor: "#000",
      // anchorBorderColor: "#127fcb",
      // plotFillHoverColor: "#00ffaa",
      toolTipBgColor: "#000"


    },
    categories: [
      {
        category: freeCustomerCategory
      }
    ],
    dataset: [
      {
        seriesname: "Free",
        data: freeCustomeraphValue
      },
      {
        seriesname: "Subscribed",
        data: subscribeGraphValue
      }
    ],
  };
  const dataSource2 = {
    chart: {
      caption: "Badges Awarded",
      // yaxisname: "Number of deaths reported",
      // subcaption: "(As per government records)",
      // plottooltext:
      // "<b>$dataValue</b> people died because of $seriesName in $label",
      // showsum: "0",
      theme: "fusion",
      bgColor: "#000", // Background color 
      paletteColors: '#873a0e,#000',
      plotFillColor: '#000',
      baseFontColor: '#fff',
      canvasbgColor: '#000',
      canvasbgAlpha: "0",
      canvasBorderThickness: "1",
      showAlternateHGridColor: "0",
      showCanvasBg: "1",
      showCanvasBase: "1",
      canvasBaseDepth: "0",
      canvasBgDepth: "0",
      canvasBaseColor: "#000",
      toolTipBgColor: "#000"

    },
    categories: [
      {
        category: badgesAwardedGraphCategory
      }
    ],
    dataset: [
      {
        seriesname: "Badges Awarded",
        data: badgesAwardedGraphValue
      }
    ],
  };
  const dataSource1 = {
    chart: {
      caption: "Newly Registered",
      // yaxisname: "Number of deaths reported",
      // subcaption: "(As per government records)",
      // plottooltext:
      // "<b>$dataValue</b> people died because of $seriesName in $label",
      // showsum: "0",
      theme: "fusion",
      bgColor: "#000", // Background color 
      paletteColors: '#873a0e,#000',
      plotFillColor: '#000',
      baseFontColor: '#fff',
      canvasbgColor: '#000',
      canvasbgAlpha: "0",
      canvasBorderThickness: "1",
      showAlternateHGridColor: "0",
      showCanvasBg: "1",
      showCanvasBase: "1",
      canvasBaseDepth: "0",
      canvasBgDepth: "0",
      canvasBaseColor: "#000",
      toolTipBgColor: "#000"

    },
    categories: [
      {
        category: customerGraphCategory
      }
    ],
    dataset: [

      {
        seriesname: "Newly Registered",
        data: customerGraphValue,
        fill: "#000"
      }
    ],
  };
  const dataSource3 = {
    chart: {
      caption: "Running Challenge",
      // yaxisname: "Number of deaths reported",
      // subcaption: "(As per government records)",
      // plottooltext:
      // "<b>$dataValue</b> people died because of $seriesName in $label",
      // showsum: "0",
      theme: "fusion",
      bgColor: "#000", // Background color 
      paletteColors: '#cdcbcb,#000',
      plotFillColor: '#000',
      baseFontColor: '#fff',
      canvasbgColor: '#000',
      canvasbgAlpha: "0",
      canvasBorderThickness: "1",
      showAlternateHGridColor: "0",
      showCanvasBg: "1",
      showCanvasBase: "1",
      canvasBaseDepth: "0",
      canvasBgDepth: "0",
      canvasBaseColor: "#000",
      toolTipBgColor: "#000"

    },
    categories: [
      {
        category: runningChallengesGraphCategory
      }
    ],
    dataset: [

      {
        seriesname: "Running Challenge",
        data: runningChallengesGraphValue
      }
    ],
  };

  const getFrequency = async () => {

    try {
      let res = await axios.get(`${baseURL}/getall/exerciseDuration/`, { headers: headers });
      console.log('res', res);

      if (res.status == 200) {
        setFrequency(res.data.getAllexerciseDuration)
      } else {

        toast.error(res.data.ErrorMessage)
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem('userData')
        localStorage.removeItem('authorization')
        navigate("/");
      } else if (error.response.status == 403) {
        toast.error(error.response.data.ErrorMessage)

      } else {
        toast.error('Server error occurring, Please try after some time.')

      }
    }
  }
  const getFrequencyDetails = async (id) => {
    try {
      let res = await axios.get(`${baseURL}/get/exerciseDurationbyid/${id}`, { headers: headers });
      if (res.status == 200) {
        setFrequencyData(res.data.GetexerciseDurationById)
        setFrequencyIsOpen(true);
      } else {
        toast.error(res.data.ErrorMessage)
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem('userData')
        localStorage.removeItem('authorization')
        navigate("/");
      } else if (error.response.status == 403) {
        toast.error(error.response.data.ErrorMessage)

      } else {
        toast.error('Server error occurring, Please try after some time.')

      }
    }

  }
  const frequencyAlert = (id, name) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to delete this ${name} Frequency.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then(async (result) => {

      if (result.isConfirmed) {
        try {
          let res = await axios.delete(`${baseURL}/delete/exerciseDuration/${id}`, { headers: headers });
          if (res.status == 200) {
            Swal.fire({
              title: "Deleted!",

              icon: "success"
            });
          } else {
            Swal.fire({
              title: "Deleted!",
              text: "Something want worng.",
              icon: "error"
            });
          }

        } catch (error) {
          if (error.response.status == 401) {
            localStorage.removeItem('userData')
            localStorage.removeItem('authorization')
            navigate("/");
          } else if (error.response.status == 403) {
            toast.error(error.response.data.ErrorMessage)

          } else {
            toast.error('Server error occurring, Please try after some time.')

          }
        }
        getFrequency()
        setFrequencyEdit(false)
      }
    });
  }
  const changeFrequencyStatus = async (e, id) => {
    var status = e ? 1 : 0;
    try {
      let res = await axios.put(`${baseURL}/activedeactive/exerciseDuration/${id}`, { isActive: status }, { headers: headers });
      if (res.status == 200) {
        if (status == 1) {
          toast.success('Frequency activated successfully.')
        } else {
          toast.success('Frequency deactivated successfully.')
        }
        getFrequency()
      } else {
        toast.error(res.data.ErrorMessage)
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem('userData')
        localStorage.removeItem('authorization')
        navigate("/");
      } else if (error.response.status == 403) {
        toast.error(error.response.data.ErrorMessage)

      } else {
        toast.error('Server error occurring, Please try after some time.')

      }
    }

  }
  const getDistance = async () => {
    try {
      let res = await axios.get(`${baseURL}/getall/exerciseUnit/`, { headers: headers });
      if (res.status == 200) {
        setDistance(res.data.getAllexerciseUnit)
      } else {
        toast.error(res.data.ErrorMessage)
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem('userData')
        localStorage.removeItem('authorization')
        navigate("/");
      } else if (error.response.status == 403) {
        toast.error(error.response.data.ErrorMessage)

      } else {
        toast.error('Server error occurring, Please try after some time.')

      }
    }

  }
  const getDistanceDetails = async (id) => {
    try {
      let res = await axios.get(`${baseURL}/get/exerciseUnitbyid/${id}`, { headers: headers });
      if (res.status == 200) {
        setDistanceData(res.data.GetexerciseUnitById)
        setDistanceIsOpen(true);
      } else {
        toast.error(res.data.ErrorMessage)
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem('userData')
        localStorage.removeItem('authorization')
        navigate("/");
      } else if (error.response.status == 403) {
        toast.error(error.response.data.ErrorMessage)

      } else {
        toast.error('Server error occurring, Please try after some time.')

      }
    }

  }
  const distanceAlert = (id, name) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to delete ${name} Distance.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then(async (result) => {

      if (result.isConfirmed) {
        try {
          let res = await axios.delete(`${baseURL}/delete/exerciseUnit/${id}`, { headers: headers });
          if (res.status == 200) {
            Swal.fire({
              title: "Deleted!",
              icon: "success"
            });
          } else {
            Swal.fire({
              title: "Deleted!",
              text: "Something want worng.",
              icon: "error"
            });
          }
        } catch (error) {
          if (error.response.status == 401) {
            localStorage.removeItem('userData')
            localStorage.removeItem('authorization')
            navigate("/");
          } else if (error.response.status == 403) {
            toast.error(error.response.data.ErrorMessage)

          } else {
            toast.error('Server error occurring, Please try after some time.')

          }
        }

        getDistance()
        setDistanceEdit(false)
      }
    });
  }
  const changeDistanceStatus = async (e, id) => {
    var status = e ? 1 : 0;
    try {
      let res = await axios.put(`${baseURL}/activedeactive/exerciseUnit/${id}`, { isActive: status }, { headers: headers });
      if (res.status == 200) {
        if (status == 1) {
          toast.success('Distance activated successfully.')
        } else {
          toast.success('Distance deactivated successfully.')
        }
        getDistance()
      } else {
        toast.error(res.data.ErrorMessage)
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem('userData')
        localStorage.removeItem('authorization')
        navigate("/");
      } else if (error.response.status == 403) {
        toast.error(error.response.data.ErrorMessage)

      } else {
        toast.error('Server error occurring, Please try after some time.')

      }
    }

  }
  const getExercises = async () => {
    try {
      let res = await axios.get(`${baseURL}/getAllExerciseTypeWiseExercise/`, { headers: headers });
      if (res.status == 200) {
        setStrengthExercise(res.data.getAllExerciseTypeWiseExercise[0].exercises)
        setStrengthExerciseId(res.data.getAllExerciseTypeWiseExercise[0]._id)
        setCardioExercise(res.data.getAllExerciseTypeWiseExercise[1].exercises)
        setCardioExerciseId(res.data.getAllExerciseTypeWiseExercise[1]._id)

      } else {
        toast.error(res.data.ErrorMessage)
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem('userData')
        localStorage.removeItem('authorization')
        navigate("/");
      } else if (error.response.status == 403) {
        toast.error(error.response.data.ErrorMessage)

      } else {
        toast.error('Server error occurring, Please try after some time.')

      }
    }

  }
  const getExercisesDetails = async (id) => {
    try {
      let res = await axios.get(`${baseURL}/get/exercisesbyid/${id}`, { headers: headers });
      if (res.status == 200) {
        setExercisesData(res.data.GetexercisesById)
        setExercisesIsOpen(true);
      } else {
        toast.error(res.data.ErrorMessage)
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem('userData')
        localStorage.removeItem('authorization')
        navigate("/");
      } else if (error.response.status == 403) {
        toast.error(error.response.data.ErrorMessage)
      } else {
        toast.error('Server error occurring, Please try after some time.')

      }
    }

  }
  const exercisesAlert = (id, name) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to delete ${name} Exercise`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then(async (result) => {

      if (result.isConfirmed) {
        try {
          let res = await axios.delete(`${baseURL}/delete/exercises/${id}`, { headers: headers });
          if (res.status == 200) {
            getExercises()
            Swal.fire({
              title: "Deleted!",

              icon: "success"
            });
          } else {
            Swal.fire({
              title: "Deleted!",
              text: "Something want worng.",
              icon: "error"
            });
          }
        } catch (error) {
          if (error.response.status == 401) {
            localStorage.removeItem('userData')
            localStorage.removeItem('authorization')
            navigate("/");
          } else if (error.response.status == 403) {
            toast.error(error.response.data.ErrorMessage)

          } else {
            toast.error('Server error occurring, Please try after some time.')

          }
        }

        getExercises()
        setExercisesEdit(false)
      }
    });
  }
  const changeExercisesStatus = async (e, id) => {
    var status = e ? 1 : 0;
    try {
      let res = await axios.put(`${baseURL}/activedeactive/exercises/${id}`, { isActive: status }, { headers: headers });
      if (res.status == 200) {
        if (status == 1) {
          toast.success('Exercise activated successfully.')
        } else {
          toast.success('Exercise deactivated successfully.')
        }
        getExercises()
      } else {
        toast.error(res.data.ErrorMessage)
      }
    } catch (error) {
      console.log('error.response', error.response.data);
      if (error.response.status == 401) {
        localStorage.removeItem('userData')
        localStorage.removeItem('authorization')
        navigate("/");
      } else if (error.response.status == 403) {
        toast.error(error.response.data.ErrorMessage)

      } else {
        toast.error('Server error occurring, Please try after some time.')

      }
    }

  }
  const getLevel = async () => {
    try {
      let res = await axios.get(`${baseURL}/get/getLevelWithExercisesType/`, { headers: headers });
      if (res.status == 200) {
        setLevel(res.data.getLevelWithExercisesType)
      } else {
        toast.error(res.data.ErrorMessage)
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem('userData')
        localStorage.removeItem('authorization')
        navigate("/");
      } else if (error.response.status == 403) {
        toast.error(error.response.data.ErrorMessage)

      } else {
        toast.error('Server error occurring, Please try after some time.')

      }
    }

  }
  const getLevelDetails = async (id) => {
    try {
      let res = await axios.get(`${baseURL}/get/levelsDetailbyid/${id}`, { headers: headers });
      if (res.status == 200) {
        setLevelData(res.data.levelsDetailbyid)
        setLevelIsOpen(true);
      } else {
        toast.error(res.data.ErrorMessage)
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem('userData')
        localStorage.removeItem('authorization')
        navigate("/");
      } else if (error.response.status == 403) {
        toast.error(error.response.data.ErrorMessage)

      } else {
        toast.error('Server error occurring, Please try after some time.')

      }
    }

  }
  const levelAlert = (id, name) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to delete ${name} level`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then(async (result) => {

      if (result.isConfirmed) {
        try {
          let res = await axios.delete(`${baseURL}/delete/levels/${id}`, { headers: headers });
          if (res.status == 200) {

            Swal.fire({
              title: "Deleted!",
              icon: "success"
            });
            getLevel()
          } else {
            Swal.fire({
              title: "Deleted!",
              text: "Something want worng.",
              icon: "error"
            });
          }
        } catch (error) {
          if (error.response.status == 401) {
            localStorage.removeItem('userData')
            localStorage.removeItem('authorization')
            navigate("/");
          } else if (error.response.status == 403) {
            toast.error(error.response.data.ErrorMessage)

          } else {
            toast.error('Server error occurring, Please try after some time.')

          }
        }

        getLevel()
        setLevelEdit(false)
      }
    });
  }
  const changeLevelStatus = async (e, id) => {
    var status = e ? 1 : 0;
    try {
      let res = await axios.put(`${baseURL}/activedeactive/levels/${id}`, { isActive: status }, { headers: headers });
      if (res.status == 200) {

        if (status == 1) {
          // toast.success('Level activated successfully.')
          toast.success(res.data.ErrorMessage)

        } else {
          // toast.success('Level deactivated successfully.')
          toast.success(res.data.ErrorMessage)

        }
        getLevel()
      } else {
        toast.error(res.data.ErrorMessage)
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem('userData')
        localStorage.removeItem('authorization')
        navigate("/");
      } else if (error.response.status == 403) {
        toast.error(error.response.data.ErrorMessage)

      } else {
        toast.error('Server error occurring, Please try after some time.')

      }
    }

  }
  const getActiveFrequency = async () => {
    try {
      let res = await axios.get(`${baseURL}/exerciseDuration/getAllActiveModel/`, { headers: headers });
      if (res.status == 200) {
        setActiveFrequency(res.data.getAllActiveModelexerciseDuration)
      } else {
        toast.error(res.data.ErrorMessage)
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem('userData')
        localStorage.removeItem('authorization')
        navigate("/");
      } else if (error.response.status == 403) {
        toast.error(error.response.data.ErrorMessage)

      } else {
        toast.error('Server error occurring, Please try after some time.')

      }
    }

  }
  const getActiveDistance = async () => {
    try {
      let res = await axios.get(`${baseURL}/exerciseUnit/getAllActiveModel/`, { headers: headers });
      if (res.status == 200) {
        setActiveDistance(res.data.getAllActiveModelexerciseUnit)
      } else {
        toast.error(res.data.ErrorMessage)
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem('userData')
        localStorage.removeItem('authorization')
        navigate("/");
      } else if (error.response.status == 403) {
        toast.error(error.response.data.ErrorMessage)

      } else {
        toast.error('Server error occurring, Please try after some time.')

      }
    }

  }
  const getCustomer = async () => {
    setIsLoading(false)

    if (pageNumber > 1) {
      var pageNumbers = pageNumber - 1
      var skip = pageNumbers * limit;
    } else {
      var skip = 0
    }
    var setdata = {
      "skip": skip,
      "limit": +limit
    }
    try {
      let res = await axios.post(`${baseURL}/customer/getAllfilteredwithPagination/`, setdata, { headers: headers });
      if (res.status == 200) {
        setIsLoading(false)
        setCustomer(res.data.getAllfilteredwithPagination.customersList)
        setCustomerForSearch(res.data.getAllfilteredwithPagination.customersList)
        var pageCount = Math.round(res.data.getAllfilteredwithPagination.totalCount / limit);
        setCountPage(pageCount)
      } else {
        toast.error(res.data.ErrorMessage)
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem('userData')
        localStorage.removeItem('authorization')
        navigate("/");
      } else if (error.response.status == 403) {
        toast.error(error.response.data.ErrorMessage)

      } else {
        toast.error('Server error occurring, Please try after some time.')

      }
    }

  }
  const newCustomer = async () => {
    try {
      let res = await axios.get(`${baseURL}/customer/newCustomerGraphofCurrentYear/${chartYear}`, { headers: headers });
      if (res.status == 200) {
        if (res.data.getAllfilteredwithPagination.length > 0) {
          setCustomerGraphCategory(prevCategory => [
            ...prevCategory,
            ...res.data.getAllfilteredwithPagination.map(item => ({ label: item.month }))
          ]);
          setCustomerGraphValue(prevValue => [
            ...prevValue,
            ...res.data.getAllfilteredwithPagination.map(item => ({ value: item.count }))
          ]);
        }
      } else {
        toast.error(res.data.ErrorMessage)
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem('userData')
        localStorage.removeItem('authorization')
        navigate("/");
      } else if (error.response.status == 403) {
        toast.error(error.response.data.ErrorMessage)

      } else {
        toast.error('Server error occurring, Please try after some time.')

      }
    }
  }
  const newSubscribe = async () => {
    try {
      let res = await axios.get(`${baseURL}/customer/newSubscribeAndNonCustomerGraphofCurrentYear/${chartYear}`, { headers: headers });
      if (res.status == 200) {
        // setNewSubscribeGraph(res.data.)
        if (res.data.freeCustomer.length > 0) {
          setFreeCustomerGraphCategory(prevCategory => [
            ...prevCategory,
            ...res.data.freeCustomer.map(item => ({ label: item.month }))
          ]);
          setFreeCustomerGraphValue(prevValue => [
            ...prevValue,
            ...res.data.freeCustomer.map(item => ({ value: item.count }))
          ]);
        }
        if (res.data.subscribeCustomer.length > 0) {
          setSubscribeGraphCategory(prevCategory => [
            ...prevCategory,
            ...res.data.subscribeCustomer.map(item => ({ label: item.month }))
          ]);
          setSubscribeGraphValue(prevValue => [
            ...prevValue,
            ...res.data.subscribeCustomer.map(item => ({ value: item.count }))
          ]);
        }
      } else {
        toast.error(res.data.ErrorMessage)
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem('userData')
        localStorage.removeItem('authorization')
        navigate("/");
      } else if (error.response.status == 403) {
        toast.error(error.response.data.ErrorMessage)

      } else {
        toast.error('Server error occurring, Please try after some time.')

      }
    }

  }
  const runningChallenge = async () => {
    try {
      let res = await axios.get(`${baseURL}/challenges/runningChallengesGraphofCurrentYear/${chartYear}`, { headers: headers });
      if (res.status == 200) {
        if (res.data.runningChallengesGraphofCurrentYear.length > 0) {
          setRunningChallengesGraphCategory(prevCategory => [
            ...prevCategory,
            ...res.data.runningChallengesGraphofCurrentYear.map(item => ({ label: item.month }))
          ]);
          setRunningChallengesGraphValue(prevValue => [
            ...prevValue,
            ...res.data.runningChallengesGraphofCurrentYear.map(item => ({ value: item.count }))
          ]);
        }
      } else {
        toast.error(res.data.ErrorMessage)
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem('userData')
        localStorage.removeItem('authorization')
        navigate("/");
      } else if (error.response.status == 403) {
        toast.error(error.response.data.ErrorMessage)

      } else {
        toast.error('Server error occurring, Please try after some time.')

      }
    }
  }
  const badgesAwarded = async () => {
    try {
      let res = await axios.get(`${baseURL}/userBadges/userBadgesGraphofCurrentYear/${chartYear}`, { headers: headers });
      if (res.status == 200) {
        if (res.data.userBadgesGraphofCurrentYear.length > 0) {
          setBadgesAwardedGraphCategory(prevCategory => [
            ...prevCategory,
            ...res.data.userBadgesGraphofCurrentYear.map(item => ({ label: item.month }))
          ]);
          setBadgesAwardedGraphValue(prevValue => [
            ...prevValue,
            ...res.data.userBadgesGraphofCurrentYear.map(item => ({ value: item.count }))
          ]);
        }
      } else {
        toast.error(res.data.ErrorMessage)
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem('userData')
        localStorage.removeItem('authorization')
        navigate("/");
      } else if (error.response.status == 403) {
        toast.error(error.response.data.ErrorMessage)

      } else {
        toast.error('Server error occurring, Please try after some time.')

      }
    }

  }
  const handleChange = value => {
    setSearchText(value);
    filterData(value);
  };
  const filterData = async (value) => {

    const lowercasedValue = value.toLowerCase().trim();
    if (lowercasedValue === "") { setCustomerForSearch(customerForSearch); setCustomer(customerForSearch) }
    else {
      var filteredData = await customer.filter((item) =>
        item.name.toLowerCase().includes(lowercasedValue.toLowerCase())
      );
      if (filteredData.length == 0) {
        var filteredData = await customer.filter((item) =>
          item.email.toLowerCase().includes(lowercasedValue.toLowerCase())
        );
      }
      setCustomer(filteredData);
    }
  }
  const shortCloume = async (value) => {
    customer.sort((a, b) => {
      return a.name - b.name;
    });
    setCustomer(customer);
  }
  const changeCustomerStatus = async (e, id) => {
    var status = e ? 1 : 0;
    try {
      let res = await axios.put(`${baseURL}/activedeactive/customers/${id}`, { isActive: status }, { headers: headers });
      if (res.status == 200) {

        if (status == 1) {
          // toast.success('Customers activated successfully.')
          toast.success(res.data.ErrorMessage)


        } else {
          toast.success(res.data.ErrorMessage)
          // toast.success('Customers deactivated successfully.')

        }
        getCustomer()
      } else {
        toast.error(res.data.ErrorMessage)
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem('userData')
        localStorage.removeItem('authorization')
        navigate("/");
      } else if (error.response.status == 403) {
        toast.error(error.response.data.ErrorMessage)

      } else {
        toast.error('Server error occurring, Please try after some time.')

      }
    }

  }
  const customerAlert = (id, name) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to delete ${name} customer.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then(async (result) => {

      if (result.isConfirmed) {
        try {
          let res = await axios.delete(`${baseURL}/delete/customers/${id}`, { headers: headers });
          if (res.status == 200) {

            Swal.fire({
              title: "Deleted!",
              icon: "success"
            });
            getCustomer()
          } else {
            Swal.fire({
              title: "Deleted!",
              text: "Something want worng.",
              icon: "error"
            });
          }
        } catch (error) {
          if (error.response.status == 401) {
            localStorage.removeItem('userData')
            localStorage.removeItem('authorization')
            navigate("/");
          } else if (error.response.status == 403) {
            toast.error(error.response.data.ErrorMessage)

          } else {
            toast.error('Server error occurring, Please try after some time.')

          }
        }

        getCustomer()
      }
    });
  }
  const years = [];
  const currentYear = new Date().getFullYear();
  const startYear = 2020; // Change this to your desired start year
  for (let year = startYear; year <= currentYear; year++) {
    years.push(year);
  }

  const handleYearChange = (e) => {
    if (e.target.value) {
      setChartYear(e.target.value);
      setCallChartFuncation(true)
    }

    // You can perform further actions with the selected year here
  };

  const sorting = async (columnName, columnType) => {

    var setData = customer;
    if (setData != '') {
      if (columnType == 'string') {
        setData.sort((a, b) => {
          if (updown == 'up') {
            var nameA = a[columnName]?.toLowerCase(); // ignore upper and lowercase
            var nameB = b[columnName]?.toLowerCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return 1;
            }
            if (nameA > nameB) {
              return -1;
            }
            return 0;
          } else if (updown == 'down') {
            var nameA = a[columnName]?.toUpperCase(); // ignore upper and lowercase
            var nameB = b[columnName]?.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          }
        });
      } else if (columnType == 'int') {
        if (updown == 'up') {
          setData.sort((a, b) => b[columnName] - a[columnName]);
          console.log('runningChallenge', setData);

        } else if (updown == 'down') {
          console.log('updown24dd', updown);
          setData.sort((a, b) => a[columnName] - b[columnName]);
        }
      }
      var data = setCustomer(setData)

    }

  }

  useEffect(() => {
    getFrequency()
    getDistance()
    getExercises()
    getLevel()
    getCustomer()
    setCustomerGraphCategory([])
    setCustomerGraphValue([])
    newCustomer()
    setSubscribeGraphCategory([])
    setSubscribeGraphValue([])
    setFreeCustomerGraphCategory([])
    setFreeCustomerGraphValue([])
    newSubscribe()
    setRunningChallengesGraphCategory([])
    setRunningChallengesGraphValue([])
    runningChallenge()
    setBadgesAwardedGraphCategory([])
    setBadgesAwardedGraphValue([])
    badgesAwarded()

  }, []);
  useEffect(() => {
    getCustomer()
  }, [limit, pageNumber])
  useEffect(() => {
    if (callChartFuncation) {

      setCustomerGraphCategory([])
      setCustomerGraphValue([])
      newCustomer()
      setSubscribeGraphCategory([])
      setSubscribeGraphValue([])
      setFreeCustomerGraphCategory([])
      setFreeCustomerGraphValue([])
      newSubscribe()
      setRunningChallengesGraphCategory([])
      setRunningChallengesGraphValue([])
      runningChallenge()
      setBadgesAwardedGraphCategory([])
      setBadgesAwardedGraphValue([])
      badgesAwarded()
      setCallChartFuncation(false)
    }

  }, [chartYear])
  return (
    <>
      {isLoading ? <div className="main_loader">
        <span className="loader-11"></span>
      </div> : null}
      {/* <div className="wrap_us">
        <div className="navbar-container">
          <Navbar activeLink={activeLink} setActiveLink={setActiveLink} />
        </div> */}
      <div className={`main_slide ${toggleClass ? 'sidebar_open' : ''}`}>

        <Header />
        <Navbar activeLink={activeLink} setActiveLink={setActiveLink} />
        <div className="wrap_us conten_set">
          <h4 className="flex_static">Statistics of {chartYear}

            <span>
              <label htmlFor="yearPicker">Select a Year: </label>
              <select id="yearPicker" onChange={handleYearChange} value={chartYear} className="form-control">
                <option value="">-- Select Year --</option>
                {years.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </span>
          </h4>

          <div className="chart_des">
            <div className="row ">
              <div className="col-md-6 chart_d">
                <ReactFusioncharts
                  type="stackedcolumn3d"
                  width="100%"
                  height="70%"
                  dataFormat="JSON"
                  dataSource={dataSource}
                />
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-6 chart_d">
                    <ReactFusioncharts
                      type="stackedcolumn3d"
                      width="100%"
                      height="20%"
                      dataFormat="JSON"
                      dataSource={dataSource1}
                    />
                  </div>
                  <div className="col-md-6 chart_d">
                    <ReactFusioncharts
                      type="stackedcolumn3d"
                      width="100%"
                      height="20%"
                      dataFormat="JSON"
                      dataSource={dataSource2}

                    />
                  </div>
                  <div className="col-md-6 chart_d">
                    <ReactFusioncharts
                      type="stackedcolumn3d"
                      width="100%"
                      height="20%"
                      dataFormat="JSON"
                      dataSource={dataSource3}

                    />
                  </div>
                </div>


              </div>
            </div>
          </div>
          <div className="hr"><hr></hr></div>
          <h4>Metrics Settings</h4>
          <div className="row">
            <div className="col-md-3">
              <div className="card_me box_1">
                <div className="card_body">

                  <h6>Levels</h6>
                  {level.length > 0 &&
                    level.map((item, index) => {
                      return (<>
                        <h4>{item.name}
                          <span className="btn_us1">
                            {/* <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }}
                              overlay={(
                                <Tooltip id="button-tooltip">
                                  {item.isActive == 1 ? 'Active' : 'In-active'}
                                </Tooltip>
                              )}
                            >
                              <span className="check_b">
                                <input type="checkbox" checked={item.isActive == 1} onClick={(e) => {
                                  changeLevelStatus(e.target.checked, item._id)
                                }} />
                                <span></span>
                              </span>
                            </OverlayTrigger> */}
                            <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }}
                              overlay={(
                                <Tooltip id="button-tooltip">
                                  Edit
                                </Tooltip>
                              )}
                            >
                              <button className="btn btn_clr" onClick={(e) => {
                                e.stopPropagation()
                                getLevelDetails(item._id)
                                setLevelEdit(true)
                                getActiveFrequency()
                                getActiveDistance()
                              }}
                              ><img src={Edit} alt="" /></button>
                            </OverlayTrigger>
                            {/* <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }}
                              overlay={(
                                <Tooltip id="button-tooltip">
                                  Delete
                                </Tooltip>
                              )}
                            >
                              <button className="btn btn_clr" onClick={() => levelAlert(item._id, item.name)}><img src={Delete} alt="" /></button>
                            </OverlayTrigger> */}
                          </span>
                        </h4>
                        <div className="row">
                          {
                            item.levelExerciseTypes.map((item1, index1) => {
                              return (
                                <>
                                  <div className="col-md-6">
                                    <div className="mb-3">
                                      <label for="">{item1.exerciseTypes.name}</label>
                                      <input type="text" className="form-control"
                                        value={item1.exerciseTypes.name == 'Cardio' ? `${item1.minExerciseCount}-${item1.maxExerciseCount} ${item1.exerciseUnits.name}` : `${item1.minExerciseCount}-${item1.maxExerciseCount} reps/${item1.exerciseDurations.name}`} />
                                    </div>
                                  </div>
                                </>)
                            })
                          }
                        </div>
                      </>);
                    })}
                  {/* <div className="btn_us2">
                    <button className="btn btn_theme btn-lg btn-block" onClick={(e) => {
                      e.preventDefault();
                      getActiveFrequency()
                      getActiveDistance()
                      setLevelIsOpen(true);
                    }}>Add Level</button>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-md-9">
              <div className="row">
                <div className="col-md-3">
                  <div className="card_me box_1">
                    <div className="card_body">
                      <h6>Strength Exercises</h6>
                      {strengthExercise.length > 0 &&
                        strengthExercise.map((item, index) => {
                          return (
                            <h4>{item.name.charAt(0).toUpperCase() + item.name.slice(1)} <span className="btn_us1">
                              <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }}
                                overlay={(
                                  <Tooltip id="button-tooltip">
                                    {item.isActive == 1 ? 'Active' : 'In-active'}
                                  </Tooltip>
                                )}
                              >
                                <span className="check_b">
                                  <input type="checkbox" checked={item.isActive == 1} onClick={(e) => {
                                    changeExercisesStatus(e.target.checked, item._id)
                                  }} />
                                  <span></span>
                                </span>
                              </OverlayTrigger>
                              <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }}
                                overlay={(
                                  <Tooltip id="button-tooltip">
                                    Edit
                                  </Tooltip>
                                )}
                              >
                                <button className="btn btn_clr" onClick={(e) => {
                                  e.stopPropagation()
                                  getExercisesDetails(item._id)
                                  setButtonName("Strength Exercise");
                                  setExercisesEdit(true)
                                }}><img src={Edit} alt="" /></button>
                              </OverlayTrigger>
                              <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }}
                                overlay={(
                                  <Tooltip id="button-tooltip">
                                    Delete
                                  </Tooltip>
                                )}
                              >
                                <button className="btn btn_clr" onClick={(e) => {
                                  e.stopPropagation()
                                  exercisesAlert(item._id, item.name)

                                }}><img src={Delete} alt="" /></button>
                              </OverlayTrigger>
                            </span></h4>
                          )

                        })
                      }
                      <div className="btn_us2">
                        <button className="btn btn_theme btn-lg btn-block" onClick={(e) => {
                          e.preventDefault();
                          setExercisesIsOpen(true);
                          setButtonName("Strength Exercise");
                        }} >Add Strength</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="card_me box_1 box_2">
                    <div className="">
                      <div className="row">
                        <div className="col-md-4">
                          <h6>Cardio Exercises</h6>

                          {cardioExercise.length > 0 &&
                            cardioExercise.map((item, index) => {
                              return (
                                <h4>{item.name.charAt(0).toUpperCase() + item.name.slice(1)} <span className="btn_us1">
                                  <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }}
                                    overlay={(
                                      <Tooltip id="button-tooltip">
                                        {item.isActive == 1 ? 'Active' : 'In-active'}
                                      </Tooltip>
                                    )}
                                  >
                                    <span className="check_b">
                                      <input type="checkbox" checked={item.isActive == 1} onClick={(e) => {
                                        changeExercisesStatus(e.target.checked, item._id)
                                      }} />
                                      <span></span>
                                    </span>
                                  </OverlayTrigger>
                                  <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }}
                                    overlay={(
                                      <Tooltip id="button-tooltip">
                                        Edit
                                      </Tooltip>
                                    )}
                                  >
                                    <button className="btn btn_clr" onClick={(e) => {
                                      e.stopPropagation()
                                      getExercisesDetails(item._id)
                                      setButtonName("Cardio Exercise");
                                      setExercisesEdit(true)
                                    }}><img src={Edit} alt="" /></button>
                                  </OverlayTrigger>
                                  <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }}
                                    overlay={(
                                      <Tooltip id="button-tooltip">
                                        Delete
                                      </Tooltip>
                                    )}
                                  >
                                    <button className="btn btn_clr" onClick={(e) => {
                                      e.stopPropagation()
                                      exercisesAlert(item._id)
                                    }}><img src={Delete} alt="" /></button>
                                  </OverlayTrigger>
                                </span></h4>
                              )
                            })
                          }
                          <div className="btn_us2">
                            <button className="btn btn_theme btn-lg btn-block" onClick={(e) => {
                              e.preventDefault();
                              setExercisesIsOpen(true);
                              setButtonName("Cardio Exercise");
                            }}>Add Cardio</button>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <h6>Distance</h6>
                          {distance.length > 0 &&
                            distance.map((item, index) => {
                              return (
                                <h4>{item.name.charAt(0).toUpperCase() + item.name.slice(1)} <span className="btn_us1">
                                  <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }}
                                    overlay={(
                                      <Tooltip id="button-tooltip">
                                        {item.isActive == 1 ? 'Active' : 'In-active'}
                                      </Tooltip>
                                    )}
                                  >
                                    <span className="check_b">
                                      <input type="checkbox" checked={item.isActive == 1} onClick={(e) => {
                                        changeDistanceStatus(e.target.checked, item._id)
                                      }} />
                                      <span></span>
                                    </span>
                                  </OverlayTrigger>
                                  <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }}
                                    overlay={(
                                      <Tooltip id="button-tooltip">
                                        Edit
                                      </Tooltip>
                                    )}
                                  >
                                    <button className="btn btn_clr" onClick={(e) => {
                                      e.stopPropagation()
                                      getDistanceDetails(item._id)
                                      setDistanceEdit(true)
                                    }}><img src={Edit} alt="" /></button>
                                  </OverlayTrigger>
                                  <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }}
                                    overlay={(
                                      <Tooltip id="button-tooltip">
                                        Delete
                                      </Tooltip>
                                    )}
                                  >
                                    <button className="btn btn_clr" onClick={(e) => {
                                      e.stopPropagation()
                                      distanceAlert(item._id, item.name)
                                    }}><img src={Delete} alt="" /></button>
                                  </OverlayTrigger>
                                </span></h4>

                              )

                            })
                          }
                          <div className="btn_us2">
                            <button className="btn btn_theme btn-lg btn-block" onClick={(e) => {
                              e.preventDefault();
                              setDistanceIsOpen(true);
                              setButtonName("Distance");

                            }}> Add Distance</button>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <h6>Frequency</h6>

                          {frequency.length > 0 &&
                            frequency.map((item, index) => {
                              return (
                                <h4>{item.name.charAt(0).toUpperCase() + item.name.slice(1)} <span className="btn_us1">
                                  <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }}
                                    overlay={(
                                      <Tooltip id="button-tooltip">
                                        {item.isActive == 1 ? 'Active' : 'In-active'}
                                      </Tooltip>
                                    )}
                                  >
                                    <span className="check_b" >
                                      <input type="checkbox" checked={item.isActive == 1} onClick={(e) => {
                                        changeFrequencyStatus(e.target.checked, item._id)
                                      }} data-tip="Meeting" />
                                      <span></span>
                                    </span>
                                  </OverlayTrigger>
                                  <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }}
                                    overlay={(
                                      <Tooltip id="button-tooltip">
                                        Edit
                                      </Tooltip>
                                    )}
                                  >
                                    <button className="btn btn_clr" onClick={(e) => {
                                      e.stopPropagation()
                                      getFrequencyDetails(item._id)
                                      setFrequencyEdit(true)
                                    }} data-tip="Meeting"><img src={Edit} alt="" /></button>
                                  </OverlayTrigger>
                                  <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }}
                                    overlay={(
                                      <Tooltip id="button-tooltip">
                                        Delete
                                      </Tooltip>
                                    )}
                                  >
                                    <button className="btn btn_clr" onClick={(e) => {
                                      e.stopPropagation()
                                      frequencyAlert(item._id, item.name)
                                    }}><img src={Delete} alt="" /></button>
                                  </OverlayTrigger>
                                </span></h4>
                              )
                            })
                          }
                          <div className="btn_us2">
                            <button className="btn btn_theme btn-lg btn-block" onClick={(e) => {
                              e.preventDefault();
                              setFrequencyIsOpen(true);
                              setFrequencyData({})
                            }}>Add Frequency</button>
                          </div>


                          {/* <h4>Week <span className="btn_us1">
                            <span className="check_b">
                              <input type="checkbox" />
                              <span></span>
                            </span>
                            <button className="btn btn_clr"><img src={Edit} alt="" /></button>
                            <button className="btn btn_clr"><img src={Delete} alt="" /></button>
                          </span></h4>
                          */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="hr">
            <hr></hr>
          </div>

          <h4>Customer Support</h4>
          <div className="card_me table_des1">
            <div className="top_aseh">
              <h4>List of Users</h4>
              <div className="searh_m2">
                Search keyword : <input type="text" placeholder="Keyword..." className="form-control" onChange={e => handleChange(e.target.value)} />
              </div>
            </div>
            <div className="table-responsive">

              <table className="table">
                <thead>
                  <tr>
                    <th >S. No.</th>
                    <th className={className == 'name' ? `orderd table-${updown}` : 'orderd table-down'} onClick={(e) => {
                      if (updown == 'down') {
                        setUpdown('up')

                      } else {
                        setUpdown('down')
                      }
                      sorting('name', 'string')
                      setClassName('name')

                    }}>Name</th>
                    <th className={className == 'email' ? `orderd table-${updown}` : 'orderd table-down'} onClick={(e) => {
                      if (updown == 'down') {
                        setUpdown('up')

                      } else {
                        setUpdown('down')
                      }
                      sorting('email', 'string')
                      setClassName('email')

                    }}>Email</th>

                    <th className={className == 'activePlan' ? `orderd table-${updown}` : 'orderd table-down'} onClick={(e) => {
                      if (updown == 'down') {
                        setUpdown('up')

                      } else {
                        setUpdown('down')
                      }
                      sorting('activePlan', 'string')
                      setClassName('activePlan')

                    }}>Active plan</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {customer.length > 0 &&
                    customer.map((item, index) => {
                      return (<>
                        <tr>
                          <td>{index + 1}</td>
                          <td>{item.name}</td>
                          <td>{item.email}</td>
                          <td>{item.activePlan}</td>
                          <td>
                            <span className="btn_us1">
                              <OverlayTrigger placement="top"
                                overlay={(
                                  <Tooltip id="button-tooltip" >
                                    {item.isActive == 1 ? 'Active' : 'In-active'}
                                  </Tooltip>
                                )}>
                                <span className="check_b">
                                  <input type="checkbox" checked={item.isActive == 1} onClick={(e) => {
                                    changeCustomerStatus(e.target.checked, item._id)
                                  }} />
                                  <span></span>
                                </span>
                              </OverlayTrigger>
                              <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }}
                                overlay={(
                                  <Tooltip id="button-tooltip">
                                    Edit
                                  </Tooltip>
                                )}>
                                <button className="btn btn_clr" onClick={(e) => {
                                  e.stopPropagation()
                                  setCustomersID(item._id)
                                  navigate('/customers')
                                }}  ><img src={Edit} alt="" /></button>
                              </OverlayTrigger>
                              <OverlayTrigger placement="top" style={{ "padding": "5px" }}
                                overlay={(

                                  <Tooltip id="button-tooltip">
                                    Delete
                                  </Tooltip>
                                )}>
                                <button className="btn btn_clr" onClick={() => customerAlert(item._id, item.name)} ><img src={Delete} alt="" /></button>
                              </OverlayTrigger>
                            </span>
                          </td>
                        </tr>
                      </>)
                    })
                  }
                </tbody>
              </table>


            </div>
            {customer.length > 0 && <>
              <div className="footer_tab">
                <div className="left_t1">
                  <div className="icon_droup">
                    <select className="form-control" onChange={(e) => {
                      e.stopPropagation()
                      setLimit(e.target.value);
                      setPageNumber(1)
                      // getCustomer()
                    }}>
                      <option value="5" selected={limit == 5 ? true : false}>5</option>
                      <option value="10" selected={limit == 10 ? true : false}>10</option>
                      {/* <option value="25" selected={limit == 25 ? true : false}>25</option> */}
                      {/* <option value="50" selected={limit == 50 ? true : false}>50</option> */}
                      <option value="100" selected={limit == 100 ? true : false}>100</option>
                      <option value="200" selected={limit == 200 ? true : false}>200</option>
                    </select>
                  </div>
                  <p>Lines per page</p>
                </div>
                <div className="right_t1">
                  <div className="icon_droup">
                    <img src={LeftArrow} onClick={(e) => {
                      e.stopPropagation()
                      if (pageNumber > 1) {
                        setPageNumber(pageNumber - 1)
                      }
                    }} />
                    <input type="text" value={pageNumber} className="form-control" />
                    <img src={RightArrow} onClick={(e) => {
                      e.stopPropagation()
                      if (countPage > pageNumber) {
                        setPageNumber(pageNumber + 1)
                      }
                    }} />
                  </div>
                  <p>of {countPage}</p>
                </div>
              </div>

            </>}
          </div>
        </div>
      </div>

      <Frequency isOpen={isFrequencyOpen} setIsOpen={setFrequencyIsOpen} frequencyData={frequencyData} getFrequency={getFrequency}
        frequencyEdit={frequencyEdit} setFrequencyEdit={setFrequencyEdit} />
      <DistanceModel isOpen={isDistanceOpen} setIsOpen={setDistanceIsOpen} distanceData={distanceData} getDistance={getDistance}
        distanceEdit={distanceEdit} setDistanceEdit={setDistanceEdit} />
      <ExercisesModel isOpen={isExercisesOpen} setIsOpen={setExercisesIsOpen} buttonName={buttonName} exercisesEdit={exercisesEdit} exercisesData={exercisesData}
        exercisesId={exercisesId} cardioExerciseId={cardioExerciseId} strengthExerciseId={strengthExerciseId} getExercises={getExercises}
        setExercisesEdit={setExercisesEdit} />
      <LevelModel isOpen={levelIsOpen} setIsOpen={setLevelIsOpen} activeDistance={activeDistance} activeFrequency={activeFrequency}
        cardioExerciseId={cardioExerciseId} strengthExerciseId={strengthExerciseId} levelEdit={levelEdit} levelData={levelData} getLevel={getLevel}
        setLevelEdit={setLevelEdit} />
      <ToastContainer />
      {/* </div>
     
      <LevelModel isOpen={levelIsOpen} setIsOpen={setLevelIsOpen} /> */}
    </>

  );
};

export default Dashboard;
