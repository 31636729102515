import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import { AppProvider } from "../context/AppContext";
import { AuthProvider } from "../context/AuthContext";
import { FormProvider } from "../context/FormContext";
import Dashboard from "../components/dashboard/Dashboard";
import NotFoundComponent from "../components/not-found/NotFoundComponent";
import Login from "../components/login/Login";
import CustomerManagement from "../components/CustomerManagement/CustomerManagement";
import Report from "../components/report/Report";
import Profile from "../components/profile/Profile";
import Magiclink from "../components/Magiclink/Magiclink";
import Plan from "../components/plan/Plan";
import ForgotPassword from "../components/login/ForgotPassword";
import Notification from "../components/notification/Notification";

const index = () => {
  return (
    <Router>
      <AuthProvider>
        <AppProvider>
          <FormProvider>
            <Routes>
              <Route
                path="/"
                element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <Login title="Login" />
                  </Suspense>
                }
              />
              <Route path="/dashboard"
                element={
                  <PrivateRoute>
                    <Suspense fallback={<div>Loading...</div>}>
                      <Dashboard title="Dashboard" />
                    </Suspense>
                  </PrivateRoute>
                }
              />
              <Route path="/customers" element={
                <PrivateRoute>
                  <Suspense fallback={<div>Loading...</div>}>
                    <CustomerManagement title="Customer Management" />
                  </Suspense>
                </PrivateRoute>
              } />
              <Route path="/report" element={
                <PrivateRoute>
                  <Suspense fallback={<div>Loading...</div>}>
                    <Report title="Report" />
                  </Suspense>
                </PrivateRoute>
              } />
              <Route path="/plan" element={
                <PrivateRoute>
                  <Suspense fallback={<div>Loading...</div>}>
                    <Plan title="Plan" />
                  </Suspense>
                </PrivateRoute>
              } />
              <Route path="/profile" element={
                <PrivateRoute>
                  <Suspense fallback={<div>Loading...</div>}>
                    <Profile title="Profile" />
                  </Suspense>
                </PrivateRoute>
              } />
              <Route path="/notification" element={
                <PrivateRoute>
                  <Suspense fallback={<div>Loading...</div>}>
                    <Notification title="Notifications" />
                  </Suspense>
                </PrivateRoute>
              } />
              <Route path="/magic-link/:token/:id" element={
                <Suspense fallback={<div>Loading...</div>}>
                  <Magiclink title="Magic Link" />
                </Suspense>
              } />
              <Route path="/forgot-password/:token" element={

                <Suspense fallback={<div>Loading...</div>}>
                  <ForgotPassword title="Forgot Password" />
                </Suspense>

              } />
              <Route path="*" element={<NotFoundComponent />} />
            </Routes>
          </FormProvider>
        </AppProvider>
      </AuthProvider>
    </Router>
  );
};

export default index;


