import React, { useContext, useState, useRef, useEffect } from 'react';
import Modal from 'react-modal';
import './modal.css';
import axios from 'axios';
import { toast } from 'react-toastify';
import useAxios from '../../hooks/useAxios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightFromBracket, faXmark } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';


const customStyles = {
    content: {
        top: '30%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: '500px',
        maxHeight: 'auto',
        borderRadius: '11px',
        minWidth: '500px',
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
};

Modal.setAppElement('#root');
const LevelModel = ({
    isOpen,
    setIsOpen,
    activeDistance,
    activeFrequency,
    cardioExerciseId,
    strengthExerciseId,
    levelEdit,
    levelData,
    getLevel

}) => {

    const api = useAxios();
    const baseURL = process.env.REACT_APP_BACKEND_BASE_URL;
    const [levelName, setLevelName] = useState('')
    const [minReps, setMinReps] = useState(1)
    const [maxReps, setMaxReps] = useState(minReps + 1)
    const [minDis, setMinDis] = useState(0)
    const [maxDis, setMaxDis] = useState(minDis + 1)
    const [frequency, setFrequency] = useState('')
    const [distance, setDistance] = useState('')
    const [levelErrorMessage, setLevelErrorMessage] = useState('')
    const [maxRepsErrorMessage, setMaxRepsErrorMessage] = useState('')
    const [minRepsErrorMessage, setMinRepsErrorMessage] = useState('')
    const [maxDisErrorMessage, setMaxDisErrorMessage] = useState('')
    const [minDisErrorMessage, setMinDisErrorMessage] = useState('')
    const [distanceErrorMessage, setDistanceErrorMessage] = useState('')
    const [frequencyErrorMessage, setFrequencyErrorMessage] = useState('')


    const [buttonStatus, setButtonStatus] = useState(true)

    const createLevel = async () => {
        const headers = {
            'Content-Type': 'application/json',
            'authorization': `Bearer ${localStorage.getItem("authorization")}`
        }

        console.log('frequency', frequency);
        if (levelErrorMessage == '' && minRepsErrorMessage == '' && maxRepsErrorMessage == '' && minDisErrorMessage == ''
            && maxDisErrorMessage == '' && distance && frequency && levelName) {
            var setdata = {
                name: levelName,
                types: [{
                    exerciseTypeId: cardioExerciseId,
                    exerciseDurationId: null,
                    exerciseUnitId: distance,
                    minExerciseCount: minDis,
                    maxExerciseCount: maxDis
                }, {
                    exerciseTypeId: strengthExerciseId,
                    exerciseDurationId: frequency,
                    exerciseUnitId: null,
                    minExerciseCount: minReps,
                    maxExerciseCount: maxReps,
                }]
            }
            if (levelEdit) {

                let res = await axios.put(`${baseURL}/update/levels/${levelData[0]._id}`, setdata, { headers: headers });
                if (res.status == 200) {
                    toast.success(res.data.ErrorMessage)
                    // toast.success('Level updated successfully.')

                    setIsOpen(false)
                    getLevel()
                } else {

                    toast.error(res.data.ErrorMessage)
                }
            } else {
                let res = await axios.post(`${baseURL}/create/levels/`, setdata, { headers: headers });
                if (res.status == 201) {
                    toast.success(res.data.ErrorMessage)
                    // toast.success('Level created successfully.')

                    setIsOpen(false)
                    getLevel()
                } if (levelName) {

                    setLevelErrorMessage('The level field is required.')

                } else {
                    toast.error(res.data.ErrorMessage)
                }
            }
        } else {
            if (distance == '') {
                setDistanceErrorMessage('The distance field is required.')
            } else if (frequency == '') {
                setFrequencyErrorMessage('The frequency field is required.')
            } else {
                toast.error('Please enter valid details')
            }
        }


    }

    useEffect(() => {

        if (levelEdit) {

            setLevelName(levelData[0].name)
            if (levelData[0].levelExerciseTypes.length > 0) {
                levelData[0].levelExerciseTypes.map((item, index) => {
                    console.log('item', item);
                    console.log('strengthExerciseId', strengthExerciseId);


                    if (item.exerciseTypes._id == cardioExerciseId) {
                        setDistance(item.exerciseUnits._id)
                        setMinDis(item.minExerciseCount)
                        setMaxDis(item.maxExerciseCount)
                    } else if (item.exerciseTypes._id == strengthExerciseId) {
                        setFrequency(item.exerciseDurations._id)
                        setMinReps(item.minExerciseCount)
                        setMaxReps(item.maxExerciseCount)
                    }
                })
            }

        }

    }, [levelData])

    return (
        <div className=''>
            <Modal
                isOpen={isOpen}
                onRequestClose={() => setIsOpen(false)}
                style={customStyles}
                onAfterClose={() => {
                    setLevelName('')
                    setMinReps(1)
                    setMaxReps(minReps + 1)
                    setMinDis(0)
                    setMaxDis(minDis + 1)
                    setFrequency('')
                    setDistance('')
                    setLevelErrorMessage('')
                    setFrequencyErrorMessage('')
                    setDistanceErrorMessage('')
                    setMaxDisErrorMessage('')
                    setMinDisErrorMessage('')
                    setMaxRepsErrorMessage('')
                    setMinRepsErrorMessage('')
                }}
            >

                <div className=' modal-container'>
                    <div className='text-center'>
                        <h3 className='text-2xl font-bold heade_close'> Add Level
                            <button className='btn btn_theme' onClick={(e) => {
                                setIsOpen(false)
                                setLevelName('')
                                setMinReps(1)
                                setMaxReps(minReps + 1)
                                setMinDis(0)
                                setMaxDis(minDis + 1)
                                setFrequency('')
                                setDistance('')
                                setLevelErrorMessage('')
                                setFrequencyErrorMessage('')
                                setDistanceErrorMessage('')
                                setMaxDisErrorMessage('')
                                setMinDisErrorMessage('')
                                setMaxRepsErrorMessage('')
                                setMinRepsErrorMessage('')
                            }}>
                                <FontAwesomeIcon icon={faXmark} />
                                {/* <FontAwesomeIcon icon={faArrowRightFromBracket} /> */}
                            </button> </h3>
                    </div>
                    <form>
                        <div className="mb-3">
                            <label>Level Name</label>
                            <input placeholder="Level name" onChange={(e) => {
                                e.stopPropagation()
                                if (e.target.value == undefined || e.target.value == '') {
                                    setLevelErrorMessage('The level field is required.')
                                    setButtonStatus(true)
                                } else if (e.target.value.length < 3) {
                                    setLevelErrorMessage('Please enter Minimum 3 characters.')
                                    setButtonStatus(true)
                                } else if (e.target.value.length > 50) {
                                    setLevelErrorMessage('Please enter maximum 50 characters.')
                                    setButtonStatus(true)
                                } else {
                                    setButtonStatus(false)
                                    setLevelErrorMessage('')
                                }
                                setLevelName(e.target.value.toUpperCase())
                            }} type="text" value={levelName} className='form-control' />
                            <p style={{ color: "red" }}>{levelErrorMessage}</p>
                        </div>

                        <div className="mb-3">
                            <h4> Strength</h4>
                            {/* <input placeholder="Enter  strength" type="text" className="form-control" /> */}
                        </div>
                        <div className="mb-3">
                            <label> Frequency</label>
                            <select className='form-control' onChange={(e) => {
                                e.stopPropagation()
                                setFrequency(e.target.value);
                                setFrequencyErrorMessage('')
                            }} >
                                <option> Select Frequency</option>
                                {activeFrequency.length > 0 &&
                                    activeFrequency.map((item, index) => {
                                        return (<>
                                            <option value={item._id} selected={frequency == item._id ? true : false}>  {item.name}</option>
                                        </>)
                                    })
                                }
                            </select>
                            <p style={{ color: "red" }}>{frequencyErrorMessage}</p>
                        </div>
                        <div className="row">
                            <div className='col-md-6'>
                                <div className="mb-3">
                                    <label> Min reps(repeatation)</label>
                                    <input min="0" onChange={(e) => {
                                        e.stopPropagation()
                                        if (parseInt(e.target.value) >= parseInt(maxReps)) {
                                            setMinRepsErrorMessage('Minimum repeatation should less then maximum repeatation.')
                                            setMaxRepsErrorMessage('')
                                        } else {
                                            setMinRepsErrorMessage('')
                                            setMaxRepsErrorMessage('')
                                        }
                                        setMinReps(e.target.value)
                                    }} type="number" className="form-control" value={minReps} />
                                </div>
                                <p style={{ color: "red" }}>{minRepsErrorMessage}</p>
                            </div>
                            <div className='col-md-6'>
                                <div className="mb-3">
                                    <label> Max reps(repeatation)</label>
                                    <input type="number" onChange={(e) => {
                                        e.stopPropagation()
                                        if (parseInt(e.target.value) <= parseInt(minReps)) {
                                            setMaxRepsErrorMessage('Maximum repeatation should greater then minimum repeatation.')
                                            setMinRepsErrorMessage('')
                                        } else {
                                            setMaxRepsErrorMessage('')
                                            setMinRepsErrorMessage('')
                                        }
                                        setMaxReps(e.target.value)
                                    }} min="1" className="form-control" value={maxReps} />
                                </div>
                                <p style={{ color: "red" }}>{maxRepsErrorMessage}</p>
                            </div>
                        </div>
                        <div className="mb-3">
                            <h4> Cardio</h4>
                            {/* <input placeholder="Enter  strength" type="text" className="form-control" /> */}
                        </div>
                        <div className="mb-3">
                            <label> Distance</label>
                            <select className='form-control' onChange={(e) => {
                                e.stopPropagation()
                                setDistance(e.target.value);
                                setDistanceErrorMessage('')
                            }}>
                                <option > Select Distance</option>
                                {activeDistance.length > 0 &&
                                    activeDistance.map((item, index) => {
                                        return (<>
                                            <option value={item._id} selected={distance == item._id ? true : false}> {item.name}</option>
                                        </>)
                                    })
                                }
                            </select>
                            <p style={{ color: "red" }}>{distanceErrorMessage}</p>
                        </div>
                        <div className="row">
                            <div className='col-md-6'>
                                <div className="mb-3">
                                    <label> Min </label>
                                    <input type="number" onChange={(e) => {
                                        e.stopPropagation()
                                        if (parseInt(e.target.value) >= parseInt(maxDis)) {
                                            setMinDisErrorMessage('Minimum distance should less then maximum distance.')
                                            setMaxDisErrorMessage('')
                                        } else {
                                            setMinDisErrorMessage('')
                                            setMaxDisErrorMessage('')
                                        }
                                        setMinDis(e.target.value)
                                    }} min="0" value={minDis} className="form-control" />
                                </div>
                                <p style={{ color: "red" }}>{minDisErrorMessage}</p>
                            </div>
                            <div className='col-md-6'>
                                <div className="mb-3">
                                    <label> Max</label>
                                    <input type="number" onChange={(e) => {
                                        e.stopPropagation()
                                        if (parseInt(e.target.value) <= parseInt(minDis)) {
                                            setMaxDisErrorMessage('Maximum distance should greater then minimum distance.')
                                            setMinDisErrorMessage('')
                                        } else {
                                            setMaxDisErrorMessage('')
                                            setMinDisErrorMessage('')
                                        }
                                        setMaxDis(e.target.value)
                                    }} min="1" value={maxDis} className="form-control" />
                                </div>
                                <p style={{ color: "red" }}>{maxDisErrorMessage}</p>
                            </div>
                        </div>
                        <button
                            className="btn btn_theme btn-block"
                            onClick={(e) => {
                                e.preventDefault();
                                createLevel()
                            }}
                        > Submit </button>
                    </form>
                </div>
            </Modal>
        </div>
    );
};

export default LevelModel;
