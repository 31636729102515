import React, { useContext, useState, useRef, useEffect } from 'react';
import Modal from 'react-modal';
import './modal.css';
import axios from 'axios';
import { toast } from 'react-toastify';
import useAxios from '../../hooks/useAxios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';


const customStyles = {
    content: {
        top: '30%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: '500px',
        maxHeight: 'auto',
        borderRadius: '11px',
        minWidth: '500px',
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
};

Modal.setAppElement('#root');
const Frequency = ({
    isOpen,
    setIsOpen,
    frequencyData,
    getFrequency,
    frequencyEdit, setFrequencyEdit
}) => {
    const headers = {
        'Content-Type': 'application/json',
        'authorization': `Bearer ${localStorage.getItem("authorization")}`
    }
    const navigate = useNavigate();
    const api = useAxios();
    const baseURL = process.env.REACT_APP_BACKEND_BASE_URL;
    const [frequencyName, setFrequencyName] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [buttonStatus, setButtonStatus] = useState(true)
    const createFrequency = async () => {
        var setdata = {
            name: frequencyName
        }
        if (frequencyEdit) {
            try {
                let res = await axios.put(`${baseURL}/update/exerciseDuration/${frequencyData._id}`, setdata, { headers: headers });
                if (res.status == 200) {
                    // toast.success('Frequency updated successfully.')
                    toast.success(res.data.ErrorMessage)

                    setFrequencyEdit(false)
                    setIsOpen(false)
                    getFrequency()

                } else {
                    toast.error(res.data.ErrorMessage)
                }
            } catch (error) {
                if (error.response.status == 401) {
                    localStorage.removeItem('userData')
                    localStorage.removeItem('authorization')
                    navigate("/");
                } else if (error.response.status == 403) {
                    toast.error(error.response.data.ErrorMessage)

                } else {
                    toast.error('Server error occurring, Please try after some time.')

                }
            }

        } else {
            try {
                let res = await axios.post(`${baseURL}/create/exerciseDuration/`, setdata, { headers: headers });
                if (res.status == 201) {
                    // toast.success('Frequency created successfully')
                    toast.success(res.data.ErrorMessage)

                    setIsOpen(false)
                    getFrequency()
                } else {
                    toast.error(res.data.ErrorMessage)
                }
            } catch (error) {
                if (error.response.status == 401) {
                    localStorage.removeItem('userData')
                    localStorage.removeItem('authorization')
                    navigate("/");
                } else if (error.response.status == 403) {
                    toast.error(error.response.data.ErrorMessage)

                } else {
                    toast.error('Server error occurring, Please try after some time.')

                }
            }

        }

    }
    const checkVlidation = (e) => {
        if (e == undefined || e == '') {
            setErrorMessage('The frequency field is required.')
            setButtonStatus(true)
        } else if (e.length < 2) {
            setErrorMessage('Please enter Minimum 2 characters.')
            setButtonStatus(true)
        } else if (e.length > 25) {
            setErrorMessage('Please enter maximum 25 characters.')
            setButtonStatus(true)
        } else {
            setButtonStatus(false)
            setErrorMessage('')
        }
    }
    useEffect(() => {
        if (frequencyEdit) {
            setButtonStatus(false)
            setFrequencyName(frequencyData.name)
        }

    }, [frequencyData])

    return (
        <div className=''>
            <Modal
                isOpen={isOpen}
                onRequestClose={() => setIsOpen(false)}
                style={customStyles}
                onAfterClose={() => {
                    setErrorMessage('')
                    setFrequencyName('')
                }}
            >
                <div className=' modal-container'>
                    <div className='text-center'>
                        <h3 className='text-2xl font-bold heade_close'> Frequency<button className='btn btn_theme' onClick={(e) => {
                            setIsOpen(false)
                            setErrorMessage('')
                            setFrequencyName('')
                        }}>
                            <FontAwesomeIcon icon={faXmark} />
                            {/* <FontAwesomeIcon icon={faArrowRightFromBracket} /> */}
                        </button> </h3>
                    </div>
                    <form >
                        <div className="mb-3">
                            <label className="">Frequency </label>
                            <input placeholder="Enter frequency" onChange={(e) => {
                                e.stopPropagation()
                                checkVlidation(e.target.value)
                                setFrequencyName(e.target.value)
                            }} type="text" value={frequencyName} className='form-control' />
                            <p style={{ color: "red" }}>{errorMessage}</p>
                        </div>
                        <button
                            className='btn btn_theme btn-block'
                            onClick={(e) => {
                                e.preventDefault();
                                createFrequency()
                            }}
                            disabled={buttonStatus} > Submit  </button>
                    </form>
                </div>
            </Modal>
        </div>
    );
};

export default Frequency;
