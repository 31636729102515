import { createContext, useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import { validateEmail, validatePassword, validatePhone } from "../utils/utils";
import useAxios from "../hooks/useAxios";
import { useTranslation } from "react-i18next";
const FormContext = createContext();

export default FormContext;

export const FormProvider = ({ children }) => {
  const api = useAxios();
  const { t } = useTranslation();
  const [isOpenModel, setIsOpenModel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalData, setModalData] = useState({
    title: "",
    bodyText: "",
    apiResStatus: false,
  });
  const [userFormErrors, setUserFormErrors] = useState({
    name: "",
    designation: "",
    email: "",
  });
  const [editFormData, setEditFormData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  const saveOrEditUser = async (userObj, updatePermissionList) => {
    let { name, designation, email } = !isEditing ? userObj : editFormData;

    for (const key in userFormErrors) {
      if (userFormErrors.hasOwnProperty(key)) {
        userFormErrors[key] = "";
      }
    }

    if (name.trim().length < 1) {
      setUserFormErrors({
        ...userFormErrors,
        name: t("form.User name is required."),
      });
    } else if (designation.trim().length < 1) {
      setUserFormErrors({
        ...userFormErrors,
        designation: t("form.Designation is required."),
      });
    } else if (email.trim().length < 1) {
      setUserFormErrors({
        ...userFormErrors,
        email: t("form.Email is required."),
      });
    } else if (!validateEmail(email)) {
      setUserFormErrors({
        ...userFormErrors,
        email: t("loginPage.Please enter a valid email!"),
      });
    } else {
      let postData = {};
      if (!isEditing) {
        postData = {
          name: name,
          email: email,
          designation: designation,
        };
      } else {
        postData = {
          name: name,
          email: email,
          designation: designation,
        };
      }
      try {
        setLoading(true);
        let res = "";
        if (!isEditing) {
          res = await api.post(`/user/byadmin/`, postData, {
            headers: {
              "Content-Type": "application/json",
            },
          });
        } else {
          res = await api.post(`/user/byadmin/edit/`, postData, {
            headers: {
              "Content-Type": "application/json",
            },
          });
        }
        setLoading(false);
        if (res.data) {
          updatePermissionList(res.data["User_permission"][0]);

          setIsOpenModel(true);
          setModalData({
            ...modalData,
            title: !isEditing ? t("form.User Added") : t("form.User Updated"),
            bodyText: `${t("form.You have successfully")} ${!isEditing ? t("form.added") : t("form.updated")
              } ${t("form.user")}.`,
            apiResStatus: true,
          });
        }
        setUserFormErrors({
          name: "",
          designation: "",
          email: "",
        });
      } catch (error) {
        setLoading(false);
        let data;
        if (error && error.response) data = error.response.data;

        let showError = 0;
        if (data && data.message) {
          let check01 = data.message.includes("Duplicate entry");
          let check02 = data.message.includes("for key 'email'");
          if (check01 && check02) {
            showError = 1;
          }
        }

        if (showError) {
          toast.error(t("form.Email id already exist."));
        } else {
          setIsOpenModel(true);
          let action = !isEditing ? t("form.added") : t("form.updated");
          setModalData({
            ...modalData,
            title: t("common.Something went wrong"),
            bodyText: `${t("user.User was not")} ${action}. ${t(
              "form.Please try again"
            )}`,
            apiResStatus: false,
          });
        }
      }
    }
  };


  const contextData = {
    modalData,
    setModalData,
    setIsOpenModel,
    isOpenModel,
    editFormData,
    setEditFormData,
    isEditing,
    setIsEditing,
    saveOrEditUser,
    userFormErrors,
    setUserFormErrors,
  };

  return (
    <FormContext.Provider value={contextData}>{children}</FormContext.Provider>
  );
};
