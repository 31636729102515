import React, { useContext, useState, useRef, useEffect } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import './modal.css';
import useAxios from '../../hooks/useAxios';

const customStyles = {
    content: {
        top: '30%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: '500px',
        maxHeight: 'auto',
        borderRadius: '11px',
        minWidth: '500px',
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
};

Modal.setAppElement('#root');
const ViewGalletyModel = ({
    isOpen,
    setIsOpen,


}) => {
    const api = useAxios();


    return (
        <div className=''>
            <AwesomeSlider>
                <div>1</div>
                <div>2</div>
                <div>3</div>
                <div>4</div>
            </AwesomeSlider>
        </div>
    );
};

export default ViewGalletyModel;
