import { Navigate } from 'react-router-dom';
import { useContext } from 'react';
import AuthContext from '../context/AuthContext';

const PrivateRoute = ({ children }) => {
  let { user } = useContext(AuthContext);
  let userdata = localStorage.getItem('userData')


  // return user ? children : <Navigate to='/' />;
  return userdata ? children : <Navigate to='/' />;

  // return children;
};

export default PrivateRoute;
