import React, { useContext, useState, useRef, useEffect } from 'react';
import Modal from 'react-modal';
import './modal.css';
import { toast } from 'react-toastify';
import useAxios from '../../hooks/useAxios';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const customStyles = {
    content: {
        top: '30%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: '500px',
        maxHeight: 'auto',
        borderRadius: '11px',
        minWidth: '500px',
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
};

Modal.setAppElement('#root');
const ExercisesModel = ({
    isOpen,
    setIsOpen,
    buttonName, exercisesEdit, exercisesData,
    cardioExerciseId, strengthExerciseId, getExercises, setExercisesEdit

}) => {
    const headers = {
        'Content-Type': 'application/json',
        'authorization': `Bearer ${localStorage.getItem("authorization")}`
    }
    const navigate = useNavigate();
    const api = useAxios();
    const baseURL = process.env.REACT_APP_BACKEND_BASE_URL;
    const [exercisesName, setExercisesName] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [buttonStatus, setButtonStatus] = useState(true)
    const createExercises = async () => {
        if (buttonName == 'Strength Exercise') {
            var id = strengthExerciseId
        } else if (buttonName == 'Cardio Exercise') {
            var id = cardioExerciseId
        }
        var setdata = {
            name: exercisesName,
            exerciseTypeId: id
        }
        if (exercisesEdit) {
            try {
                let res = await axios.put(`${baseURL}/update/exercises/${exercisesData._id}`, setdata, { headers: headers });
                if (res.status == 200) {
                    // toast.success('Exercise updated successfully.')
                    toast.success(res.data.ErrorMessage)

                    setIsOpen(false)
                    getExercises()
                    setExercisesEdit(false)
                } else {
                    toast.error(res.data.ErrorMessage)
                }
            } catch (error) {
                if (error.response.status == 401) {
                    localStorage.removeItem('userData')
                    localStorage.removeItem('authorization')
                    navigate("/");
                } else if (error.response.status == 403) {
                    toast.error(error.response.data.ErrorMessage)

                } else {
                    toast.error('Server error occurring, Please try after some time.')

                }
            }

        } else {
            try {
                let res = await axios.post(`${baseURL}/create/exercises/`, setdata, { headers: headers });
                if (res.status == 201) {
                    toast.success('Exercise created successfully.')
                    setIsOpen(false)
                    getExercises()
                } else {
                    toast.error(res.data.ErrorMessage)
                }
            } catch (error) {
                if (error.response.status == 401) {
                    localStorage.removeItem('userData')
                    localStorage.removeItem('authorization')
                    navigate("/");
                } else if (error.response.status == 403) {
                    toast.error(error.response.data.ErrorMessage)

                } else {
                    toast.error('Server error occurring, Please try after some time.')

                }
            }

        }
    }
    const checkVlidation = (e) => {
        if (e == undefined || e == '') {
            setErrorMessage('The exercise field is required.')
            setButtonStatus(true)
        } else if (e.length < 3) {
            setErrorMessage('Please enter Minimum 3 characters.')
            setButtonStatus(true)
        } else if (e.length > 50) {
            setErrorMessage('Please enter maximum 50 characters.')
            setButtonStatus(true)
        } else {
            setButtonStatus(false)
            setErrorMessage('')
        }
    }
    useEffect(() => {
        if (exercisesEdit) {
            setButtonStatus(false)
            setExercisesName(exercisesData.name)
        }

    }, [exercisesData])

    return (
        <div className=''>
            <Modal
                isOpen={isOpen}
                onRequestClose={() => setIsOpen(false)}
                style={customStyles}

                onAfterClose={() => {
                    setExercisesName('')
                    setErrorMessage('')
                }}
            >

                <div className=' modal-container'>
                    <div className='text-center'>
                        <h3 className='text-2xl font-bold heade_close'> {buttonName} <button className='btn btn_theme' onClick={(e) => {
                            setIsOpen(false)
                            setExercisesName('')
                            setErrorMessage('')
                        }}>
                            <FontAwesomeIcon icon={faXmark} />
                            {/* <FontAwesomeIcon icon={faArrowRightFromBracket} /> */}
                        </button> </h3>

                    </div>
                    <form >
                        <div className="mb-3">
                            <label className="">Exercise Name</label>
                            <input placeholder="Enter exercise name" onChange={(e) => {
                                e.stopPropagation()
                                checkVlidation(e.target.value)
                                setExercisesName(e.target.value)
                            }} type="text" value={exercisesName} className='form-control' />
                            <p style={{ color: "red" }}>{errorMessage}</p>
                        </div>
                        <button
                            className='btn btn_theme btn-block' disabled={buttonStatus}
                            onClick={(e) => {
                                e.preventDefault();
                                createExercises()
                            }}
                        > Submit  </button>
                    </form>

                </div>
            </Modal>
        </div>
    );
};

export default ExercisesModel;
