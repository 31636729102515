
import Routes from './routes';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
function App() {
  return (
    
     <div className='' >  {/* sidebar_open */}
      <Routes />
    </div>
  );
}

export default App;
