import React, { useContext, useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
// import Dropdown from 'react-bootstrap/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGauge, faUserGroup, faMedal, faFileLines, faClipboardList, faGear, faBell, faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import AppContext from '../../context/AppContext';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
const Navbar = ({ activeLink, setActiveLink }) => {
  const navigate = useNavigate();
  const { toggleClass, setToggleClass } = useContext(AppContext);
  function logout() {
    localStorage.removeItem('userData')
    navigate("/");
  }
  return (

    <div className="slidebar">
      <ul className="ul_set">
        <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }}
          overlay={(
            <Tooltip id="button-tooltip">
              DASHBOARD
            </Tooltip>
          )}>
          <li> <NavLink to="/dashboard"  ><FontAwesomeIcon icon={faGauge} /> <span>DASHBOARD</span> </NavLink></li>
        </OverlayTrigger>
        <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }}
          overlay={(
            <Tooltip id="button-tooltip">
              CUSTOMERS
            </Tooltip>
          )}>
          {/* <li><NavLink to="/exercise-type"><i className="icon"></i>  Exercise Type </NavLink></li>
         <li><NavLink to="/exercise"><i className="icon"></i>  Exercise </NavLink></li>
        <li><NavLink to="/level"><i className="icon"></i>  levels</NavLink></li>
        <li><a href="#"><i className="icon"></i>  Distance</a></li>
        <li><a href="#"><i className="icon"></i>  Frequency</a></li> */}
          <li ><NavLink to="/customers" ><FontAwesomeIcon icon={faUserGroup} />  <span>CUSTOMERS</span></NavLink></li>
        </OverlayTrigger>
        <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }}
          overlay={(
            <Tooltip id="button-tooltip">
              PLAN
            </Tooltip>
          )}>
          {/* <li><a href="#"><FontAwesomeIcon icon={faMedal} />  <span>Badges</span></a></li> */}
          <li ><NavLink to="/plan" ><FontAwesomeIcon icon={faClipboardList} />  <span>PLAN</span></NavLink></li>
        </OverlayTrigger>
        <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }}
          overlay={(
            <Tooltip id="button-tooltip">
              REPORTS
            </Tooltip>
          )}>
          <li ><NavLink to="/report" ><FontAwesomeIcon icon={faFileLines} />  <span>REPORTS</span></NavLink></li>
        </OverlayTrigger>
        <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }}
          overlay={(
            <Tooltip id="button-tooltip">
              NOTIFICATIONS
            </Tooltip>
          )}>
          <li ><NavLink to="/notification" ><FontAwesomeIcon icon={faBell} />  <span>NOTIFICATIONS</span></NavLink></li>
        </OverlayTrigger>
        {/* <li><a href="#"><FontAwesomeIcon icon={faGear} />  <span>SETTING</span></a></li> */}

        {/* <li className='log_ouuu'  ><NavLink to="/logout" ><FontAwesomeIcon icon={faArrowRightFromBracket} />  <span>LOGOUT</span></NavLink></li> */}
        <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }}
          overlay={(
            <Tooltip id="button-tooltip">
              LOGOUT
            </Tooltip>
          )}>
          <li className='log_ouuu'  ><a onClick={(e) => {
            e.stopPropagation()
            logout()
          }} ><FontAwesomeIcon icon={faArrowRightFromBracket} />  <span>LOGOUT</span></a></li>
        </OverlayTrigger>

      </ul>
    </div>


  );
};

export default Navbar;
