import React, { useState, useContext, useEffect } from "react";
import { Navigate, useNavigate, useHistory, useParams, NavLink } from "react-router-dom";
import { useFormik } from 'formik';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthContext from "../../context/AuthContext";
import { useTranslation } from "react-i18next";
import { appName } from "../../constants/constants";
import { autoCloseTimer, delay } from '../../constants/constants';
import { validateEmail } from '../../utils/utils'
import logo from '../../assets/img/logo.png'
import checkd from '../../assets/img/check.png'
import { Icon } from 'react-icons-kit';
import { eyeOff } from 'react-icons-kit/feather/eyeOff';
import { eye } from 'react-icons-kit/feather/eye'
import { Player } from 'video-react';
import ReactWebMediaPlayer from 'react-web-media-player';
const ForgotPassword = ({ title }) => {
    const { token } = useParams();

    const { ForgotPasswordUser, user } = useContext(AuthContext);
    const [formErrors, setFormErrors] = useState({ email: '', password: '' });
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isValid, setIsValid] = useState(false);
    const [passwordError, setPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');

    const [isLoading, setIsLoading] = useState(true);
    const [checkStatus, setCheckStatus] = useState('');
    const [type, setType] = useState('password');
    const [passwordType, setPasswordType] = useState('password');
    const [passwordIcon, setPasswordIcon] = useState(eyeOff);


    const [icon, setIcon] = useState(eyeOff);
    const handleToggle = () => {
        if (type === 'password') {
            setIcon(eye);
            setType('text')
        } else {
            setIcon(eyeOff)
            setType('password')
        }
    }
    const handleTogglePassword = () => {
        if (passwordType === 'password') {
            setPasswordIcon(eye);
            setPasswordType('text')
        } else {
            setPasswordIcon(eyeOff)
            setPasswordType('password')
        }
    }
    const { t } = useTranslation();
    const navigate = useNavigate();

    const onFormSubmit = async (e) => {

        if (isValid != true) {
            toast.error('Please enter email address.')
        } else {

            var setdata = {
                email: email,
            }
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}/admin/generatePasswordLink/`, setdata,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    }
                );
                if (response.status == 200) {
                    setCheckStatus('LinkSendSuccessfully')
                } else {
                    toast.error(response.data.ErrorMessage)
                }
            } catch (error) {
                if (error.response.status == 403 || error.response.status == 500) {
                    toast.error(error.response.data.ErrorMessage)

                } else {
                    toast.error('Server error occurring, Please try after some time.')

                }
            }
        }
    };
    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };
    const updatePassword = async (e) => {
        if (confirmPasswordError == '' && passwordError == '') {
            var setdata = {
                token: token,
                password: password,
                confirmPassword: confirmPassword,
            }
            try {
                setIsLoading(true)
                const response = await axios.post(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}/admin/updatePassword/`, setdata,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    }
                );
                if (response.status == 200) {
                    setIsLoading(false)

                    setCheckStatus('PasswordUpdated')

                } else {
                    setIsLoading(false)

                    toast.error('Server error occurring, Please try after some time.')
                }
            } catch (error) {
                setIsLoading(false)

                if (error.response.status == 403) {
                    toast.error(error.response.data.ErrorMessage)

                } else {
                    toast.error('Server error occurring, Please try after some time.')

                }
            }
        } else {
            setIsLoading(false)

            toast.error('All fields are required.')
        }
    }
    useEffect(() => {
        console.log('token', token);
        if (token) {
            if (token == 'email') {
                setCheckStatus('email')
            } else {
                setCheckStatus('password')
            }
        }
        document.title = `${appName} | ${title}`;
        let userdata = localStorage.getItem('userData')
        // console.log('userdata', userdata);
        if (userdata) {
            navigate('/dashboard')
        } else {
            setIsLoading(false);
        }
        window.onload = () => {
            setIsLoading(false); // Once the window is fully loaded, set loading to false
        };
        return () => {
            window.onload = null; // Clean up the event listener to prevent memory leaks
        };
    }, []);

    useEffect(() => {

    }, [passwordError])


    return (<>
        {isLoading ? <div className="main_loader">
            <span className="loader-11"></span>
        </div> : null}
        <div className="login">
            <div className="login_box1 card_me">
                {checkStatus == 'email' &&
                    <div className="login_box2 conten_set">
                        <div className="text-center">
                            <img src={logo} alt="" className="img_r logo_login" />
                        </div>

                        <h2>
                            <span>Forgot password to continue NRTH.</span>
                        </h2>
                        <form>
                            <div className="mb-3">
                                <input type="email" value={email}
                                    onChange={(e) => {
                                        const newEmail = e.target.value;
                                        setEmail(newEmail);
                                        setIsValid(validateEmail(newEmail));
                                    }}
                                    className="form-control quantity" placeholder="Email" required />
                                {email && !isValid && <div style={{ color: 'red' }}>Please enter email address</div>}
                            </div>
                            {/* <Player>
                            <source src="https://stage.trulinco.com/nrth-fitness-api/uploads/2024-01-16T12-40-42.286Z-challengeVideo.mp4" />
                        </Player>
                        <ReactWebMediaPlayer
                            title="My own video player"
                            video="https://stage.trulinco.com/nrth-fitness-api/uploads/2024-01-16T12-40-42.286Z-challengeVideo.mp4"
                            thumbnail="https://any-link.com/video-thumbnail.jpg"
                        /> */}
                            <div className="form-group1">
                                <button type='button' onClick={(e) => onFormSubmit(e)} className="btn btn_theme btn-lg btn-block text_u" >Submit</button>
                            </div>
                        </form>
                    </div>
                }
                {checkStatus == 'password' &&
                    <div className="login_box2 conten_set">
                        <div className="text-center">
                            <img src={logo} alt="" className="img_r logo_login" />
                        </div>

                        <h2>
                            <span>Create a new password to continue NRTH.</span>
                        </h2>
                        <form>
                            <div className="mb-3">
                                <div className="icon_eyeyy">

                                    <input type={passwordType} minLength={8} value={password}
                                        onChange={(e) => {
                                            if (e.target.value == '') {
                                                setPasswordError('Please enter password')
                                            } else if (e.target.value.length < 8) {
                                                setPasswordError('Please enter minimum 8 characters')
                                            } else if (confirmPassword != e.target.value) {
                                                setPasswordError('')
                                                setConfirmPasswordError('Password and confirm password must be equal')
                                            } else {
                                                setPasswordError('')
                                                setConfirmPasswordError('')
                                            }
                                            setPassword(e.target.value)
                                        }}
                                        className="form-control" maxLength={16} placeholder=" Password" required />
                                    <div style={{ color: 'red' }}>{passwordError}</div>
                                    <span class="eye_fmin" onClick={handleTogglePassword}>
                                        <Icon icon={passwordIcon} size={20} />
                                    </span>
                                </div>
                            </div>
                            <div className="mb-3">
                                <div className="icon_eyeyy">
                                    <input type={type} minLength={6} value={confirmPassword}
                                        onChange={(e) => {
                                            // console.log('e.target.value', e.target.value.length);
                                            if (e.target.value == password) {
                                                setConfirmPasswordError('')
                                            } else {
                                                setConfirmPasswordError('Password and confirm password must be equal')
                                            }
                                            setConfirmPassword(e.target.value)
                                        }}
                                        className="form-control" maxLength={16} placeholder="Confirm Password" required />
                                    <span class="eye_fmin" onClick={handleToggle}>
                                        <Icon icon={icon} size={20} />
                                    </span>
                                </div>
                                <div style={{ color: 'red' }}>{confirmPasswordError}</div>
                            </div>
                            <div className="form-group1">
                                <button type='button' onClick={(e) => updatePassword(e)} className="btn btn_theme btn-lg btn-block text_u" >Submit</button>
                            </div>
                        </form>
                    </div>
                }
                {checkStatus == 'LinkSendSuccessfully' &&
                    <div className="login_box2 conten_set">
                        <div className="text-center mb-4">
                            <img src={checkd} alt="" className="img_r" />
                        </div>
                        <h2 style={{ marginBottom: "25px" }}>
                            Success
                            <span style={{
                                marginTop: "8px"
                            }}>Password link sent on your register email.</span>
                        </h2>
                        <div className="text-center">
                            <NavLink to={`/`} className="btn btn_theme">Continue</NavLink>
                            {/* <a ></a> */}
                        </div>
                    </div>
                }
                {checkStatus == 'PasswordUpdated' &&
                    <div className="login_box2 conten_set">
                        <div className="text-center mb-4">
                            <img src={checkd} alt="" className="img_r" />
                        </div>
                        <h2 style={{ marginBottom: "25px" }}>
                            Success
                            <span style={{
                                marginTop: "8px"
                            }}>Password updated successfully.</span>
                        </h2>
                        <div className="text-center">
                            <NavLink to={`/`} className="btn btn_theme">Continue</NavLink>

                        </div>

                    </div>
                }
            </div>
        </div>
        <ToastContainer />

    </>);
};

export default ForgotPassword;
