import React, { useContext, useState, useRef, useEffect } from 'react';
import Modal from 'react-modal';
import './modal.css';
import axios from 'axios';
import { toast } from 'react-toastify';
import useAxios from '../../hooks/useAxios';
import { setDate } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';


const customStyles = {

    content: {
        top: '30%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: '500px',
        maxHeight: 'auto',
        borderRadius: '11px',
        minWidth: '500px',
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
};

Modal.setAppElement('#root');
const AddPlanModel = ({
    isOpen,
    setIsOpen,
    getPlan, isEdit, setIsEdit, planData

}) => {
    const headers = {
        'Content-Type': 'application/json',
        'authorization': `Bearer ${localStorage.getItem("authorization")}`
    }
    const navigate = useNavigate();

    const api = useAxios();
    const baseURL = process.env.REACT_APP_BACKEND_BASE_URL;
    const [errorMessage, setErrorMessage] = useState('')
    const [frequency, setFrequency] = useState('')
    const [activeFrequency, setActiveFrequency] = useState([])
    const [planName, setPlanName] = useState('')
    const [price, setPrice] = useState(1)
    const [description, setDescription] = useState('')
    const [numberOfFrequency, setNumberOfFrequency] = useState('')
    const [gateWayId, setGateWayId] = useState('')
    const [isFree, setIsFree] = useState(0)
    const [isDisplay, setIsDisplay] = useState(0)
    const [gateWayData, setGateWayData] = useState([])
    const [selectedValue, setSelectedValue] = useState()
    const [planNameErrorMessage, setPlanNameErrorMessage] = useState('')
    const [frequencyErrorMessage, setFrequencyErrorMessage] = useState('')
    const [numberOfFrequencyErrorMessage, setNumberOfFrequencyErrorMessage] = useState('')
    const [descriptionErrorMessage, setDescriptionErrorMessage] = useState('')


    const createPlan = async () => {
        if (planName && description && planNameErrorMessage == '' && frequencyErrorMessage == '' && numberOfFrequencyErrorMessage == '' && descriptionErrorMessage == '') {
            if (isEdit) {
                if (frequency == 'Month') {
                    var numberOfFrequencyData = 1
                } else {
                    var numberOfFrequencyData = numberOfFrequency

                }
                var setdata = {
                    name: planName,
                    price: price,
                    frequency: frequency,
                    noOfFrequency: numberOfFrequencyData,
                    description: description,
                    paymentGatewayId: gateWayId,
                    isFree: isFree,
                }
                try {
                    let res = await axios.put(`${baseURL}/update/packages/${planData._id}`, setdata, { headers: headers });
                    if (res.status == 200) {
                        toast.success(res.data.ErrorMessage)
                        // toast.success('Plan updated successfully.')

                        setIsEdit(false)
                        setIsOpen(false)
                        getPlan()
                    } else {
                        toast.error(res.data.ErrorMessage)
                    }
                } catch (error) {

                    if (error.response.status == 401) {
                        localStorage.removeItem('userData')
                        localStorage.removeItem('authorization')
                        navigate("/");
                    } else if (error.response.status == 403) {
                        toast.error(error.response.data.ErrorMessage)
                    } else {
                        toast.error('Server error occurring, Please try after some time.')
                    }

                }

            } else {
                if (frequency == 'Month') {
                    var numberOfFrequencyData = 1
                } else {
                    var numberOfFrequencyData = numberOfFrequency

                }
                var setdata = {
                    name: planName,
                    price: price,
                    frequency: frequency,
                    noOfFrequency: numberOfFrequencyData,
                    isFree: isFree,
                    isDisplay: isDisplay,
                    description: description,
                    paymentGatewayId: gateWayId,
                }
                try {
                    let res = await axios.post(`${baseURL}/create/packages`, setdata, { headers: headers });
                    if (res.status == 201) {
                        // toast.success('Plan created successfully.')
                        toast.success(res.status.ErrorMessage)

                        setIsOpen(false)
                        getPlan()
                    } else {
                        toast.error(res.data.ErrorMessage)
                    }
                } catch (error) {

                    if (error.response.status == 401) {
                        localStorage.removeItem('userData')
                        localStorage.removeItem('authorization')
                        navigate("/");
                    } else if (error.response.status == 403) {
                        toast.error(error.response.data.ErrorMessage)
                    } else {
                        toast.error('Server error occurring, Please try after some time.')
                    }
                    // 
                }

            }
        } else {
            if (frequencyErrorMessage == '' && frequency == '') {
                setFrequencyErrorMessage('The frequency field is required.')
            } else if (planName == '') {

                setPlanNameErrorMessage('The plan name field is required.')
            } else if (frequency == '') {
                setPlanName('The plan name field is required.')
            }
        }
    }
    const changeFreePlan = async (e) => {
        var status = e ? 1 : 0;
        setIsFree(status)
    }
    const changePlanStatus = async (e) => {
        var status = e ? 1 : 0;
        setIsDisplay(status)
    }
    const getActiveFrequency = async () => {
        // alert('fds')
        try {
            let res = await axios.get(`${baseURL}/exerciseDuration/getAllActiveModel/`, { headers: headers });
            if (res.status == 200) {
                setActiveFrequency(res.data.getAllActiveModelexerciseDuration)
            } else {
                toast.error(res.data.ErrorMessage)
            }
        } catch (error) {
            if (error.response.status == 401) {
                localStorage.removeItem('userData')
                localStorage.removeItem('authorization')
                navigate("/");
            } else if (error.response.status == 403) {
                toast.error(error.response.data.ErrorMessage)

            } else {
                toast.error('Server error occurring, Please try after some time.')

            }
        }

    }
    const getAllGateWay = async () => {
        try {
            let res = await axios.get(`${baseURL}/getall/paymentGateways/`, { headers: headers });
            if (res.status == 200) {
                setGateWayData(res.data.getAllpaymentGateways)
            } else {
                toast.error(res.data.ErrorMessage)
            }
        } catch (error) {
            if (error.response.status == 401) {
                localStorage.removeItem('userData')
                localStorage.removeItem('authorization')
                navigate("/");
            } else if (error.response.status == 403) {
                toast.error(error.response.data.ErrorMessage)

            } else {
                toast.error('Server error occurring, Please try after some time.')

            }
        }

    }
    const handleRadioChange = (event) => {
        setSelectedValue(event.target.value);
        setGateWayId(event.target.value)
    };

    useEffect(() => {
        getActiveFrequency()
        getAllGateWay()
        if (isEdit) {
            setFrequency(planData.frequency)
            setPlanName(planData.name)
            setPrice(planData.price)
            setNumberOfFrequency(planData.noOfFrequency)
            setDescription(planData.description)
            setIsFree(planData.isFree)
            setIsDisplay(planData.isDisplay)
            if (planData.packagepaymentgateways.length > 0) {
                setSelectedValue(planData.packagepaymentgateways[0].paymentGatewayId)
            }
            if (planData.packagepaymentgateways.length > 0) {
                setGateWayId(planData.packagepaymentgateways[0].paymentGatewayId)
            } else {

            }

        }
    }, [isEdit])
    return (
        <div className=''>
            <Modal
                isOpen={isOpen}
                onRequestClose={() => setIsOpen(false)}
                style={customStyles}
                onAfterClose={() => {
                    setErrorMessage(''); setDescription(''); setGateWayId(''); setPlanNameErrorMessage('');
                    setPlanName(''); setPrice(1); setFrequency(''); setNumberOfFrequency(0); setIsEdit(false);
                    setFrequencyErrorMessage('');
                }}
            >
                <div className=' modal-container'>
                    <div className='text-center'>
                        <h3 className='text-2xl font-bold heade_close'> Add Plan <button className='btn btn_theme' onClick={(e) => {
                            setIsOpen(false)
                            setErrorMessage(''); setDescription(''); setGateWayId(''); setPlanNameErrorMessage('');
                            setPlanName(''); setPrice(1); setFrequency(''); setNumberOfFrequency(0); setIsEdit(false);
                            setFrequencyErrorMessage('');
                        }}>
                            <FontAwesomeIcon icon={faXmark} />
                            {/* <FontAwesomeIcon icon={faArrowRightFromBracket} /> */}
                        </button> </h3>
                    </div>
                    <form>
                        <div className="mb-3">
                            <label>Plan Name</label>
                            <input placeholder="Enter plan name" onChange={(e) => {
                                e.stopPropagation()
                                if (e.target.value == undefined || e.target.value == '') {
                                    setPlanNameErrorMessage('The plan name field is required.')
                                } else if (e.target.value.length < 3) {
                                    setPlanNameErrorMessage('Please enter minimum 3 characters.')
                                } else if (e.target.value.length > 50) {
                                    setPlanNameErrorMessage('Please enter maximum 50 characters.')
                                } else {
                                    setPlanNameErrorMessage('')
                                }
                                setPlanName(e.target.value)
                            }} type="text" value={planName} className='form-control' required />
                            <p style={{ color: "red" }}>{planNameErrorMessage}</p>
                        </div>
                        <div className="mb-3">
                            <label>Price</label>
                            <input min="1" onChange={(e) => {
                                e.stopPropagation()
                                setPrice(e.target.value)
                            }} type="number" className="form-control" value={price} required />
                        </div>
                        {isEdit != true && <>
                            <div className="mb-3">
                                <label> Frequency</label>
                                <select className='form-control' onChange={(e) => {
                                    e.stopPropagation()
                                    setFrequency(e.target.value);
                                    setFrequencyErrorMessage('')
                                }} value={frequency} >
                                    <option> Select Frequency</option>
                                    <option value="Days"> Days</option>
                                    <option value="Month"> Months</option>
                                    {/* <option value="Years"> Years</option> */}

                                    {/* {activeFrequency.length > 0 &&
                                        activeFrequency.map((item, index) => {
                                            return (<>
                                                <option value={item.name} selected={frequency == item.name ? true : false}>  {item.name}</option>
                                            </>)
                                        })
                                    } */}
                                </select>
                                <p style={{ color: "red" }}>{frequencyErrorMessage}</p>
                            </div>
                        </>}
                        <div className="mb-3">
                            <label>Number of Frequency</label>
                            <input placeholder="Enter number of frequency" readOnly={frequency == 'Month'} onChange={(e) => {
                                e.stopPropagation()
                                setNumberOfFrequency(e.target.value)
                                if (e.target.value == undefined || e.target.value == '') {
                                    setNumberOfFrequencyErrorMessage('The Frequency field is required.')
                                } else {
                                    setNumberOfFrequencyErrorMessage('')
                                }
                            }} type="number" min="0" value={frequency == 'Month' ? 1 : numberOfFrequency} className='form-control' />
                            {/* disabled={isEdit} */}
                        </div>
                        <p style={{ color: "red" }}>{numberOfFrequencyErrorMessage}</p>


                        <div className="mb-3">
                            <label>Description</label>
                            <textarea value={description} onChange={(e) => {
                                e.stopPropagation()
                                setDescription(e.target.value)
                                setDescriptionErrorMessage('')
                                if (e.target.value == undefined || e.target.value == '') {
                                    setDescriptionErrorMessage('The description field is required.')
                                } else if (e.target.value.length < 3) {
                                    setDescriptionErrorMessage('Please enter minimum 3 characters.')
                                } else {
                                    setDescriptionErrorMessage('')
                                }
                            }} className='form-control' placeholder='Enter description' />
                            <p style={{ color: "red" }}>{descriptionErrorMessage}</p>

                        </div>

                        <div className="mb-3">
                            <label>Gate Way ID</label>
                            <br />
                            {gateWayData.length > 0 &&
                                gateWayData.map((item, index) => {
                                    return (<>
                                        <div className="form-check form-check-inline">
                                            <input type="radio"
                                                className='form-check-input'
                                                onChange={handleRadioChange}
                                                checked={selectedValue === item._id}
                                                value={item._id}
                                            />
                                            <label className='form-check-label'>{item.name}</label>
                                        </div>
                                    </>)
                                })
                            }

                        </div>

                        {isEdit != true && <>
                            <div className='mb-3'>


                                <div className='swdd2 form-check-inline'>
                                    <label className='form-check-label'>Display</label>
                                    <span className="btn_us1">
                                        <span className="check_b">
                                            <input disabled={isEdit} type="checkbox" onClick={(e) => {
                                                changePlanStatus(e.target.checked)
                                            }} checked={isDisplay == true} />
                                            <span></span>
                                        </span>
                                    </span>

                                </div>
                                <div className='swdd2 form-check-inline'>
                                    <label className='form-check-label'>Free</label>
                                    <span className="btn_us1">
                                        <span className="check_b">
                                            <input disabled={isEdit} type="checkbox" onClick={(e) => {
                                                changeFreePlan(e.target.checked)
                                            }} checked={isFree == true} />
                                            <span></span>
                                        </span>
                                    </span>

                                </div>
                            </div>

                        </>}
                        <button
                            className='btn btn_theme btn-block'
                            onClick={(e) => {
                                e.preventDefault();
                                createPlan()
                            }}
                        >Submit</button>
                    </form>
                </div>
            </Modal>
        </div>
    );
};

export default AddPlanModel;
