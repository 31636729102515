import React, { useEffect, useContext, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { appName } from "../../constants/constants";
import AuthContext from "../../context/AuthContext";
import AppContext from "../../context/AppContext";
import Navbar from "../navbar/Navbar";
import Header from "../commonPage/header/Header";
import LevelModel from "../modals/Level";
import FusionCharts from "fusioncharts";
import Charts from 'fusioncharts/fusioncharts.charts';
import ReactFusioncharts from "react-fusioncharts";
import Edit from '../../assets/img/edit.png'
import Delete from '../../assets/img/delete.png'
import LeftArrow from '../../assets/img/left_arrow.png'
import RightArrow from '../../assets/img/right_arrow.png'


Charts(FusionCharts);

const timeout = 1000 * 60 * 30;

const Profile = ({ title }) => {

  const { activeLink, setActiveLink, activePrevLink, toggleClass, setHeaderHeadding } = useContext(AppContext);
  const { user, logoutUser } = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState(false);
  const [buttonName, setButtonName] = useState("");
  const [levelIsOpen, setLevelIsOpen] = useState(false);
  const [adminName, setAdminName] = useState('')
  const [adminEmail, setAdminEmail] = useState('')

  useEffect(() => {
    document.title = `${appName} | ${title}`;
    setHeaderHeadding('PROFILE')
    var data = JSON.parse(localStorage.getItem('userData'))
    setAdminName(data.name)
    setAdminEmail(data.email)
  }, []);

  return (
    <>

      <div className={`main_slide ${toggleClass ? "sidebar_open" : ""}`}>
        <Header />
        <Navbar activeLink={activeLink} setActiveLink={setActiveLink} />
        <div className="wrap_us conten_set">
          <h4>Profile</h4>
          <div className="row">
            <div className="col-md-8">
              <div className="card_me">
                <div className="card_body">
                  <div className="user_pro">
                    <div className="profliedd">
                      <span>{adminName.charAt(0).toUpperCase()}</span>
                    </div>
                    <h4>{adminName}
                      <span>{adminEmail}</span>
                    </h4>
                  </div>
                  <div className="mb-3">
                    <label> Name</label>
                    <input placeholder="" type="name" className="form-control" value={adminName} />
                  </div>
                  <div className="mb-3">
                    <label> Email</label>
                    <input placeholder="" type="name" className="form-control" value={adminEmail} disabled />
                  </div>
                  {/* <div className="mb-3">
                    <label> Password</label>
                    <input placeholder="" type="name" className="form-control" value="******" />
                  </div>
                  <div className="mb-3">
                    <label>Confirm Password</label>
                    <input placeholder="" type="name" className="form-control" value="******" />
                  </div> */}
                  <div className="">
                    {/* <button className="btn btn_theme btn-lg btn-block">Save Changes</button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>

  );
};

export default Profile;
