import React, { useState, useContext, useEffect } from "react";
import { Navigate, useNavigate, useHistory, NavLink } from "react-router-dom";
import { useFormik } from 'formik';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthContext from "../../context/AuthContext";
import { useTranslation } from "react-i18next";
import { appName } from "../../constants/constants";
import { autoCloseTimer, delay } from '../../constants/constants';
import { validateEmail } from '../../utils/utils'
import logo from '../../assets/img/logo.png'

const Login = ({ title }) => {
  const { loginUser, user } = useContext(AuthContext);
  const [formErrors, setFormErrors] = useState({ email: '', password: '' });
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const onFormSubmit = async (e) => {

    if (isValid != true) {
      toast.error('Please enter email.')
    } else if (password.trim().length < 1) {
      toast.error('Please enter password.')
      // setFormErrors({ ...formErrors, password: t('loginPage.Please enter a password') });
    } else {

      var setdata = {
        email: email,
        password: password
      }
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/admin/login/`, setdata,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        if (response.status == 200) {
          localStorage.setItem('userData', JSON.stringify(response.data.getAllActiveModelusers));
          localStorage.setItem('authorization', response.data.authtoken);

          navigate("/dashboard");
        } else {
          toast.error('Please enter valid email address');
        }
      } catch (error) {
        if (error.response.status == 403 || error.response.status == 500) {
          toast.error(error.response.data.ErrorMessage)

        } else {
          toast.error('Server error occurring, Please try after some time.')

        }
      }
    }
  };
  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  useEffect(() => {

    document.title = `${appName} | ${title}`;
    let userdata = localStorage.getItem('userData')
    if (userdata) {
      navigate('/dashboard')
    } else {
      setIsLoading(false);
    }
    window.onload = () => {

      setIsLoading(false); // Once the window is fully loaded, set loading to false
    };
    return () => {
      window.onload = null; // Clean up the event listener to prevent memory leaks
    };
  }, []);

  useEffect(() => {

  }, [passwordError])


  return (<>
    {isLoading ? <div className="main_loader">
      <span className="loader-11"></span>
    </div> : null}
    <div className="login">
      <div className="login_box1 card_me">
        <div className="login_box2 conten_set">
          <div className="text-center">
            <img src={logo} alt="" className="img_r logo_login" />
          </div>
          <h2>
            <span>Sign in to continue to NRTH.</span>
          </h2>
          <form >
            <div className="mb-3">
              <input type="email" value={email}
                onChange={(e) => {
                  const newEmail = e.target.value;
                  setEmail(newEmail);
                  setIsValid(validateEmail(newEmail));
                }}
                className="form-control quantity" placeholder=" Email" required />
              {email && !isValid && <div style={{ color: 'red' }}>Please enter email address</div>}
            </div>

            <div className="mb-3">
              <input type="password" minLength={6} value={password}
                onChange={(e) => {
                  if (e.target.value == '') {
                    setPasswordError('Please enter password.')
                  } else if (e.target.value.length < 6) {
                    setPasswordError('Please enter minimum 6 characters.')
                  } else {
                    setPasswordError('')
                  }
                  setPassword(e.target.value)
                }}
                className="form-control" maxLength={16} placeholder=" Password" required />
              <div style={{ color: 'red' }}>{passwordError}</div>
              <div className="forget">
                <NavLink to={`/forgot-password/email`}  >Forgot Password</NavLink>
              </div>
            </div>

            <div className="form-group1">
              <button type='button' onClick={(e) => onFormSubmit(e)} className="btn btn_theme btn-lg btn-block text_u" >Login</button>
            </div>
          </form>

        </div>

        {/* <a href="" ></a> */}
      </div>
    </div>
    <ToastContainer />

  </>);
};

export default Login;
