import React, { useEffect, useContext, useState } from "react";
import AppContext from "../../context/AppContext";
import Header from "../commonPage/header/Header";
import Navbar from "../navbar/Navbar";
import Edit from "../../assets/img/edit.png";
import Delete from "../../assets/img/delete.png";
import LeftArrow from "../../assets/img/left_arrow.png";
import RightArrow from "../../assets/img/right_arrow.png";
import EyeIcon from "../../assets/img/eye.png";
import axios from "axios";
import { parseISO, format } from "date-fns";
import Moment from 'moment';
import ViewAwardedModel from "../modals/ViewAwarded";
import { ToastContainer, toast } from "react-toastify";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
const timeout = 1000 * 60 * 30;
const baseURL = process.env.REACT_APP_BACKEND_BASE_URL;
const Report = ({ title }) => {
    const {
        activeLink,
        setActiveLink,
        activePrevLink,
        toggleClass,
        setHeaderHeadding,
    } = useContext(AppContext);
    const headers = {
        'Content-Type': 'application/json',
        'authorization': `Bearer ${localStorage.getItem("authorization")}`
    }
    const navigate = useNavigate();
    const { allow } = DateRangePicker;
    const [activaTab, setActivaTab] = useState("runningChallenge");
    const [searchText, setSearchText] = useState("");
    const [limit, setLimit] = useState(10)
    const [newlyRegistered, setNewlyRegistered] = useState([])
    const [newlyRegisteredList, setNewlyRegisteredList] = useState([])
    const [countPage, setCountPage] = useState('')
    const [pageNumber, setPageNumber] = useState(1)
    const [BadgesAwarded, setBadgesAwarded] = useState([])
    const [badgesAwardedList, setBadgesAwardedList] = useState([])
    const [runningChallenge, setRunningChallenge] = useState([])
    const [runningChallengeList, setRunningChallengeList] = useState([])
    const [fromDate, setFromDate] = useState(Moment().subtract(30, 'days').format('YYYY-MM-DD'))
    const [todate, setToDate] = useState(Moment().format('YYYY-MM-DD'))
    const [isOpneViewAwarded, setIsOpneViewAwarded] = useState(false)
    const [badgesAwardedDetail, setBadgesAwardedDetail] = useState([])
    const [startDate, setStartDate] = useState(Moment().subtract(30, 'days').format('MM/DD/YYYY'))
    const [endDate, setEndDate] = useState(Moment().format('MM/DD/YYYY'))
    const [className, setClassName] = useState('')
    const [updown, setUpdown] = useState('')
    const [isLoading, setIsLoading] = useState(true);


    const getNewlyRegistered = async () => {

        if (pageNumber > 1) {
            var pageNumbers = pageNumber - 1
            var skip = pageNumbers * limit;
        } else {
            var skip = 0
        }
        var setdata = {
            fromDate: fromDate,
            toDate: todate,
            "skip": skip,
            "limit": +limit
        }
        try {
            var res = await axios.post(`${baseURL}/customer/newCustomerList`, setdata, { headers: headers });
            if (res.status == 200) {
                setIsLoading(false)
                setNewlyRegistered(res.data.getAllfilteredwithPagination.customersList)
                setNewlyRegisteredList(res.data.getAllfilteredwithPagination.customersList)
                var pageCount = Math.round(res.data.getAllfilteredwithPagination.totalCount / limit);
                setCountPage(pageCount)

            }
        } catch (error) {

            if (error.response.status == 401) {
                localStorage.removeItem('userData')
                localStorage.removeItem('authorization')
                navigate("/");
            } else if (error.response.status == 403) {
                toast.error(error.response.data.ErrorMessage)

            } else {
                toast.error('Server error occurring, Please try after some time.')

            }
        }

    }
    const getBadgesAwarded = async () => {

        if (pageNumber > 1) {
            var pageNumbers = pageNumber - 1
            var skip = pageNumbers * limit;
        } else {
            var skip = 0
        }
        var setdata = {
            fromDate: fromDate,
            toDate: todate,
            "skip": skip,
            "limit": +limit
        }
        try {
            var res = await axios.post(`${baseURL}/userBadges/getAllUserBadgesDetailswithPagination`, setdata, { headers: headers });
            if (res.status == 200) {
                setIsLoading(false)
                var badgesAwardedData = []
                console.log('res.data.getAllUserBadgesDetailswithPagination.userBadgesList', res.data.getAllUserBadgesDetailswithPagination.userBadgesList);

                res.data.getAllUserBadgesDetailswithPagination.userBadgesList.forEach((element, index) => {
                    badgesAwardedData.push({
                        name: element?.customerId?.name,
                        activePlan: element?.customerId?.activePlan,
                        levelName: element?.challengeId?.levelId?.name,
                        createdAt: element?.createdAt,
                        item: element,
                        _id: element?._id
                    })
                });
                console.log();
                setBadgesAwarded(badgesAwardedData)
                setBadgesAwardedList(res.data.getAllUserBadgesDetailswithPagination.userBadgesList)
                var pageCount = Math.round(res.data.getAllUserBadgesDetailswithPagination.totalCount / limit);
                setCountPage(pageCount)
            }
        } catch (error) {

            if (error.response.status == 401) {
                localStorage.removeItem('userData')
                localStorage.removeItem('authorization')
                navigate("/");
            } else if (error.response.status == 403) {
                toast.error(error.response.data.ErrorMessage)

            } else {
                toast.error('Server error occurring, Please try after some time.')

            }
        }

    }
    const getRunningChallenge = async () => {
        if (pageNumber > 1) {
            var pageNumbers = pageNumber - 1
            var skip = pageNumbers * limit;
        } else {
            var skip = 0
        }
        var columnName = ''
        if (className) {
            columnName = className.split("-").pop();
        }
        var setdata = {
            fromDate: fromDate,
            toDate: todate,
            "skip": skip,
            "limit": +limit,
            sort: updown,
            columnName: columnName
        }
        try {
            var res = await axios.post(`${baseURL}/challenges/getAllProcessOrRunningChallengePagination`, setdata, { headers: headers });
            if (res.status == 200) {

                setIsLoading(false)
                var runningChallengeData = []
                console.log('res.data.getAllChallengeByCustomerId.chalange', res.data.getAllChallengeByCustomerId.chalange);

                res.data.getAllChallengeByCustomerId.chalange.forEach((element, index) => {

                    let repType = element.repType
                    if (element.repType == 'Week') {
                        repType = 'Days'
                    }

                    runningChallengeData.push({
                        userName: element?.customers?.name,
                        activePlan: element?.customers?.activePlan,
                        exerciseDurations: element?.exerciseDurations?.name != undefined ? element?.exerciseDurations?.name : repType,
                        exercisesName: element?.exercises?.name,
                        exerciseTypes: element?.exercises?.exerciseTypes?.name,
                        levelName: element?.levels?.name,
                        dayCount: element?.dayCount,
                        exerciseCount: element?.exerciseCount,
                        createdAt: element?.createdAt,
                        _id: element?._id
                    })
                });
                console.log('runningChallenge', runningChallengeData);
                setRunningChallenge(runningChallengeData)
                setRunningChallengeList(res.data.getAllChallengeByCustomerId.chalange)
                var pageCount = Math.round(res.data.getAllChallengeByCustomerId.totalCount / limit);
                setCountPage(pageCount)

            }
        } catch (error) {

            if (error.response.status == 401) {
                localStorage.removeItem('userData')
                localStorage.removeItem('authorization')
                navigate("/");
            } else if (error.response.status == 403) {
                toast.error(error.response.data.ErrorMessage)

            } else {
                toast.error('Server error occurring, Please try after some time.')

            }
        }

    }
    const handleChange = value => {
        setSearchText(value);
        filterData(value);

    };
    const filterData = (value) => {
        const lowercasedValue = value.toLowerCase().trim();

        if (lowercasedValue === "") setNewlyRegistered(newlyRegisteredList);
        else {
            const filteredData = newlyRegistered.filter((item) =>
                item.name.toLowerCase().includes(lowercasedValue.toLowerCase())
            );
            setNewlyRegistered(filteredData);
        }
    }
    const exportData = async (type) => {
        var setdata = {
            fromDate: fromDate,
            toDate: todate
        }
        if (type == 'runningChallenge') {
            try {
                var res = await axios.post(`${baseURL}/challenges/getAllProcessOrRunningChallengeExcel`, setdata, { headers: headers });
                if (res.status == 200) {
                    // toast.success('Data export successfully.')
                    toast.success(res.data.ErrorMessage)

                    // window.open(`https://stage.trulinco.com/nrth-fitness-api/${res.data.getAllProcessOrRunningChallengeExcel}`, '_blank');
                    window.open(res.data.getAllProcessOrRunningChallengeExcel, '_blank');

                }
            } catch (error) {

                if (error.response.status == 401) {
                    localStorage.removeItem('userData')
                    localStorage.removeItem('authorization')
                    navigate("/");
                } else if (error.response.status == 403) {
                    toast.error(error.response.data.ErrorMessage)

                } else {
                    toast.error('Server error occurring, Please try after some time.')

                }
            }
        } else if (type == 'badgesAwarded') {
            try {
                var res = await axios.post(`${baseURL}/userBadges/getAllUserBadgesDetailsExcel`, setdata, { headers: headers });
                if (res.status == 200) {
                    toast.success(res.data.ErrorMessage)
                    // toast.success('Data export successfully.')

                    // window.open(`https://stage.trulinco.com/nrth-fitness-api/${res.data.getAllUserBadgesDetailsExcel}`, '_blank');
                    window.open(res.data.getAllUserBadgesDetailsExcel, '_blank');


                }
            } catch (error) {

                if (error.response.status == 401) {
                    localStorage.removeItem('userData')
                    localStorage.removeItem('authorization')
                    navigate("/");
                } else if (error.response.status == 403) {
                    toast.error(error.response.data.ErrorMessage)

                } else {
                    toast.error('Server error occurring, Please try after some time.')

                }
            }
        } else if (type == 'newlyRegistered') {
            try {
                var res = await axios.post(`${baseURL}/customer/newCustomerListExcel`, setdata, { headers: headers });
                if (res.status == 200) {
                    console.log('res.data', res.data);
                    toast.success('Data export successfully.')
                    toast.success(res.data.ErrorMessage)

                    // window.open(`https://stage.trulinco.com/nrth-fitness-api/${res.data.newCustomerListExcel}`, '_blank');
                    window.open(res.data.newCustomerListExcel, '_blank');


                }
            } catch (error) {

                if (error.response.status == 401) {
                    localStorage.removeItem('userData')
                    localStorage.removeItem('authorization')
                    navigate("/");
                } else if (error.response.status == 403) {
                    toast.error(error.response.data.ErrorMessage)

                } else {
                    toast.error('Server error occurring, Please try after some time.')

                }
            }
        }
    }
    const handleApply = (handleEvent) => {
        var date = handleEvent.target.value.split("-");
        setFromDate(date[0])
        setToDate(date[1])
    }
    const sorting = async (columnName, columnType) => {

        var setData = activaTab == 'runningChallenge' ? runningChallenge : activaTab == 'badgesAwarded' ? BadgesAwarded : activaTab == 'newlyRegistered' ? newlyRegistered : ''
        if (setData != '') {
            if (columnType == 'string') {
                setData.sort((a, b) => {
                    if (updown == 'up') {
                        var nameA = a[columnName]?.toLowerCase(); // ignore upper and lowercase
                        var nameB = b[columnName]?.toLowerCase(); // ignore upper and lowercase
                        if (nameA < nameB) {
                            return 1;
                        }
                        if (nameA > nameB) {
                            return -1;
                        }
                        return 0;
                    } else if (updown == 'down') {
                        var nameA = a[columnName]?.toUpperCase(); // ignore upper and lowercase
                        var nameB = b[columnName]?.toUpperCase(); // ignore upper and lowercase
                        if (nameA < nameB) {
                            return -1;
                        }
                        if (nameA > nameB) {
                            return 1;
                        }
                        return 0;
                    }
                });
            } else if (columnType == 'int') {
                if (updown == 'up') {
                    setData.sort((a, b) => b[columnName] - a[columnName]);
                    console.log('runningChallenge', setData);

                } else if (updown == 'down') {
                    console.log('updown24dd', updown);
                    setData.sort((a, b) => a[columnName] - b[columnName]);
                }
            }
            var data = activaTab == 'runningChallenge' ? setRunningChallenge(setData)
                : activaTab == 'badgesAwarded' ? setBadgesAwarded(setData)
                    : activaTab == 'newlyRegistered' ? setNewlyRegistered(setData) : ''

        }

    }
    const [date, setDate] = useState(new Date());
    useEffect(() => {
        if (activaTab == 'runningChallenge') {
            getRunningChallenge()
        }
        setHeaderHeadding('REPORTS')
    }, []);
    useEffect(() => {
        if (activaTab == 'newlyRegistered') {
            getNewlyRegistered()
        } else if (activaTab == 'badgesAwarded') {
            getBadgesAwarded()
        } else if (activaTab == 'runningChallenge') {
            getRunningChallenge()
        }
    }, [limit, pageNumber, todate, fromDate])

    return (
        <>
            {isLoading ? <div className="main_loader">
                <span className="loader-11"></span>
            </div> : null}
            <div className={`main_slide ${toggleClass ? "sidebar_open" : ""}`}>
                <Header />
                <Navbar activeLink={activeLink} setActiveLink={setActiveLink} />
                <div className="wrap_us conten_set">
                    <div className="">
                        <div className="">
                            <div className="w-full items-center generate_docuement">
                                <div className="template_list_m1 mt_uss">
                                    <div className="tab_lisd1 mb-4" style={{ display: "flex" }}>
                                        <button
                                            data-tip="Running Challenge"
                                            style={{
                                                borderBottom:
                                                    activaTab == "runningChallenge"
                                                        ? "2px solid white"
                                                        : "none",
                                            }}
                                            onClick={() => {
                                                setActivaTab("runningChallenge");
                                                setLimit(10)
                                                setPageNumber(1)
                                                setFromDate(Moment().subtract(30, 'days').format('MM-DD-YYYY'))
                                                setToDate(Moment().format('YYYY-MM-DD'))
                                                getRunningChallenge()
                                                setIsLoading(true)
                                            }}
                                        >
                                            Running Challenge
                                        </button>
                                        <button
                                            data-tip="Badges awarded"
                                            style={{
                                                borderBottom:
                                                    activaTab == "badgesAwarded"
                                                        ? "2px solid white"
                                                        : "none",
                                            }}
                                            onClick={() => {
                                                setActivaTab("badgesAwarded");
                                                setLimit(10)
                                                setPageNumber(1)
                                                setFromDate(Moment().subtract(30, 'days').format('MM-DD-YYYY'))
                                                setToDate(Moment().format('YYYY-MM-DD'))
                                                getBadgesAwarded()
                                                setIsLoading(true)

                                            }}
                                        >
                                            Badges Awarded
                                        </button>
                                        <button
                                            data-tip="Newly registered"
                                            style={{
                                                borderBottom:
                                                    activaTab == "newlyRegistered"
                                                        ? "2px solid white"
                                                        : "none",
                                            }}
                                            onClick={() => {
                                                setActivaTab("newlyRegistered");
                                                setLimit(10)
                                                setPageNumber(1)
                                                setFromDate(Moment().subtract(30, 'days').format('MM-DD-YYYY'))
                                                setToDate(Moment().format('YYYY-MM-DD'))
                                                getNewlyRegistered()
                                                setIsLoading(true)
                                            }}
                                        >
                                            Register
                                        </button>
                                    </div>
                                    {activaTab == "runningChallenge" && (
                                        <>
                                            <div className="">
                                                <div className="">
                                                    <div className="card_me table_des1">
                                                        <div className="top_aseh" >

                                                            <div className="fixe_date exp_file">

                                                                <DateRangePicker onApply={handleApply} max={Moment().format('MM/DD/YYYY')} initialSettings={{ startDate, endDate }} >
                                                                    <input type="text" className="form-control col-4" />
                                                                    {/* {startDate != '' ? : <FontAwesomeIcon icon={faCalendarAlt} />} */}
                                                                    {/* {startDate == '' && } */}
                                                                </DateRangePicker>
                                                                <button className="btn btn-sm white_us" onClick={(e) => {
                                                                    e.stopPropagation()
                                                                    exportData('runningChallenge')
                                                                }}>Export to Excel</button>

                                                            </div>
                                                        </div>
                                                        <div className="table-responsive">

                                                            <table className="table">
                                                                <thead>
                                                                    <tr>
                                                                        <th >S. No.</th>
                                                                        <th className={className == 'userName' ? `orderd table-${updown}` : 'orderd table-down'} onClick={(e) => {
                                                                            if (updown == 'down') {
                                                                                setUpdown('up')

                                                                            } else {
                                                                                setUpdown('down')
                                                                            }
                                                                            sorting('userName', 'string')
                                                                            setClassName('userName')

                                                                        }}>User Name</th>
                                                                        <th className={className == 'activePlan' ? `orderd table-${updown}` : 'orderd table-down'} onClick={(e) => {
                                                                            if (updown == 'down') {
                                                                                setUpdown('up')

                                                                            } else {
                                                                                setUpdown('down')
                                                                            }
                                                                            sorting('activePlan', 'string')
                                                                            setClassName('activePlan')

                                                                        }}>Plan</th>
                                                                        <th className={className == 'exerciseDurations' ? `orderd table-${updown}` : 'orderd table-down'} onClick={(e) => {
                                                                            if (updown == 'down') {
                                                                                setUpdown('up')

                                                                            } else {
                                                                                setUpdown('down')
                                                                            }
                                                                            sorting('exerciseDurations', 'string')
                                                                            setClassName('exerciseDurations')

                                                                        }}>Exercise Duration</th>
                                                                        <th className={className == 'exercisesName' ? `orderd table-${updown}` : 'orderd table-down'} onClick={(e) => {
                                                                            if (updown == 'down') {
                                                                                setUpdown('up')

                                                                            } else {
                                                                                setUpdown('down')
                                                                            }
                                                                            sorting('exercisesName', 'string')
                                                                            setClassName('exercisesName')

                                                                        }}>Exercise Name</th>
                                                                        <th className={className == 'exerciseTypes' ? `orderd table-${updown}` : 'orderd table-down'} onClick={(e) => {
                                                                            if (updown == 'down') {
                                                                                setUpdown('up')
                                                                            } else {
                                                                                setUpdown('down')
                                                                            }
                                                                            sorting('exerciseTypes', 'string')
                                                                            setClassName('exerciseTypes')
                                                                        }}>Exercise Type</th>
                                                                        <th className={className == 'levelName' ? `orderd table-${updown}` : 'orderd table-down'} onClick={(e) => {
                                                                            if (updown == 'down') {
                                                                                setUpdown('up')
                                                                            } else {
                                                                                setUpdown('down')
                                                                            }
                                                                            sorting('levelName', 'string')
                                                                            setClassName('levelName')
                                                                        }}>Exercise Level</th>
                                                                        <th className={className == 'dayCount' ? `orderd table-${updown}` : 'orderd table-down'} onClick={(e) => {
                                                                            if (updown == 'down') {
                                                                                setUpdown('up')
                                                                            } else {
                                                                                setUpdown('down')
                                                                            }
                                                                            sorting('dayCount', 'int')
                                                                            setClassName('dayCount')
                                                                        }}>Durations Count</th>
                                                                        <th className={className == 'exerciseCount' ? `orderd table-${updown}` : 'orderd table-down'} onClick={(e) => {
                                                                            if (updown == 'down') {
                                                                                setUpdown('up')
                                                                            } else {
                                                                                setUpdown('down')
                                                                            }
                                                                            sorting('exerciseCount', 'int')
                                                                            setClassName('exerciseCount')
                                                                        }}>Exercise Count</th>
                                                                        <th>Created Date</th>

                                                                    </tr>
                                                                </thead>
                                                                <tbody>

                                                                    {runningChallenge.length > 0 &&
                                                                        runningChallenge.map((item, index) => {
                                                                            return (<>
                                                                                <tr>
                                                                                    <td >{index + 1}</td>
                                                                                    <td>{item.userName}</td>
                                                                                    <td>{item.activePlan}</td>
                                                                                    <td>{item.exerciseDurations}</td>
                                                                                    <td>{item.exercisesName}</td>
                                                                                    <td>{item.exerciseTypes}</td>
                                                                                    <td>{item.levelName}</td>
                                                                                    <td>{item.dayCount}</td>
                                                                                    <td>{item.exerciseCount}</td>
                                                                                    <td>{format(
                                                                                        parseISO(item.createdAt),
                                                                                        "ccc, dd MMM yyyy, HH:mm a"
                                                                                    )}</td>
                                                                                </tr>
                                                                            </>)
                                                                        })}
                                                                </tbody>

                                                            </table>

                                                        </div>
                                                        {runningChallenge.length == 0 &&
                                                            <><span className="not_found">No data available.</span></>
                                                        }
                                                        {runningChallenge.length > 0 &&
                                                            <div className="footer_tab">
                                                                <div className="left_t1">
                                                                    <div className="icon_droup">
                                                                        <select className="form-control" onChange={(e) => {
                                                                            e.stopPropagation()
                                                                            setLimit(e.target.value);
                                                                            setPageNumber(1)
                                                                        }}>
                                                                            <option value="5" selected={limit == 5 ? true : false}>5</option>
                                                                            <option value="10" selected={limit == 10 ? true : false}>10</option>
                                                                            <option value="25" selected={limit == 25 ? true : false}>25</option>
                                                                            <option value="50" selected={limit == 50 ? true : false}>50</option>
                                                                            <option value="100" selected={limit == 100 ? true : false}>100</option>
                                                                            <option value="200" selected={limit == 200 ? true : false}>200</option>
                                                                        </select>
                                                                    </div>
                                                                    <p>Lines per page</p>
                                                                </div>
                                                                <div className="right_t1">
                                                                    <div className="icon_droup">
                                                                        <img src={LeftArrow} onClick={(e) => {
                                                                            e.stopPropagation()
                                                                            if (pageNumber > 1) {
                                                                                setPageNumber(pageNumber - 1)
                                                                            }
                                                                        }} />
                                                                        <input type="text" value={pageNumber} className="form-control" />
                                                                        <img src={RightArrow} onClick={(e) => {
                                                                            e.stopPropagation()
                                                                            if (countPage > pageNumber) {
                                                                                setPageNumber(pageNumber + 1)
                                                                            }
                                                                        }} />
                                                                    </div>
                                                                    <p>of {countPage}</p>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    {activaTab == "badgesAwarded" && (
                                        <>
                                            <div className="">
                                                <div className="">
                                                    <div className="card_me table_des1">
                                                        <div className="top_aseh" >

                                                            <div className="fixe_date exp_file">
                                                                <DateRangePicker onApply={handleApply} max={Moment().format('MM/DD/YYYY')} initialSettings={{ startDate, endDate }} >
                                                                    <input type="text" className="form-control col-4" />
                                                                </DateRangePicker>
                                                                <button className="btn btn-sm white_us" onClick={(e) => {
                                                                    e.stopPropagation()
                                                                    exportData('badgesAwarded')
                                                                }}>Export to Excel</button>
                                                            </div>

                                                        </div>
                                                        <div className="table-responsive">

                                                            <table className="table">
                                                                <thead>
                                                                    <tr>
                                                                        <th >S. No.</th>
                                                                        <th className={className == 'name' ? `orderd table-${updown}` : 'orderd table-down'} onClick={(e) => {
                                                                            if (updown == 'down') {
                                                                                setUpdown('up')

                                                                            } else {
                                                                                setUpdown('down')
                                                                            }
                                                                            sorting('name', 'string')
                                                                            setClassName('name')

                                                                        }}>User Name</th>
                                                                        <th className={className == 'activePlan' ? `orderd table-${updown}` : 'orderd table-down'} onClick={(e) => {
                                                                            if (updown == 'down') {
                                                                                setUpdown('up')

                                                                            } else {
                                                                                setUpdown('down')
                                                                            }
                                                                            sorting('activePlan', 'string')
                                                                            setClassName('activePlan')

                                                                        }}>Active Plan</th>

                                                                        <th className={className == 'levelName' ? `orderd table-${updown}` : 'orderd table-down'} onClick={(e) => {
                                                                            if (updown == 'down') {
                                                                                setUpdown('up')

                                                                            } else {
                                                                                setUpdown('down')
                                                                            }
                                                                            sorting('levelName', 'string')
                                                                            setClassName('levelName')

                                                                        }}>Exercise Level</th>
                                                                        <th >Created date</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {BadgesAwarded.length > 0 &&
                                                                        BadgesAwarded.map((item, index) => {
                                                                            return (<>
                                                                                <tr>
                                                                                    <td >{index + 1}</td>
                                                                                    <td>{item.name}</td>
                                                                                    <td>{item.activePlan}</td>
                                                                                    <td>{item.levelName}</td>
                                                                                    <td>{format(
                                                                                        parseISO(item.createdAt),
                                                                                        "ccc, dd MMM yyyy, HH:mm a"
                                                                                    )}</td>
                                                                                    <td>
                                                                                        <span className="btn_us1">
                                                                                            <button className="btn btn_clr" onClick={(e) => {
                                                                                                e.stopPropagation()
                                                                                                setBadgesAwardedDetail(item)
                                                                                                setIsOpneViewAwarded(true)
                                                                                            }}><img src={EyeIcon} alt="" /></button>
                                                                                        </span>

                                                                                    </td>

                                                                                </tr>
                                                                            </>)
                                                                        })}
                                                                </tbody>

                                                            </table>

                                                        </div>
                                                        {BadgesAwarded.length == 0 &&
                                                            <><span className="not_found">No data available.</span></>
                                                        }
                                                        {BadgesAwarded.length > 0 &&
                                                            <div className="footer_tab">
                                                                <div className="left_t1">
                                                                    <div className="icon_droup">
                                                                        <select className="form-control" onChange={(e) => {
                                                                            e.stopPropagation()
                                                                            setLimit(e.target.value);
                                                                            setPageNumber(1)
                                                                            // getCustomer()
                                                                        }}>
                                                                            <option value="5" selected={limit == 5 ? true : false}>5</option>
                                                                            <option value="10" selected={limit == 10 ? true : false}>10</option>
                                                                            <option value="25" selected={limit == 25 ? true : false}>25</option>
                                                                            <option value="50" selected={limit == 50 ? true : false}>50</option>
                                                                            <option value="100" selected={limit == 100 ? true : false}>100</option>
                                                                            <option value="200" selected={limit == 200 ? true : false}>200</option>
                                                                        </select>
                                                                    </div>
                                                                    <p>Lines per page</p>
                                                                </div>
                                                                <div className="right_t1">
                                                                    <div className="icon_droup">
                                                                        <img src={LeftArrow} onClick={(e) => {
                                                                            e.stopPropagation()
                                                                            if (pageNumber > 1) {
                                                                                setPageNumber(pageNumber - 1)
                                                                            }
                                                                        }} />
                                                                        <input type="text" value={pageNumber} className="form-control" />
                                                                        <img src={RightArrow} onClick={(e) => {
                                                                            e.stopPropagation()
                                                                            if (countPage > pageNumber) {
                                                                                setPageNumber(pageNumber + 1)
                                                                            }
                                                                        }} />
                                                                    </div>
                                                                    <p>of {countPage}</p>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    {activaTab == "newlyRegistered" && (
                                        <>
                                            <div className="">
                                                <div className="">
                                                    <div className="card_me table_des1">
                                                        <div className="top_aseh">
                                                            <div className="fixe_date exp_file">
                                                                <DateRangePicker onApply={handleApply} max={Moment().format('MM/DD/YYYY')} initialSettings={{ startDate, endDate }} >
                                                                    <input type="text" className="form-control col-4" />
                                                                </DateRangePicker>
                                                                <button className="btn btn-sm white_us" onClick={(e) => {
                                                                    e.stopPropagation()
                                                                    exportData('newlyRegistered')
                                                                }}>Export to Excel</button>
                                                            </div>

                                                            <div className="searh_m2">
                                                                Search keyword : {" "}
                                                                <input value={searchText}
                                                                    onChange={e => handleChange(e.target.value)}
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Keyword..."
                                                                />
                                                            </div>

                                                        </div>
                                                        <div className="table-responsive">

                                                            <table className="table">
                                                                <thead>
                                                                    <tr>
                                                                        <th >S. No.</th>
                                                                        <th className={className == 'name' ? `orderd table-${updown}` : 'orderd table-down'} onClick={(e) => {
                                                                            if (updown == 'down') {
                                                                                setUpdown('up')

                                                                            } else {
                                                                                setUpdown('down')
                                                                            }
                                                                            sorting('name', 'string')
                                                                            setClassName('name')

                                                                        }}> Name</th>
                                                                        <th className={className == 'email' ? `orderd table-${updown}` : 'orderd table-down'} onClick={(e) => {
                                                                            if (updown == 'down') {
                                                                                setUpdown('up')

                                                                            } else {
                                                                                setUpdown('down')
                                                                            }
                                                                            sorting('email', 'string')
                                                                            setClassName('email')

                                                                        }}> Email</th>
                                                                        <th > Date</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {newlyRegistered.length > 0 &&
                                                                        newlyRegistered.map((item, index) => {
                                                                            return (<>
                                                                                <tr>
                                                                                    <td>{index + 1}</td>
                                                                                    <td>{item.name}</td>
                                                                                    <td>{item.email}</td>

                                                                                    <td>{format(
                                                                                        parseISO(item.createdAt),
                                                                                        "ccc, dd MMM yyyy, HH:mm a"
                                                                                    )}</td>
                                                                                </tr>
                                                                            </>)
                                                                        })}
                                                                </tbody>

                                                            </table>

                                                        </div>
                                                        {newlyRegistered.length == 0 &&
                                                            <><span className="not_found">No data available.</span></>
                                                        }
                                                        {newlyRegistered.length > 0 &&
                                                            <div className="footer_tab">
                                                                <div className="left_t1">
                                                                    <div className="icon_droup">
                                                                        <select className="form-control" onChange={(e) => {
                                                                            e.stopPropagation()
                                                                            setLimit(e.target.value);
                                                                            setPageNumber(1)
                                                                            // getCustomer()
                                                                        }}>
                                                                            <option value="5" selected={limit == 5 ? true : false}>5</option>
                                                                            <option value="10" selected={limit == 10 ? true : false}>10</option>
                                                                            <option value="25" selected={limit == 25 ? true : false}>25</option>
                                                                            <option value="50" selected={limit == 50 ? true : false}>50</option>
                                                                            <option value="100" selected={limit == 100 ? true : false}>100</option>
                                                                            <option value="200" selected={limit == 200 ? true : false}>200</option>
                                                                        </select>
                                                                    </div>
                                                                    <p>Lines per page</p>
                                                                </div>
                                                                <div className="right_t1">
                                                                    <div className="icon_droup">
                                                                        <img src={LeftArrow} onClick={(e) => {
                                                                            e.stopPropagation()
                                                                            if (pageNumber > 1) {
                                                                                setPageNumber(pageNumber - 1)
                                                                            }
                                                                        }} />
                                                                        <input type="text" value={pageNumber} className="form-control" />
                                                                        <img src={RightArrow} onClick={(e) => {
                                                                            e.stopPropagation()
                                                                            if (countPage > pageNumber) {
                                                                                setPageNumber(pageNumber + 1)
                                                                            }
                                                                        }} />
                                                                    </div>
                                                                    <p>of {countPage}</p>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ViewAwardedModel isOpen={isOpneViewAwarded} setIsOpen={setIsOpneViewAwarded} badgesAwardedDetail={badgesAwardedDetail} />
        </>
    );
};

export default Report;