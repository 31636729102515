import React, { useContext, useState, useRef, useEffect } from 'react';
import Modal from 'react-modal';
import './modal.css';

import { toast } from 'react-toastify';
import useAxios from '../../hooks/useAxios';
import { parseISO, format } from "date-fns";

const customStyles = {
    content: {
        top: '30%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: '500px',
        maxHeight: 'auto',
        borderRadius: '11px',
        minWidth: '500px',
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
};

Modal.setAppElement('#root');
const ViewAwardedModel = ({
    isOpen,
    setIsOpen,
    buttonName, badgesAwardedDetail

}) => {
    const api = useAxios();



    return (
        <div className=''>
            <Modal
                isOpen={isOpen}
                onRequestClose={() => setIsOpen(false)}
                style={customStyles}
                onAfterClose={() => {

                }}
            >
                {console.log('badgesAwardedDetail', badgesAwardedDetail)}
                {badgesAwardedDetail != undefined && badgesAwardedDetail != '' && <>
                    <div className=' modal-container'>
                        <div style={{ display: 'flex' }}>
                            <h3> Name: </h3>
                            <h3>
                                {console.log('badgesAwardedDetail', badgesAwardedDetail)}
                                {badgesAwardedDetail.item.customerId.name}
                            </h3>
                        </div>


                        {badgesAwardedDetail.item.challengeId.levelId.name == 'BEGINNER' &&
                            <div className="plan_ds1">
                                <h3>BEGINNER <span>{format(
                                    parseISO(badgesAwardedDetail.item.challengeId.updatedAt),
                                    "dd-M-yyyy"
                                )}</span></h3>
                                <div className="payment1">
                                    <div className="row">
                                        <div className="col-6">
                                            <h4>{badgesAwardedDetail.item.avgExerciseCount}</h4>
                                            <h6>
                                                <span className="pd1">{badgesAwardedDetail.item.challengeId.exerciseId.name}</span>
                                            </h6>
                                        </div>
                                        <div className="col-6">
                                            <h4>{badgesAwardedDetail.item.challengeId.dayCount}</h4>
                                            <h6>
                                                <span className="pd2">Everyday for</span>
                                                <span className="pd1">{badgesAwardedDetail.item.challengeId.exerciseDurationId?.name}</span>
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                                <h5>CHALLENGE

                                    <span>{badgesAwardedDetail.item.challengeId.status = 'Complete' ? 'Completed' : ''}</span>
                                </h5>
                            </div>
                        }
                        {badgesAwardedDetail.item.challengeId.levelId.name == 'ELITE' &&
                            <div className="plan_ds1 plan_ds2">
                                <h3>ELITE</h3>
                                <div className="payment1">
                                    <div className="row">
                                        <div className="col-6">
                                            <h4>{badgesAwardedDetail.item.avgExerciseCount}</h4>
                                            <h6>
                                                <span className="pd1">{badgesAwardedDetail.item.challengeId.exerciseId.name}</span>
                                            </h6>
                                        </div>
                                        <div className="col-6">
                                            <h4>{badgesAwardedDetail.item.challengeId.dayCount}</h4>
                                            <h6>
                                                <span className="pd2">Everyday for</span>
                                                <span className="pd1">{badgesAwardedDetail.item.challengeId.exerciseDurationId.name}</span>
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                                <h5>CHALLENGE

                                    <span>{badgesAwardedDetail.item.challengeId.status = 'Complete' ? 'Completed' : ''}</span>
                                </h5>
                                <h3 className='time_one'>

                                    <span>{format(parseISO(badgesAwardedDetail.item.challengeId.updatedAt), "dd-M-yyyy")}</span>
                                </h3>
                            </div>
                        }
                        {badgesAwardedDetail.item.challengeId.levelId.name == 'CUSTOM' &&
                            <div className="plan_ds1 plan_ds2 plan_custum">
                                <h3>CUSTOM</h3>
                                <div className="payment1">
                                    <div className="row">
                                        <div className="col-6">
                                            <h4>{badgesAwardedDetail.item.avgExerciseCount}</h4>
                                            <h6>
                                                <span className="pd1">{badgesAwardedDetail.item.challengeId.exerciseId.name}</span>
                                            </h6>
                                        </div>
                                        <div className="col-6">
                                            <span className='evey_cut'>Everyday <span>for</span></span>
                                            <h4>{badgesAwardedDetail.item.challengeId.dayCount}</h4>
                                            <h6 className='cust_us'>
                                                {/* <span className="pd2">Everyday for</span> */}
                                                <span className="pd1">{badgesAwardedDetail.item.challengeId?.exerciseDurationId?.name != undefined ? "hello" : badgesAwardedDetail.item.challengeId.repType}</span>
                                            </h6>
                                            <h6>
                                                <span className="pd1">Rounds</span>
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                                <h5>CHALLENGE

                                    <span>{badgesAwardedDetail.item.challengeId.status = 'Complete' ? 'Completed' : ''}</span>
                                </h5>
                                <h3 className='time_one'>

                                    <span>{format(parseISO(badgesAwardedDetail.item.challengeId.updatedAt), "dd-M-yyyy")}</span>
                                </h3>
                            </div>
                        }
                        {badgesAwardedDetail.item.challengeId.levelId.name == 'SAVAGE' &&
                            <div className="plan_ds1 plan_ds3">
                                <h3>SAVAGE</h3>
                                <div className="payment1">
                                    <div className="row">
                                        <div className="col-6">
                                            <h4>{badgesAwardedDetail.item.avgExerciseCount}</h4>
                                            <h6>
                                                <span className="pd1">{badgesAwardedDetail.item.challengeId.exerciseId.name}</span>
                                            </h6>
                                        </div>
                                        <div className="col-6">
                                            <h4>{badgesAwardedDetail.item.challengeId.dayCount}</h4>
                                            <h6>
                                                <span className="pd2">Everyday for</span>
                                                <span className="pd1">{badgesAwardedDetail.item.challengeId.exerciseDurationId.name}</span>
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                                <h5>CHALLENGE

                                    <span>{badgesAwardedDetail.item.challengeId.status = 'Complete' ? 'Completed' : ''}</span>
                                </h5>
                                <h3 className='time_one'>
                                    <span>{format(
                                        parseISO(badgesAwardedDetail.item.challengeId.updatedAt),
                                        "dd-M-yyyy"
                                    )} </span>
                                </h3>
                            </div>
                        }
                    </div>
                </>}
            </Modal>
        </div>
    );
};

export default ViewAwardedModel;
