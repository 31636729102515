import { createContext, useState, useEffect } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { autoCloseTimer, delay } from "../constants/constants";
import { useTranslation } from "react-i18next";

const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {
  let [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("FitnessAppAuthTokens")
      ? JSON.parse(localStorage.getItem("FitnessAppAuthTokens"))
      : null
  );

  let [user, setUser] = useState(() =>
    localStorage.getItem("FitnessAppAuthTokens")
      ? jwt_decode(
        JSON.parse(localStorage.getItem("FitnessAppAuthTokens")).access
      )
      : null
  );
  const navigate = useNavigate();
  const { t } = useTranslation();



  const loginUser = async (email, password, setLoading) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/api/token/`,
        JSON.stringify({
          email,
          password,
        }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.data;

      setAuthTokens(data["data"]);
      const userData = jwt_decode(data["data"].access);

      setUser(userData);
      localStorage.setItem(
        "FitnessAppAuthTokens",
        JSON.stringify(data["data"])
      );
      setLoading(false);
      navigate("/dashboard");
    } catch (error) {
      let data;
      if (error && error.response) data = error.response.data;
      if (data?.code === "INVALID_EMAIL") {
        toast.error("Please verify email and try again", {
          delay,
          autoClose: autoCloseTimer,
          onClose: () => setLoading(false),
        });
      } else {
        toast.error(t("common.Something went wrong"), {
          delay,
          autoClose: autoCloseTimer,
          onClose: () => setLoading(false),
        });
      }
    }
  };

  const logoutUser = async () => {
    let user = {};
    user.user_id = 1;
    try {
      await axios.get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/user/logout/${user.user_id}`
      );

      setAuthTokens(null);
      setUser(null);
      localStorage.removeItem("FitnessAppAuthTokens");
      localStorage.removeItem("activeLink");
      navigate("/");
    } catch (error) {

    }
  };

  useEffect(() => {
    const storageListener = (e) => {
      if (e.key === "authTokens" && e.newValue === null) {
        logoutUser();
      }
    };
    window.addEventListener("storage", storageListener);
    return () => {
      window.removeEventListener("storage", storageListener);
    };
  }, []);

  const contextData = {
    user,
    authTokens,
    setAuthTokens,
    setUser,
    loginUser,
    logoutUser,
  };

  useEffect(() => {
    if (authTokens) {
      setUser(jwt_decode(authTokens.access));
    }
  }, [authTokens]);

  return (
    <AuthContext.Provider value={contextData}>{children}</AuthContext.Provider>
  );
};
